import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    props: {
        MuiButtonBase: {
            // The default props to change
            disableRipple: true, // No more ripple, on the whole application 💣!
        },
    },
    questionTitle: {
        justifyContent: 'start',
        display: 'flex',
        marginLeft: '10px'
    },
    questionContainer: {
        margin: '15px 0px'
    },
    formButtonGroup: {
        flexDirection: 'unset',
        justifyContent: 'center',
        display: 'flex'
    },
    nonSelectedAnswer: {
        backgroundColor: '#efefef'
    },
    selectedAnswer: {
        color: 'white !important',
        backgroundColor: '#e64683 !important'
    }

}))
