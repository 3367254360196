import {Patient} from "../model/patient";
import * as repository from "../data/repository";
import {EvolutionFilters} from "../model/filters/evolution-filters";
import {Citizenship} from "../model/citizenship";
import {AxiosResponse} from "axios";
import {CreateEvolution} from "../model/create-evolution";
import {Evolution} from "../model/evolution";
import {AnswerQuestion} from "../model/answer-question";
import {SettingsResponse} from "../model/settings-response";
import api from "../../../utils/api";
import {SignatureResponse} from "../model/signature-response";
import {PatientPhoto} from "../model/patient-photo";
import {EvolutionListResponse} from "../model/evolution-list-response";
import { Attachment } from "../model/attachment";

export const findPatient = async (cpf: string, tasy: boolean): Promise<Patient> => {
    cpf = formatDocNumber(cpf);
    const result = await repository.getPatientByCpf({cpf, tasy});
    return result.data;
}

export const createEvolution = async (createEvolutionArg: CreateEvolution): Promise<Evolution> => {
    formatCreateData(createEvolutionArg);
    const response = await repository.createEvolution(createEvolutionArg);
    return response.data
}

export const updateEvolution = async (createEvolutionArg: CreateEvolution): Promise<Evolution> => {
    formatCreateData(createEvolutionArg);
    const response = await repository.updateEvolution(createEvolutionArg);
    return response.data
}

export const listCitizenship = async (): Promise<Citizenship[]> => {
    const result: AxiosResponse<Citizenship[]> = await repository.listCitizenship();
    return result.data;
}

export const removeSignature = async (evolutionId: string, typeEvaluationId: string): Promise<void> => {
    await repository.removeSignature(evolutionId, typeEvaluationId);
}

export const getEvolutionQuestions = async (evolutionId: number): Promise<Evolution> => {
    const response = await repository.getEvolutionQuestions(evolutionId);
    return response.data
}

export const listEvolutions = async (filter: EvolutionFilters, page: number): Promise<EvolutionListResponse> => {
    // @ts-ignore
    filter.DocNumber = formatDocNumber(filter.DocNumber);
    filter.PageNumber = page;
    const response = await repository.listEvolutions(filter);
    return response?.data;
}

export const answerQuestion = async (evolutionId: number, answerQuestion: AnswerQuestion): Promise<void> => {
    const response = await repository.answerQuestion(evolutionId, answerQuestion);
    return response.data
}

export const getProgramsAndEvolutionTypes = async (): Promise<SettingsResponse> => {
    const response = await repository.getProgramsAndEvolutionTypes();
    return response.data;
}

export const updateEvolutionProgram = async (params: {id: number, programId: number}): Promise<void> => {
    const response = await repository.updateEvolutionProgram(params)
    return response.data;
}

export const updateTypeEvaluation = async (params: {id: number, typeEvaluationId: number}): Promise<void> => {
    const response = await repository.updateTypeEvaluation(params)
    return response.data;
}


export const releaseEvolution = async (evolutionId: number): Promise<void> => {
    const response = await repository.releaseEvolution(evolutionId)
    return response.data;
}

export const getPatientSignature = async (evaluationId: number, type: number): Promise<SignatureResponse> => {
    const response = await repository.getSignature({evaluationId, type});
    return response.data;
}

export const savePatientSignature = async (evaluationId: number, type: number, signature: string): Promise<void> => {
    const formData = new FormData();
    formData.append('EvolutionId', String(evaluationId));
    formData.append('SignatureType', String(type));
    formData.append('Attachments', dataURLtoFile(signature, signature));
    await repository.saveSignature(formData);
}

export const updateEvaluationStage = async (id: number, stage: number): Promise<void> => {
    await repository.updateEvaluationStage({id, stage});
}
export const getPatientPhoto = async (personId: number): Promise<PatientPhoto> => {
    const result = await repository.getPatientPhoto({personId});
    return result.data;
}

export const getPdf = async (evolutionId: number): Promise<string> => {
    const result = await repository.getPdf(evolutionId);
    return result.data;
}

export const  setPatientPhoto = async (personId: number, photo: string): Promise<AxiosResponse<void>> => {
    const formData = new FormData();
    const file = dataURLtoFile(photo, 'file.png')
    formData.append('PhysicalPersonId', String(personId));
    formData.append('Photo', file);

    return await repository.setPatientPhoto(formData);
}

export const uploadAttachment = async (evolutionId: Number, files: File[]): Promise<AxiosResponse<void>> => {
    const formData = new FormData();
    formData.append('evolutionId', evolutionId.toString());

    files.forEach((item: File) => {
        formData.append('Attachments', item)
    });

    return await repository.uploadAttachment(formData);
}

export const getAttachments = async (evolutionId: number): Promise<Attachment[]> => {
    const response = await repository.getAttachments(evolutionId);

    return response.data;
}

export const downloadAttachment = async (attachmentId: number): Promise<{data: string, filename: string}> => {
    const response = await repository.downloadAttachment(attachmentId);
    const filename = parseFilenameFromContentDisposition(response.headers["content-disposition"]) ?? "";

    return {
        data: URL.createObjectURL(response.data),
        filename: filename.replaceAll('"', ""),
    };
}

export const deleteAttachment = async (attachmentId: number): Promise<void> => {
    await repository.deleteAttachment(attachmentId);
}

function formatDocNumber(docNumber: string): string {
    return docNumber?.replaceAll('.', '')?.replaceAll('-', '');
}

function parseFilenameFromContentDisposition(contentDisposition: string) {
    if (!contentDisposition) return null;
    let matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/g.exec(contentDisposition);

    return matches && matches.length > 1 ? matches[1] : null;
}

function formatCreateData(data: CreateEvolution): void {
    data.patient.docNumber = data.patient.docNumber?.replace(/\D+/g, '');
    data.patient.telephone = data.patient.telephone?.replace(/\D+/g, '');
    if (data.patient.born) {
        data.patient.born = new Date(data.patient.born).toJSON();
    }
}

function dataURLtoFile(dataurl: any, filename: string) {

    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type:mime});
}
