
import { createContext } from "react";
interface IContextUser {
    mode : string,
    setMode : (mode : string) => void,
    isReadOnly: boolean,
    setIsReadOnly : (isReadOnly : boolean) => void,   
    userId : number | undefined,
    setUserId : (userId : number) => void,
    typeUser : number | undefined,
    setTypeUser : (typeUser : number) => void,

}
//isReadOnly, setIsReadOnly

const ContextUsers = createContext({} as IContextUser);

export default ContextUsers