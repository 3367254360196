export const hideRecaptcha = () => {
    const el = document.querySelectorAll<HTMLElement>('.grecaptcha-badge');

    if(el.length > 0){
        el[0].style.visibility = 'hidden'
    }

}

export const showRecaptcha = () => {
    const el = document.querySelectorAll<HTMLElement>('.grecaptcha-badge');
    el[0].style.visibility = ''
}

export const handleRecaptcha = (action:string) => {

    window.grecaptcha?.ready(() => {
        window.grecaptcha
            .execute(process.env.REACT_APP_RECAPTCHA_PK, { action: action })
            .then((token: any) => {
               //TO-DO - Validar Recaptcha no backend e tratar aqui quando ouver.
            })
    })
}

export const RECAPTCHA_ACTIONS = {
    LOGIN: 'login',
    SUBMIT_FORM_LOGIN: 'submitformlogin',
    RESET_PASSWORD: 'resetpassword',
    RESET_PASSWORD_PF: 'resetpasswordpf',
    RESET_PASSWORD_CONFIRMATION: 'resetpasswordconfirmation',
    SUBMIT_FORM_RESET: 'submitformresetpassword'
}
