import createTheme from "@material-ui/core/styles/createTheme";

const theme = createTheme({
  palette: {
    primary: {
      main: '#e64683',
    },
    secondary: {
      main: '#ff9015',
      contrastText: '#fff'
    },
    text:{
        primary:'#5f5f5f'
    }
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        backgroundColor: "#f2f2f2",
        "&:hover": {
          backgroundColor: "#f2f2f2",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: "#f2f2f2"
          }
        },
        "&.Mui-focused": {
          backgroundColor: "#f2f2f2"
        }
      }
    },
    MuiTableCell: {
      head: {
        fontWeight:'bold',
        backgroundColor: '#FDE8E5 !important',
      },
    }
  },
  typography: {
    fontFamily: 'jubilat !important',
  },
})

export default theme
