/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  TextField,
  Box,
  Button,
} from "@material-ui/core";

import UploadFile from "../../components/UploadFile";
import Snackbar from "../../components/Snackbar";
import { enviarSugestaoService, getSuggestionsType } from "./service";
import useStyles from "./style";
import { useHistory } from "react-router";
import InputMask from "react-input-mask";
import { Context } from "../../providers/AuthProvider/contexts/AuthContext";
import removeMask from "../../utils/removeMask";

type tipoSugestoes = {
  id: number;
  description: string;
};

const SuggestionsCompaints = () => {
  const classes = useStyles();

  const [tiposSugestoes, setTiposSugestoes] = useState<
    Array<tipoSugestoes> | undefined
  >([]);

  const [tipo, setTipo] = useState<number>(0);
  const [message, setMessage] = useState<string>("");
  const [file, setFile] = useState<File | File[]>();
  const [contactPhoneNumber, setContactPhoneNumber] = useState("");
  const [isErrorPhoneFormat, setIsErrorPhoneFormat] = useState(false);
  const { user } = useContext(Context);
  const history = useHistory();

  useEffect(() => {
    checkUserType();
    getTypes();
  }, [file]);

  const checkUserType = () => {
    if (user?.typeUser === 0) {
      history.push("sem-autorizacao");
    }
  };

  const getTypes = async () => {
    const response = await getSuggestionsType();

    setTiposSugestoes(response);
  };

  const handleTipo = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTipo(event.target.value as number);
  };

  const handleChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContactPhoneNumber(e.target.value);
  };

  const enviarSugestao = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const values = {
      tipo,
      message,
      contactPhoneNumber: removeMask(contactPhoneNumber),
      file,
    };

    if (values.contactPhoneNumber.length < 10) {
      setIsErrorPhoneFormat(true);
      return;
    }

    try {
      await enviarSugestaoService(values);
      Snackbar({ message: "Mensagem enviada com sucesso!", type: "success" });
      setFile(undefined);
      setContactPhoneNumber("");
      setIsErrorPhoneFormat(false);
      setMessage("");
    } catch (err) {
      //
    }
  };
  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={6}>
          <Typography
            variant="h5"
            style={{ fontWeight: "bolder" }}
            color="textPrimary"
          >
            Fale Conosco
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Você pode enviar seu comentário para nossa equipe e em breve
            retornaremos seu contato!
          </Typography>
        </Grid>
      </Grid>

      <form onSubmit={enviarSugestao}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={6}>
            <Grid container>
              <Grid item xs={12} sm={10} md={6}>
                <Box mt={2} mb={2} mr={1}>
                  <Typography
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      color: "#5f5f5f",
                    }}
                  >
                    Tipo
                  </Typography>
                  <Select
                    variant="outlined"
                    required
                    labelId="tipo"
                    id="tipo"
                    fullWidth
                    onChange={handleTipo}
                    displayEmpty
                  >
                    <MenuItem selected={tipo === 0 ? true : false} disabled>
                      Tipo
                    </MenuItem>
                    {tiposSugestoes?.map((sugestao: tipoSugestoes) => (
                      <MenuItem
                        selected={tipo === sugestao.id ? true : false}
                        key={sugestao.id}
                        value={sugestao.id}
                      >
                        {sugestao.description}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Grid>
            </Grid>
            <Box>
              <Typography
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "#5f5f5f",
                }}
              >
                Telefone
              </Typography>
            </Box>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <InputMask
                  mask="(99) 9999-99999"
                  maskPlaceholder="_"
                  value={contactPhoneNumber}
                  onChange={(e) => handleChangePhoneNumber(e)}
                >
                  {(inputProps: any) => (
                    <TextField
                      {...inputProps}
                      required
                      size="small"
                      variant="outlined"
                      margin="none"
                      fullWidth
                      id="telephone"
                      placeholder="Telefone"
                    />
                  )}
                </InputMask>
                {isErrorPhoneFormat && (
                  <Box mb={1}>
                    <span
                      style={{
                        fontSize: "0.8rem",
                        fontWeight: 400,
                        color: "red",
                      }}
                    >
                      * Insira o telefone informando o DDD com 2 dígitos e o
                      número com 8 ou 9 dígitos{" "}
                    </span>
                  </Box>
                )}
              </Grid>
            </Grid>
            <Box>
              <Typography
                style={{
                  marginTop: "1rem",
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "#5f5f5f",
                }}
              >
                Sugestão/Reclamação
              </Typography>
            </Box>

            <Grid container>
              <Grid item xs={12} sm={9} md={10}>
                <Box mr={1}>
                  <TextField
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    fullWidth
                    id="sugestao"
                    placeholder="Digite aqui sua sugestão ou reclamação..."
                    multiline
                    rows={5}
                    variant="outlined"
                  />
                </Box>
                <Box mb={1}>
                  <span
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: 400,
                    }}
                  >
                    * Para enviar mais de um arquivo selecione-os na janela que
                    será aberta ao clicar no botão de upload.{" "}
                  </span>
                </Box>
              </Grid>

              <Grid item xs={12} sm={3} md={2}>
                <Box mr={1}>
                  <UploadFile file={file} setFile={setFile} multipleFiles />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent="center">
            <Grid item xs={12} sm={2}>
              <Box mr={1}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Enviar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default SuggestionsCompaints;
