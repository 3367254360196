import { Grid, Typography } from "@material-ui/core";
import React from "react";

const WithoutPermission = () => {
  return (
    <Grid container justifyContent="center">
      <Grid item justifyContent="center">
        <Typography variant="h5" style={{ fontWeight: 'bolder' }} color="textPrimary">Você não tem permissão para acessar a página requisitada</Typography>
      </Grid>
    </Grid>
  )
}

export default WithoutPermission