/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Checkbox, FormHelperText, Grid, InputAdornment, MenuItem, Select, TextField, Typography } from "@material-ui/core"
import { Close, Search } from "@material-ui/icons"
import React, { useEffect, useState } from "react"
import ModalSearchInstitutions, { CbInstituteProps } from "../../../components/ModalSearchInstitutions"
import { createNewMaterialRequestConfiguration, getMaterialRequestById, getMaterialRequestType, updateMaterialRequestConfiguration } from "../service"
import ShowHasPermission from "../../../utils/showHasPermission"
import Snackbar from "../../../components/Snackbar"
import { useFormik } from "formik"
import * as Yup from 'yup'
import useStyles from '../style'

type materialConfigurationProps = {
  setMode: React.Dispatch<React.SetStateAction<string>>
  idMaterialConfiguration: number | undefined
  setIdMaterialConfiguration: React.Dispatch<React.SetStateAction<number | undefined>>
}

const AddEditMaterialRequestConfiguration = (props: materialConfigurationProps) => {
  const initialState = {
    tasyCode: 0,
    cnes: '',
    corporateName: '',
    cnpj: '',
    cityState: '',
    webAccess: ''
  }

  const { setMode, idMaterialConfiguration, setIdMaterialConfiguration } = props
  const [open, setOpen] = useState(false);
  const [cbInstitute, setCbInstitute] = useState<CbInstituteProps>(initialState)
  const [instituteName, setInstituteName] = useState<any>(`${cbInstitute.cnes} - ${cbInstitute.corporateName}`)
  const [materialsType, setMaterialsType] = useState([])  
  const classes = useStyles();

  useEffect(() => {
    materialRequest()
  }, [])

  const onClose = () => {
    setOpen(false);
  }

  const materialRequest = async () => {
    setMaterialsType(await getMaterialRequestType())
    if (idMaterialConfiguration !== undefined) {
      const response = await getMaterialRequestById(idMaterialConfiguration)
      formik.setValues(response)
      setInstituteName(`${response.instituteCnes} - ${response.institutionCorporateName}`)      
    }
  }

  const formik = useFormik({
    initialValues: {
      idMaterialConfiguration: idMaterialConfiguration,
      instituteCnes: '',
      instituteTasy: '',
      materialRequestTypeId: '',
      automaticRequest: false,
      minInventoryBalance: '',
      automaticRequestAmount: '',
      inventoryBalanceAmount: ''
    },
    validationSchema: Yup.object({     
      instituteTasy: Yup.number().required('Obrigatório'),
      materialRequestTypeId: Yup.string().required('Obrigatório'),
      inventoryBalanceAmount: Yup.number().min(0).required('Obrigatório'),

      minInventoryBalance: Yup.number().when('automaticRequest', {
        is: true,
        then: Yup.number().min(0).required('Obrigatório')
      }),
      automaticRequestAmount: Yup.number().when('automaticRequest', {
        is: true,
        then: Yup.number().min(0).required('Obrigatório')
      })
    }),
    onSubmit: async values => {    

      if (idMaterialConfiguration !== undefined) {
        await updateMaterialRequestConfiguration(values).then(()=>{
          setCbInstitute(initialState)
          formik.resetForm()          
          setMode('view')
          Snackbar({ message: 'Configuração atualizada com sucesso!', type: 'success' })
        })        
      } 
      else {
        await createNewMaterialRequestConfiguration(values).then(()=>{
          setCbInstitute(initialState)
          formik.resetForm()
          Snackbar({ message: 'Nova configuração criada com sucesso!', type: 'success' })
        })
      }  
    }
  })

  useEffect(() => {
    if (cbInstitute.tasyCode !== 0) {
      formik.setFieldValue('instituteCnes', cbInstitute.cnes)
      formik.setFieldValue('instituteTasy', cbInstitute.tasyCode)
    }
  }, [cbInstitute.tasyCode])

  const handleChangeInstituteIcon = (event: any) => {
    event.stopPropagation();
    setCbInstitute(initialState)
    formik.setFieldValue('instituteTasy', undefined)
    formik.setFieldValue('instituteCnes', undefined)
  }

  const handleMode = () => {
    setMode('view')
    setIdMaterialConfiguration(undefined)
  } 

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={6}>
          <Typography variant="h5" style={{ fontWeight: 'bolder' }} color="textPrimary">Nova Configuração Solicitação de Materiais</Typography>
          <Typography variant="subtitle1" color="textSecondary"></Typography>
        </Grid>
      </Grid>

      <form onSubmit={formik.handleSubmit}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={6}>

            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Box mt={2} mb={2}>
                  <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Instituição</Typography>
                  <ModalSearchInstitutions open={open} onCallBack={setCbInstitute}
                    onClose={onClose} />
                  <TextField
                    fullWidth
                    inputProps={
                      {
                        className: 'iconCursor',
                      }
                    }
                    value={idMaterialConfiguration !== undefined ? instituteName : `${cbInstitute.cnes} - ${cbInstitute.corporateName}`}
                    onClick={() => idMaterialConfiguration !== undefined ? null : setOpen(true)}
                    disabled={idMaterialConfiguration !== undefined ? true : false}
                    size="small"
                    variant="outlined"
                    margin="none"
                    id="search-user-institute"
                    placeholder="Clique para selecionar a instituição"
                    error={formik.touched.instituteTasy && Boolean(formik.errors.instituteTasy)}
                    helperText={formik.touched.instituteTasy && formik.errors.instituteTasy}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          {cbInstitute.corporateName !== '' ?
                            <Close className='iconCursor' onClick={handleChangeInstituteIcon} />
                            : <Search className='iconCursor' />}

                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Box mb={2}>
                  <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Tipo Material</Typography>
                  <Select variant="outlined"
                    disabled={idMaterialConfiguration !== undefined ? true : false}
                    labelId="tipo"
                    id="tipo"
                    fullWidth
                    displayEmpty
                    onChange={(e) => formik.setFieldValue('materialRequestTypeId', e.target.value)}
                    value={formik.values.materialRequestTypeId}
                    error={formik.touched.materialRequestTypeId && Boolean(formik.errors.materialRequestTypeId)}
                  >
                    {materialsType?.map((material: any) => (
                      <MenuItem key={material.id} value={material.id}>{material.description}</MenuItem>
                    ))}
                  </Select>
                  {
                   formik.touched.materialRequestTypeId && Boolean(formik.errors.materialRequestTypeId) &&
                    <FormHelperText style={{ marginLeft: 14, marginRight: 14 }} error={formik.touched.materialRequestTypeId && Boolean(formik.errors.materialRequestTypeId)}>{
                      formik.errors.materialRequestTypeId
                    }</FormHelperText>
                  }
                </Box>
              </Grid>
            </Grid>

            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Box mb={2}>
                  <Checkbox checked={formik.values.automaticRequest} color="primary" onChange={(e) => formik.setFieldValue('automaticRequest', e.target.checked)} />
                  Solicitação Automática
                </Box>
              </Grid>
            </Grid>

            {formik.values.automaticRequest ?
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <Box mb={2} mr={1}>
                    <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Saldo Mínimo</Typography>
                    <TextField
                      id="minInventoryBalance"
                      type="number"
                      variant="outlined"
                      error={formik.touched.minInventoryBalance && Boolean(formik.errors.minInventoryBalance)}
                      helperText={formik.touched.minInventoryBalance && formik.errors.minInventoryBalance}
                      {...formik.getFieldProps('minInventoryBalance')}
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Box mb={2} mr={1}>
                    <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Qtd. Solicitar</Typography>
                    <TextField
                      id="automaticRequestAmount"
                      type="number"
                      variant="outlined"
                      error={formik.touched.automaticRequestAmount && Boolean(formik.errors.automaticRequestAmount)}
                      helperText={formik.touched.automaticRequestAmount && formik.errors.automaticRequestAmount}
                      {...formik.getFieldProps('automaticRequestAmount')}
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </Box>
                </Grid>
              </Grid>
              : ''
            }

            {ShowHasPermission('SolicitacaoMaterialConfiguracao_Alteração_Saldo') ? (
              <Grid item xs={12} sm={4}>
                <Box mb={2} mr={1}>
                  <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Saldo Atual</Typography>
                  <TextField
                    id="inventoryBalanceAmount"
                    type="number"
                    variant="outlined"
                    error={formik.touched.inventoryBalanceAmount && Boolean(formik.errors.inventoryBalanceAmount)}
                    helperText={formik.touched.inventoryBalanceAmount && formik.errors.inventoryBalanceAmount}
                    {...formik.getFieldProps('inventoryBalanceAmount')}
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </Box>
              </Grid>
            )
              : ''}

          </Grid>
        </Grid>

        <Grid container justifyContent="center">
          <Grid item xs={12} sm={2}>
            <Box mr={1}>
              <Button
              className={classes.button}                
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Box mr={1}>
              <Button
              className={classes.button}
                onClick={handleMode}                
                type="button"
                fullWidth
                variant="contained"
                color="secondary"               
              >
                Voltar
              </Button>
            </Box>
          </Grid>
        </Grid>

      </form>
    </>
  )
}

export default AddEditMaterialRequestConfiguration