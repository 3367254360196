import {FormResponse} from "../../../../model/form-response";
import useStyles from "./style";
import {Box, Button, Grid, TextareaAutosize, Typography, Link} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import SetDescriptionDialog from "../dialogs/set-description-dialog";
import * as service from "../../../../service/evolution-service";
import EvolutionAttachmentModal from "../attachment";

export interface OpenQuestionProps {
    evolutionId: number,
    formResponse: FormResponse,
    disableEdit: boolean,
    onAnswer: () => void,
    withAttachment: boolean
}


const OpenQuestion = (props: OpenQuestionProps) => {
    const style = useStyles();
    const [openTextAreaValue, setOpenTextAreaValue] = useState<string>('');

    async function onTextAreaChange(event: any): Promise<void> {
        const _value: string = event?.target?.value;
        if (_value !== openTextAreaValue) {
            props.formResponse.openAnswer = _value;

            await service.answerQuestion(props?.evolutionId, {observation: _value, questionId: props.formResponse.questionId});
            setOpenTextAreaValue(_value);

            props.onAnswer();
        }
    }

    const [showAttachmentModal, setShowAttachmentModal] = useState<boolean>(false);

    useEffect(() => {
        setOpenTextAreaValue(props.formResponse.openAnswer);
    }, [props.formResponse.openAnswer])

    return(
        <Grid>
            <Grid container spacing={2}>
                <Grid item sm={10}>
                    <Typography >{props.formResponse.question}</Typography>
                </Grid>
                {props.withAttachment &&
                <Grid item sm={2}>
                    <Grid>
                        <Link className={style.editLink} color={'primary'} onClick={() => setShowAttachmentModal(true)}>Anexar arquivo</Link>
                        <EvolutionAttachmentModal evolutionId={Number(props.evolutionId)} open={showAttachmentModal}  handleClose={() => setShowAttachmentModal(false)}/>
                    </Grid>
                </Grid>
                }
            </Grid>
            <TextareaAutosize
                disabled={props.disableEdit}
                onBlur={(event) => onTextAreaChange(event)}
                aria-label="minimum height"
                minRows={5}
                defaultValue={openTextAreaValue}
                style={{ width: '100%' }}
            />
        </Grid>
    )
}


export default OpenQuestion;
