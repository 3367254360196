import React, { useEffect, useState } from "react";
import { Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import MUIDataTable from "mui-datatables";
import { getLogMaterialRequestConfig } from "../service";
import { dateStringToDateTimeFormat } from "../../../utils/convertDate";

interface ModalSearchInstitutionsProps {
  open: boolean;
  onClose: () => void | undefined;
  materialRequestId: number | undefined
}

const LogMaterialRequestConfig = ({ open, onClose, materialRequestId }: ModalSearchInstitutionsProps) => {

  type SelectableRows = 'multiple' | 'single' | 'none';
  type ResponsiveType = 'vertical' | 'standard' | 'simple';

  const [data, setData] = useState<any>([]);
  const [totalRows, setTotalRows] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [page, setPage] = useState(0)

  useEffect(() => {
    if (materialRequestId !== undefined) {
      getLogMaterialRequestConfig(materialRequestId).then((response: any) => {
        setData(response.content)
        setTotalRows(response.totalRecords)
        setRowsPerPage(response.pageable.pageSize)
      })
    }
  }, [materialRequestId])

  const onCloseHandle = () => {
    onClose()
  }

  const columns = [
    {
      name: "createdAt",
      label: "Data",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (index: any) => {
          return (
            dateStringToDateTimeFormat(data[index].createdAt)
          );
        }
      }
    },
    {
      name: "userName",
      label: "Usuário",
      options: {
        filter: false,
        sort: false,
      }
    },
  ]

  const options = {
    search: false,
    download: false,
    expandableRowsHeader: false,
    expandableRows: true,
    expandableRowsOnClick: true,
    renderExpandableRow: (rowData: any, rowMeta: any) => {
      return (
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Box margin={1}>
            {data[rowMeta.dataIndex].instituteTasyOld === (undefined || null) ?
              <>
                <Typography variant="h6" gutterBottom component="div">
                  Novo Registro
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>CNES</TableCell>
                      <TableCell>Tipo Material</TableCell>
                      <TableCell>Solicitação Automática</TableCell>
                      <TableCell>Saldo Mínimo</TableCell>
                      <TableCell>Qtd. Solicitar</TableCell>
                      <TableCell>Saldo Atual</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={0}>
                      <TableCell>{data[rowMeta.dataIndex].instituteCnesNew}</TableCell>
                      <TableCell>{data[rowMeta.dataIndex].nameMaterialRequestTypeNew}</TableCell>
                      <TableCell>{data[rowMeta.dataIndex].automaticRequestNew === true ? 'Sim' : 'Não'}</TableCell>
                      <TableCell>{data[rowMeta.dataIndex].minInventoryBalanceNew}</TableCell>
                      <TableCell>{data[rowMeta.dataIndex].automaticRequestAmountNew}</TableCell>
                      <TableCell>{data[rowMeta.dataIndex].inventoryBalanceAmountNew}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </>
              : (
                <>
                  <Typography variant="h6" gutterBottom component="div">
                    Alteração
                  </Typography>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>CNES</TableCell>
                        <TableCell>Tipo Material</TableCell>
                        <TableCell>Solicitação Automática</TableCell>
                        <TableCell>Saldo Mínimo</TableCell>
                        <TableCell>Qtd. Solicitar</TableCell>
                        <TableCell>Saldo Atual</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={0}>
                        <TableCell>Antes</TableCell>
                        <TableCell>{data[rowMeta.dataIndex].instituteCnesOld}</TableCell>
                        <TableCell>{data[rowMeta.dataIndex].nameMaterialRequestTypeOld}</TableCell>
                        <TableCell>{data[rowMeta.dataIndex].automaticRequestOld === true ? 'Sim' : 'Não'}</TableCell>
                        <TableCell>{data[rowMeta.dataIndex].minInventoryBalanceOld}</TableCell>
                        <TableCell>{data[rowMeta.dataIndex].automaticRequestAmountOld}</TableCell>
                        <TableCell>{data[rowMeta.dataIndex].inventoryBalanceAmountOld}</TableCell>
                      </TableRow>
                      <TableRow key={1}>
                        <TableCell>Depois</TableCell>
                        <TableCell>{data[rowMeta.dataIndex].instituteCnesNew}</TableCell>
                        <TableCell>{data[rowMeta.dataIndex].nameMaterialRequestTypeNew}</TableCell>
                        <TableCell>{data[rowMeta.dataIndex].automaticRequestNew === true ? 'Sim' : 'Não'}</TableCell>
                        <TableCell>{data[rowMeta.dataIndex].minInventoryBalanceNew}</TableCell>
                        <TableCell>{data[rowMeta.dataIndex].automaticRequestAmountNew}</TableCell>
                        <TableCell>{data[rowMeta.dataIndex].inventoryBalanceAmountNew}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </>
              )}
          </Box>
        </TableCell>
      );
    },
    filter: false,
    print: false,
    selectableRowsHeader: false,
    viewColumns: false,
    rowsPerPageOptions: [5, 10],
    rowsPerPage: rowsPerPage,
    responsive: 'simple' as ResponsiveType,
    selectableRows: 'none' as SelectableRows,
    page: page,
    textLabels: {
      body: {
        noMatch: "Nenhum registro encontrado.",
      },
      pagination: {
        next: "Próxima Pagina",
        previous: "Pagina Anterior",
        rowsPerPage: "",
        displayRows: "de",
      },
    },
    count: totalRows,
    serverSide: true,
    onTableChange: async (action: any, tableState: any) => {
      switch (action) {
        case 'changePage':
          requestOnChange(tableState.page, tableState.rowsPerPage);
          break;
        case 'changeRowsPerPage':
          requestOnChange(tableState.page, tableState.rowsPerPage);
          break;
        default:
      }
    },
    setTableProps: () => {
      return {
        size: 'small'
      };
    },
  }

  const requestOnChange = async (page: any, rowsPerPage: any) => {
    const response = await getLogMaterialRequestConfig(materialRequestId, page + 1, rowsPerPage)
    setData(response.content)
    setTotalRows(response.totalRecords)
    setRowsPerPage(response.pageable.pageSize)
    setPage(response.pageable.pageNumber - 1)
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth='xl' fullWidth scroll='body'>
      <MuiDialogTitle disableTypography>
        <Typography variant="h6">Histórico Configuração Solicitação de Material</Typography>
        <IconButton aria-label="close" style={{ position: 'absolute', right: '10px', top: '10px' }}
          onClick={onCloseHandle}>
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <DialogContent>
        <MUIDataTable
          options={options}
          title={""}
          data={data}
          columns={columns}
        />
      </DialogContent>
    </Dialog>
  )
}

export default LogMaterialRequestConfig;