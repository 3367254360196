import api from '../../../utils/api'
import { IUserContent, IGetUserProps } from '../interfaces'




export const getGroupUser = async ({
   pageNumber, 
   pageSize, 
   searchUserValue, 
   searchEmailValue ,
   searchTypeUser ,
   searchStatus,
   searchCnes}: Partial<IGetUserProps>) :Promise<IUserContent> => {
   
   let queryParam = ``
   
   if(searchUserValue){
      queryParam = queryParam + `&Name=${searchUserValue}`
   }

   if(searchEmailValue){
      queryParam = queryParam + `&Email=${searchEmailValue}`
   }

   if(searchTypeUser){
      if(searchTypeUser !== 'all'){
         queryParam = queryParam + `&TypeUser=${searchTypeUser}`
      }
      
   }


   if(searchStatus){
     
     if(searchStatus !== 'all'){
         queryParam = queryParam + `&Active=${searchStatus}`
     }
     
   }

   if(searchCnes){
      queryParam = queryParam + `&InstituteCnes=${searchCnes}`
   }


   if(pageNumber){
      queryParam += `&pageNumber=${pageNumber}`
   }

   if(pageSize){
      queryParam += `&pageSize=${pageSize}`
   }   
   const user  = await api.get(`user?${queryParam}`);
   const userData :IUserContent = user.data   
   
   return userData;
}

export const unlockUser = async(userId : number) => {
   return await api.post(`user/login/reset/${userId}`)
}

export const getCanEditInstitute = async () => {
    return await api.get('group/canEditInstitute')
}

export const getActiveGroupUser = async () => {
   return await api.get(`group/active`)
}

export const getSector = async () => {
   return await api.get('sector')
}

export const postUser = async (user: any) => {
   return await api.post('user', user)
}

export const getUsersById = async (userID : number)  => 
   await api.get(`user/${userID}`).then(resp => resp.data)


export const updateUserById = async(userID : number , data? : object) => 
       await api.put(`user/${userID}`, data).then(resp => resp.data)
