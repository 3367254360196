import { Dialog, DialogTitle, DialogActions, Box, TextField, Button } from '@material-ui/core'
import { useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import Snackbar from "../../../../components/Snackbar";
import { changeMaterialRequestStatus } from "../../services";
import useStyles from './style'

type StatusOptions = 'Entregue' | 'Cancelada' | 'Confirmada'

type IModalProps = {
    open: boolean;
    materialItemId?: number;
    statusModeClicked: StatusOptions;
    setStatusMode: (statusCode: number) => void;
    title: string;
    onClose: () => void;
    onConfirm?: () => Promise<void>;
    //status pelo statusMode 
}

export const ModalStatusConfirmation = ({ open, title, statusModeClicked, setStatusMode, onClose, materialItemId }: IModalProps) => {
    const classes = useStyles()
    const [observationText, setObservationText] = useState('')
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 598px)' })

    const confirmMaterialRequestStatus = async () => {
       
        try {

            if (materialItemId) {
                if (statusModeClicked === 'Entregue') {
                    await changeMaterialRequestStatus({
                        status: 2,
                        observation: observationText
                    }, materialItemId)
                    setStatusMode(2)
                }
                else if (statusModeClicked === 'Cancelada') { 

                    if(observationText.length === 0){
                        return Snackbar({message:'É obrigatório informar o motivo do cancelamento.', type:'error'})
                    }          

                    await changeMaterialRequestStatus({
                        status: 3,
                        observation: observationText
                    }, materialItemId)
                    setStatusMode(3)
                }
                else {
                    await changeMaterialRequestStatus({
                        status: 1,
                        observation: observationText
                    }, materialItemId)
                    setStatusMode(1)
                }

                Snackbar({ message: `Status alterado com sucesso.`, type: 'success' })
                setObservationText('')
                onClose()
            }
            else {
                console.log('ID do material não informado.')
            }

        }
        catch (error) {
            console.log(error)
        }
    }


    const ButtonsModalForm = () => {
        return (
            <Box className={classes.buttonsBox}>
                <Button
                    className={classes.button}
                    variant='contained'
                    color='primary'
                    onClick={() => {
                        confirmMaterialRequestStatus()                        
                    }}>
                    Confirmar
                </Button>
                <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                        onClose()
                    }}>
                    Cancelar
                </Button>
            </Box>
        )
    }

    return (
        <Dialog
            fullWidth
            maxWidth='xs'
            open={open}
            fullScreen={isTabletOrMobile}
        >
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogActions >
                {statusModeClicked === 'Confirmada' && (
                    <TextField
                        size="small"
                        variant="outlined"
                        margin="none"
                        id='mark-as-delivered'
                        label='Observações'
                        multiline
                        placeholder='Campo opcional'
                        rows={5}
                        style={{ width: '100%' }}
                        value={observationText}
                        onChange={(e) => setObservationText(e.target.value)}
                    />
                )}
                {statusModeClicked === 'Entregue' && (
                    <TextField
                        size="small"
                        variant="outlined"
                        margin="none"
                        id='mark-as-delivered'
                        label='Observações sobre a entrega'
                        multiline
                        placeholder='Campo opcional'
                        rows={5}
                        style={{ width: '100%' }}
                        value={observationText}
                        onChange={(e) => setObservationText(e.target.value)}
                    />
                )}
                {statusModeClicked === 'Cancelada' && (
                    <TextField
                        size="small"
                        variant="outlined"
                        margin="none"
                        id='mark-as-cancelled'
                        label='Informe o motivo do cancelamento'
                        multiline
                        placeholder='Campo Obrigatório'
                        rows={5}
                        style={{ width: '100%' }}
                        value={observationText}
                        required
                        onChange={(e) => setObservationText(e.target.value)}
                    />
                )}
            </DialogActions>
            <ButtonsModalForm />
        </Dialog>
    )
}