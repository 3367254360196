import { Dialog, Typography, IconButton, DialogContent, Grid, Box } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import MUIDataTable from 'mui-datatables'
import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import useStyles from '../style'
import { getHistoryReport } from '../service'

export interface IHistoryReport {
    date: "string",
    historicType: "string",
    responsibleUser: "string"
}

const DialogHistoryReport = ({ handleClose, open, prescription }: { handleClose: any, open: boolean, prescription: string }) => {
    const classes = useStyles()
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 598px)' })
    type ResponsiveType = 'vertical' | 'standard' | 'simple'
    type SelectableRows = 'multiple' | 'single' | 'none'
    const [data, setData] = useState<IHistoryReport[]>([])

    const options = {
        pagination: false,
        search: false,
        download: false,
        expandableRowsHeader: false,
        filter: false,
        print: false,
        selectableRowsHeader: false,
        viewColumns: false,
        responsive: 'simple' as ResponsiveType,
        selectableRows: 'none' as SelectableRows,
        textLabels: {
            body: {
                noMatch: "Nenhum registro encontrado.",
            }
        },
        setTableProps: () => {
            return {
                size: 'small'
            };
        },
    }

    const columns = [
        {
            name: "date",
            label: "Data/Hora",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "historicType",
            label: "Histórico",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "responsibleUser",
            label: "Usuário Responsável",
            options: {
                filter: false,
                sort: false,
            }
        }
    ]

    useEffect( ()=> {
        if(prescription)
        getHistoryReport(prescription).then(resp =>{
            setData(resp)
        })
    }, [prescription])

    return (
        <Dialog scroll='paper' onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={'lg'} fullScreen={isTabletOrMobile ?? false} >
            <MuiDialogTitle disableTypography>
                <Typography variant="h6">Histórico do laudo</Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <DialogContent dividers >
                <Grid>
                    <Box mt={2} mb={2}>
                        <MUIDataTable
                            options={options}
                            title={""}
                            data={data}
                            columns={columns}
                        />
                    </Box>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default DialogHistoryReport