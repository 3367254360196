import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  alertWrapper: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center'
  },

  alertIcon: {
    marginRight: '10px',
    color: theme.palette.primary.main
  },

  alertText: {
    lineHeight: '20px'
  }
}))
