import { Typography } from '@material-ui/core'

import useStyles from './styles'

function PhraseReferenceServiceNeonatal() {
  const classes = useStyles()

  return (
    <Typography
      className={classes.text}
      color="textSecondary"
    >
      Somos o maior Serviço de Referência em Triagem Neonatal do país
    </Typography>
  )
}

export default PhraseReferenceServiceNeonatal
