import useStyles from "./style";
import { Box, Button, Dialog, DialogContent, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import UploadFile from "../../../../../../components/UploadFile";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import * as service from "../../../../service/evolution-service";
import Snackbar from "../../../../../../components/Snackbar";
import { Attachment } from "../../../../model/attachment";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

interface EvolutionAttachmentModalProps {
  evolutionId: number,
  open: boolean,
  handleClose: () => void,
}

const EvolutionAttachmentModal = (args: EvolutionAttachmentModalProps) => {
  const styles = useStyles();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 598px)' });
  const [selectedFiles, setSelectedFiles] = useState<File | File[] | undefined>();
  const [attachments, setAttachments] = useState<Attachment[]>();

  const refreshAttachments = async () => {
    if (!args.open)
      return;

    try {
      const attachments = await service.getAttachments(args.evolutionId);
      setAttachments(attachments);
    }
    catch(_) {
    }
  }

  const handleUploadButtonClick = () => {
    if (!selectedFiles)
      return;

    let files: File[] = [];
    if (selectedFiles instanceof File) {
      files = [selectedFiles];
    } else {
      files = selectedFiles as File[];
    }

    service.uploadAttachment(args.evolutionId, files)
    .then(() => {
      Snackbar({message: 'Arquivo(s) anexado(s) com sucesso', type: 'success'});
    })
    .catch((_) => {
      Snackbar({message: 'Aconteceu um erro ao tentar enviar o(s) anexo(s)', type: 'error'});
    })
    .finally(() => {
      return refreshAttachments();
    });

    setSelectedFiles(undefined);
  };

  const handleCancelButtonClick = () => {
    args.handleClose();
  };

  const onDownloadItem = async (attachmentId: number) => {
    const { data, filename } = await service.downloadAttachment(attachmentId);
    const link = document.createElement("a");
    link.href = data;
    link.setAttribute("download", filename);
    link.click();
  }

  const onDeleteItem = async (attachmentId: number) => {
    try {
      await service.deleteAttachment(attachmentId);
      Snackbar({message: 'Anexo deletado com sucesso', type: 'success'});
    }
    catch (e) {
      Snackbar({message: 'Ocorreu um erro ao deletar o anexo', type: 'error'});
    }

    await refreshAttachments();
  }

  useEffect(() => {
    refreshAttachments();
  }, [args.open]);

  return (
    <Dialog onClose={args.handleClose} aria-labelledby="customized-dialog-title" open={args.open} maxWidth="md" fullScreen={isTabletOrMobile ?? false}>
      <MuiDialogTitle disableTypography>
        <Typography variant="h6">Anexo</Typography>

        <IconButton aria-label="close" className={styles.closeButton} onClick={args.handleClose}>
          <CloseIcon />
        </IconButton>

      </MuiDialogTitle>

      <DialogContent dividers>
        <List>
          {
            attachments?.map((attachment) => {
              return(
                <ListItem key={attachment.id}>
                  <ListItemText
                    primary={attachment.fileName}
                    className={styles.attachmentList}
                    onClick={() => onDownloadItem(attachment.id)}
                    />
                  <ListItemSecondaryAction onClick={() => onDeleteItem(attachment.id)}>
                    <IconButton edge="end" arial-label="delete">
                      <DeleteForeverIcon/>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })
          }
          </List>
        <Box>
          <Typography variant="subtitle1">Selecione os arquivos</Typography>
          <UploadFile file={selectedFiles} setFile={setSelectedFiles} multipleFiles />
          <Box display="flex" mt={2}>
            <Button variant="contained" color="secondary" onClick={handleCancelButtonClick}>
              Fechar
            </Button>
            <Box ml={1}>
              <Button variant="contained" color="primary" onClick={handleUploadButtonClick}>
                Enviar
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EvolutionAttachmentModal;