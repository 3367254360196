/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField, Box, Button, Checkbox, RadioGroup, FormControlLabel, Radio } from "@material-ui/core"
import { getInstitute, sendRequest } from "./service";
import { IQuestions } from "./interfaces";
import Snackbar from "../../components/Snackbar";
import { unityType, trainingCategory, trainingPeriod, professionalToBeTraineds } from "./questions";
import { useFormik } from "formik"

export type CbInstituteProps = {
  tasyCode: number,
  cnes: string,
  corporateName: string,
  cnpj: string,
  cityState: string,
  webAccess: string
}

export interface iUser {
  instituteCnes: number,
}

type Pageable = {
  pageNumber: number,
  pageSize: number,
}

type Response = {
  data: {
      content: CbInstituteProps[],
      pageable: Pageable,
      totalPages: number,
      totalRecords: number,
  }

}

type InstituteProps = {
  PageNumber?: any,
  PageSize?: any,
  Cnes?: number,
}

const SuggestionsCompaints = () => {
  const [attempts, setAttempts] = useState(1)

  useEffect(() => {
    const userStore: any = localStorage.getItem('user')
    var userParsed: iUser = JSON.parse(userStore)
    requestInstitute(
      {
        Cnes: userParsed.instituteCnes,
        PageNumber: 0,
        PageSize: 1,
      }
    );
  }, [])

  const formik = useFormik({
    initialValues: {
      corporateName: "",
      expectedNumberParticipants: 0,
      observation: "",
      questionUnityType: unityType,
      questionServiceForm: {
        id: 0,
        observation: ""
      },
      questionTrainingCategory: trainingCategory,
      questionTrainingPeriod: trainingPeriod,
      questionProfessionalToBeTraineds: professionalToBeTraineds
    },
    onSubmit: values => {
      const countUnityType = values.questionUnityType.reduce((count, answer) => answer.checked === true ? ++count : count, 0)
      const countTrainingCategory = values.questionTrainingCategory.reduce((count, answer) => answer.checked === true ? ++count : count, 0)
      const countTrainingPeriod = values.questionTrainingPeriod.reduce((count, answer) => answer.checked === true ? ++count : count, 0)
      const countProfessionalToBeTraineds = values.questionProfessionalToBeTraineds.reduce((count, answer) => answer.checked === true ? ++count : count, 0)

      if (countUnityType <= 0) {
        Snackbar({ message: 'Selecione pelo menos uma opção em Tipo da Unidade', type: 'error' })
        return;
      }
      if (values.questionServiceForm.id === 0) {
        Snackbar({ message: 'Selecione uma opção em Forma de Atendimento', type: 'error' })
        return;
      }
      if (countTrainingCategory <= 0) {
        Snackbar({ message: 'Selecione pelo menos uma opção em Categoria do treinamento', type: 'error' })
        return;
      }
      if (countTrainingPeriod <= 0) {
        Snackbar({ message: 'Selecione pelo menos uma opção em Períodos possíveis para o treinamento', type: 'error' })
        return;
      }
      if (countProfessionalToBeTraineds <= 0) {
        Snackbar({ message: 'Selecione pelo menos uma opção em Profissionais a serem capacitados', type: 'error' })
        return;
      }
      sendRequest(values).then(() => {
        Snackbar({ message: 'Solicitação enviada com sucesso!', type: 'success' })
        formik.resetForm()
        getInstitute()
      })
    },
  });

  const setChecked = (questionId: number, answerId: number, checked: boolean, event?: React.ChangeEvent) => {
    let question: Array<IQuestions> = []
    let setQuestion = ''
    if (questionId === 1) {
      question = formik.values.questionUnityType
      setQuestion = 'questionUnityType'
    } else if (questionId === 3) {
      question = formik.values.questionTrainingCategory
      setQuestion = 'questionTrainingCategory'
    } else if (questionId === 4) {
      question = formik.values.questionTrainingPeriod
      setQuestion = 'questionTrainingPeriod'
    } else if (questionId === 5) {
      question = formik.values.questionProfessionalToBeTraineds
      setQuestion = 'questionProfessionalToBeTraineds'
    }

    formik.setFieldValue(
      setQuestion,
      question.map((answer) => {
        if (answer.id === answerId) {
          return { ...answer, checked: checked }
        }
        return answer
      })
    );
  }

  const setObservation = (questionId: number, answerId: number, event: React.ChangeEvent<{ value: string }>) => {
    const observation = event.target.value
    let question: Array<IQuestions> = []
    let setQuestion = ''
    if (questionId === 1) {
      question = formik.values.questionUnityType
      setQuestion = 'questionUnityType'
    } else if (questionId === 3) {
      question = formik.values.questionTrainingCategory
      setQuestion = 'questionTrainingCategory'
    } else if (questionId === 4) {
      question = formik.values.questionTrainingPeriod
      setQuestion = 'questionTrainingPeriod'
    } else if (questionId === 5) {
      question = formik.values.questionProfessionalToBeTraineds
      setQuestion = 'questionProfessionalToBeTraineds'
    }

    formik.setFieldValue(
      setQuestion,
      question.map((answer) => {
        if (answer.id === answerId) {
          return { ...answer, observation: observation }
        }
        return answer
      })
    );
  }

  const requestInstitute = async ({ Cnes, PageNumber, PageSize }: InstituteProps) => {
    await getInstitute({
        Cnes: Cnes,
        PageNumber: PageNumber + 1,
        PageSize: PageSize
    }).then((response: Response) => {
      console.log(response.data.content[0].corporateName);
      if (typeof response.data.content === 'object' && response.data.content.length > 0) {
        formik.setFieldValue('corporateName', response.data.content[0].corporateName);
      }
    }).catch(((err) => {
        }))
  }

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={6}>
          <Typography variant="h5" style={{ fontWeight: 'bolder' }} color="textPrimary">Solicitação Capacitação Teste Pezinho</Typography>
          <Typography variant="subtitle1" color="textSecondary"></Typography>
        </Grid>
      </Grid>

      <form onSubmit={formik.handleSubmit}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={6}>

            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Box mt={2} mb={2}>
                  <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Nome da unidade</Typography>
                  <TextField variant="outlined" value={formik.values.corporateName} disabled fullWidth />
                </Box>
              </Grid>
            </Grid>

            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Box mt={2} mb={2}>
                  <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Qtd. prevista de participantes</Typography>
                  <TextField
                    id="number-participants"
                    type="number"
                    variant="outlined"
                    value={formik.values.expectedNumberParticipants}
                    onChange={(e) => formik.setFieldValue('expectedNumberParticipants', parseInt(e.target.value))}
                    InputProps={{ inputProps: { min: 1 } }}
                    required
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container justifyContent="center">
              <Grid item xs={12} lg={6} >
                <Box mt={2} mb={2} mr={1}>
                  <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Tipo da Unidade</Typography>
                  {formik.values.questionUnityType.map((type) => (
                    <>
                      <Checkbox checked={type.checked} onChange={(e) => setChecked(1, type.id, !type.checked, e)} color="primary" value={type.id} /> {type.name}
                      {type.checked && type.hasObservation ? (
                        <>
                          <TextField style={{ marginLeft: '10px' }} required onChange={(e) => setObservation(1, type.id, e)} value={type.observation} /> <br />
                        </>
                      )
                        :
                        <br />
                      }
                    </>
                  ))}
                </Box>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Box mt={2} mb={2}>
                  <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Forma de Atendimento</Typography>
                  <RadioGroup aria-label="serviceForm" name="serviceForm1" onChange={(e) => formik.setFieldValue('questionServiceForm', { id: parseInt(e.target.value), observation: '' })} value={formik.values.questionServiceForm.id}>
                    <FormControlLabel value={5} control={<Radio color="primary" />} label="Atendimento SUS dentro do município de São Paulo" />
                    <FormControlLabel value={6} control={<Radio color="primary" />} label="Atendimento SUS em outros municípios do estado de São Paulo" />
                    <FormControlLabel value={7} control={<Radio color="primary" />} label="Atendimento Particular/Convênio" />
                    <FormControlLabel value={8} control={<Radio color="primary" />} label={
                      formik.values.questionServiceForm.id === 8 ? (
                        <>Outros <TextField style={{ marginLeft: '5px' }} required value={formik.values.questionServiceForm.observation} onChange={(e) => formik.setFieldValue('questionServiceForm.observation', e.target.value)} /></>
                      )
                        :
                        'Outros'
                    } />
                  </RadioGroup>
                </Box>
              </Grid>

            </Grid>

            <Grid container justifyContent="center">

              <Grid item xs={12} lg={6}>
                <Box mt={2} mb={2} mr={1}>
                  <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Categoria do treinamento</Typography>
                  {formik.values.questionTrainingCategory.map((type) => (
                    <>
                      <Checkbox checked={type.checked} onChange={() => setChecked(3, type.id, !type.checked)} color="primary" value={type.id} /> {type.name}
                      {type.checked && type.hasObservation ? (
                        <>
                          <TextField style={{ marginLeft: '10px' }} required onChange={(e) => setObservation(3, type.id, e)} value={type.observation} /> <br />
                        </>
                      )
                        :
                        <br />
                      }
                    </>
                  ))}
                </Box>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Box mt={2} mb={2}>
                  <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Períodos possíveis para o treinamento</Typography>
                  {formik.values.questionTrainingPeriod.map((type) => (
                    <>
                      <Checkbox checked={type.checked} onChange={() => setChecked(4, type.id, !type.checked)} color="primary" value={type.id} /> {type.name}
                      {type.checked && type.hasObservation ? (
                        <>
                          <TextField style={{ marginLeft: '10px' }} required onChange={(e) => setObservation(4, type.id, e)} value={type.observation} /> <br />
                        </>
                      )
                        :
                        <br />
                      }
                    </>
                  ))}
                </Box>
              </Grid>

            </Grid>

            <Grid container>

              <Grid item xs={12}>
                <Box mt={2} mb={2}>
                  <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Profissionais a serem capacitados</Typography>
                  {formik.values.questionProfessionalToBeTraineds.map((type) => (
                    <>
                      <Checkbox checked={type.checked} onChange={() => setChecked(5, type.id, !type.checked)} color="primary" value={type.id} /> {type.name}
                      {type.checked && type.hasObservation ? (
                        <>
                          <TextField style={{ marginLeft: '10px' }} required onChange={(e) => setObservation(5, type.id, e)} value={type.observation} /> <br />
                        </>
                      )
                        :
                        <br />
                      }
                    </>
                  ))}
                </Box>
              </Grid>

            </Grid>

            <Grid container>

              <Grid item xs={12}>
                <Box mt={2} mb={2}>
                  <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Observações / Sugestões</Typography>
                  <TextField multiline rows={5} fullWidth variant="outlined" placeholder="Observações / Sugestões" value={formik.values.observation} onChange={(e) => formik.setFieldValue('observation', e.target.value)} />
                </Box>
              </Grid>

            </Grid>

          </Grid>
        </Grid>

        <Grid container justifyContent="center">
          <Grid item xs={12} sm={2}>
            <Box mr={1}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              // className={classes.submit}
              >
                Enviar
              </Button>
            </Box>
          </Grid>
        </Grid>

      </form>
    </>
  )
}

export default SuggestionsCompaints