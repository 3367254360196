import { Typography } from '@material-ui/core'
import { ErrorOutline } from '@material-ui/icons'

import useStyles from './styles'

function AlertPermission({ message }: any) {
  const classes = useStyles()

  return (
    <div className={classes.alertWrapper}>
      <ErrorOutline className={classes.alertIcon} />

      <Typography
        variant="subtitle1"
        color="textSecondary"
        className={classes.alertText}
      >
        {message}
      </Typography>
    </div>
  )
}

export default AlertPermission
