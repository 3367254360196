import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  Select,
  FormControl,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import {
  Search,
  Close,
  Edit,
  GetApp,
  Print,
  Visibility,
  Lock,
  KeyboardArrowRight,
  KeyboardArrowDown,
  History,
  Email,
} from "@material-ui/icons";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { debounce } from 'lodash';
import ModalSearchInstitutions, {
  CbInstituteProps,
} from "../../../components/ModalSearchInstitutions";
import { blockReport, getReportPDFBase64, getReports } from "../service";
import DataGrid from "../../../components/DataGrid";
import useStyles from "../style";
import history from "../../../routes/history";
import { useFormik } from "formik";
import { format } from "date-fns";
import DialogHistoryReport from "./DialogHistoryReport";
import DialogSendMailReport from "./DialogSendMailReport";
import ShowHasPermission from "../../../utils/showHasPermission";
import ModalConfirmation from "../../../components/ModalConfirmation";
import { Context } from "../../../providers/AuthProvider/contexts/AuthContext";
import { useMediaQuery } from "react-responsive";
import StatusCustomRenderTypes from "../../../components/StatusCustomRenderTypes";
import openPdfBlob from "../../../utils/openPdfBlob";

const ReportsPjIJC = () => {
  const classes = useStyles();

  const initialState: CbInstituteProps[] = [];

  const [data, setData] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [selectedInstitutes, setSelectedInstitutes] =
    useState<CbInstituteProps[]>(initialState);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [expand, setExpand] = useState<string | false>("filter");
  const [openHistory, setOpenHistory] = useState(false);
  const [idReportHistory, setIdReportHistory] = useState("");
  const [openSendEmail, setOpenSendEmail] = useState(false);
  const [idSendEmail, setIdSendEmail] = useState("");
  const [labelPrescription, setLabelPrescription] = useState("Prescrição");
  const [reportIdItemChanged, setReportIdItemChanged] =
    useState<boolean>(false);
  const [reportId, setReportId] = useState<string>("");
  const [searching, setSearching] = useState<boolean>(false);
  const { user } = useContext(Context);
  const isTablet = useMediaQuery({ minWidth: 600, maxWidth: 1100 });

  const [resetInstitutesSelection, setResetInstitutesSelection] = useState(false)

  const reports = (page: number) => {
    const filters = formik.values;

    getReports(filters, page).then((response) => {
      setData(response.reportPageResponse.content);
      setTotalPages(response.reportPageResponse.totalPages);
    });
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleChangeInstituteIcon = (event: any) => {
    event.stopPropagation();
    setSelectedInstitutes(initialState);
    formik.setFieldValue("InstituteIds", []);
        setResetInstitutesSelection(true);
  };

  const handleSearchInstitute = async (callback: CbInstituteProps[]) => {
    const ids = callback.map(institute => institute.tasyCode);
    formik.setFieldValue("InstituteIds", ids);
    setSelectedInstitutes(callback);
    setResetInstitutesSelection(false);
  };

  function PrescriptionColumns({ data }: any) {
    return (
      <>
        {data?.prescription}{" "}
        {labelPrescription === "Prescrição / Versão" &&
          `/ ${data?.versionNumber}`}
      </>
    );
  }

  const columns = [
    {
      name: "prescription",
      label: labelPrescription,
      width: "7%",
      minWidth: "123px",
      sortable: true,
    },
    {
      name: "batch",
      label: "Lote",
      width: "6%",
      minWidth: "105px",
      sortable: true,

    },
    {
      name: "dnv",
      label: "DNV",
      width: "7%",
      minWidth: "123px",
    },
    {
      name: "namePatient",
      label: "Nome Criança",
      sortable: true,
      width: "16%",
      minWidth: "261px",
    },
    {
      name: "bornDate",
      label: "Data de Nascimento",
      width: "10%",
      minWidth: "175px",
    },
    {
      name: "collectionDate",
      label: "Data da Coleta",
      width: "8%",
      minWidth: "140px",
    },
    {
      name: "motherName",
      label: "Filiação",
      width: "16%",
      minWidth: "244px",
    },
    {
      name: "status",
      label: "",
      width: "10%",
      minWidth: "187px",
      customRender: (index: any) => (
        <StatusCustomRenderTypes data={data[index]} />
      ),
    },
    {
      name: "",
      label: "",
      width: "17%",
      minWidth: "281px",
      customRender: (index: any) => {
        return (
          <>
            <div
              className="actions"
              style={
                isTablet
                  ? { flexDirection: "column" }
                  : { flexDirection: "row" }
              }
            >
              <span className="option">
                <Visibility
                  titleAccess="Visualizar"
                  onClick={() => showPdfNewTab(data[index].reportId, 1)}
                />
              </span>

              <span className="option">
                <GetApp
                  titleAccess="Download"
                  onClick={() => downloadPdf(data[index], 2)}
                />
              </span>

              <span className="option">
                <Print
                  titleAccess="Imprimir"
                  onClick={() => printPDFInApp(data[index].reportId, 3)}
                />
              </span>

              {ShowHasPermission("Laudo_EnvioEmail") && (
                <span className="option">
                  <Email
                    titleAccess="Enviar laudo por email"
                    onClick={() => openDialogSendEmail(data[index].reportId)}
                  />
                </span>
              )}

              {ShowHasPermission("Laudo_Bloqueio") && (
                <span className="option">
                  <Lock
                    titleAccess="Bloquear"
                    onClick={() => handleOpenModalConfirmation(data[index])}
                  />
                </span>
              )}

              {ShowHasPermission("Laudo_SolicitacaoAlteraçãoDados") && (
                <span className="option">
                  <Edit
                    titleAccess="Editar"
                    onClick={() =>
                      history.push(
                        `/laudo/alterar-dados/${data[index].reportId}`
                      )
                    }
                  />
                </span>
              )}

              {ShowHasPermission("Laudo_Historico") && (
                <span className="option">
                  <History
                    titleAccess="Histórico"
                    onClick={() => openDialogHistory(data[index].prescription)}
                  />
                </span>
              )}
            </div>
          </>
        );
      },
    },
  ];

  const [idReportBlock, setIdReportBlock] = useState(0);
  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    message: <></>,
  });

  const handleBlockReport = () => {
    blockReport(idReportBlock).then(() => reports(page));
  };

  const handleOpenModalConfirmation = (row: any) => {
    setIdReportBlock(row.reportId);
    const namePatient = row.namePatient;
    const message = (
      <>
        Deseja realmente bloquear o laudo de: <b>{namePatient}?</b>
      </>
    );

    setConfirmationModal({
      open: true,
      message: message,
    });
  };

  const handleCloseModalConfirmation = () => {
    setConfirmationModal({
      open: false,
      message: confirmationModal.message,
    });
  };

  const showPdfNewTab = (reportId: string, visualizationType: number) => {
    setReportIdItemChanged(true);
    setReportId(reportId);
    setSearching(false);

    getReportPDFBase64(reportId, visualizationType).then((pdfBase64) => {
      const contentType = "application/pdf";
      const blob = b64toBlob(pdfBase64, contentType);
      const blobUrl = openPdfBlob(blob);

      window.open(blobUrl, "_blank");
    });
  };

  const downloadPdf = (report: any, visualizationType: number) => {
    setReportIdItemChanged(true);
    setReportId(report.reportId);
    setSearching(false);

    getReportPDFBase64(report.reportId, visualizationType).then((pdfBase64) => {
      const contentType = "application/pdf";
      const blob = b64toBlob(pdfBase64, contentType);
      const blobUrl = openPdfBlob(blob);

      var objDownload = document.createElement("a");
      objDownload.download = `Laudo - ${report.namePatient} - ${report.collectionDate}`;
      objDownload.href = blobUrl;
      objDownload.click();
    });
  };

  const printPDFInApp = (reportId: string, visualizationType: number) => {
    setReportIdItemChanged(true);
    setReportId(reportId);
    setSearching(false);

    getReportPDFBase64(reportId, visualizationType).then((pdfBase64) => {
      const contentType = "application/pdf";
      const blob = b64toBlob(pdfBase64, contentType);
      const blobUrl = openPdfBlob(blob);

      var obj = document.createElement("iframe");

      obj.src = "";
      obj.src = blobUrl;
      obj.id = "pdfToShowPrint";

      var div = document.getElementById("hiddenPDFReport") as HTMLDivElement;
      div.innerHTML = "";
      div?.appendChild(obj);

      const iframeEle = document.getElementById(
        "pdfToShowPrint"
      ) as HTMLIFrameElement;

      if (iframeEle) {
        iframeEle.contentWindow!.print();
      }
    });
  };

  const b64toBlob = (b64Data: any, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize),
        byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const onChangeExpand =
    (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setExpand(newExpanded ? panel : false);
    };

  const initialSearchParam = () => {
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth(),
      d = date.getDate();
    var firstDay = format(new Date(y, m, d), "yyyy-MM-dd");
    var lastDay = format(new Date(y, m, d), "yyyy-MM-dd");

    let params = {
      CollectionDateStart: firstDay,
      CollectionDateEnd: lastDay,
      reportViewed: "T",
      reportPreviousVersions: false,
      InstituteIds: user ? user.instituteCnes : ''
    };

    formik.setFieldValue("CollectionDateStart", firstDay);
    formik.setFieldValue("CollectionDateEnd", lastDay);
    formik.setFieldValue("reportViewed", "T");
      formik.setFieldValue("reportPreviousVersions", false);
      formik.setFieldValue("InstituteIds", user ? user.instituteCnes : '');

    getReports(params).then((response) => {
      setData(response.reportPageResponse.content);
      setTotalPages(response.reportPageResponse.totalPages);
    });
  };

  useEffect(() => {
    initialSearchParam();
  }, []);

  interface FormikErrors {
    NamePatient?: string;
    MotherName?: string;
    Batch?: string;
    general?: string; // Agora incluído explicitamente
  }

  const formik = useFormik({
    initialValues: {
      CollectionDateStart: "",
      CollectionDateEnd: "",
      InstituteIds: [],
      NamePatient: "",
      MotherName: "",
      Batch: "",
      Dnv: "",
      medicalRecordNumber: "",
      reportViewed: "T",
      reportPreviousVersions: false,
    },
      validate: (values): FormikErrors => {
        const errors: FormikErrors = {};

        // Validação de Instituição com outros campos
        if (user != null && !user.instituteCnes) { 
          if (values.InstituteIds != null && values.InstituteIds.length > 0 && !values.NamePatient && !values.MotherName && !values.Batch) {
              errors.general = 'Quando uma instituição é selecionada, o preenchimento de "Nome Paciente", "Filiação" ou "Lote" é obrigatório.';
          }
        }

      return errors;
    },
    onSubmit: (values, { setErrors }) => {
      if (!values?.reportPreviousVersions) {
        setLabelPrescription("Prescrição");
      }

      if (values?.reportPreviousVersions) {
        setLabelPrescription("Prescrição / Versão");
      }

      // Checa se existem erros no formulário antes de proceder
      const formErrors = formik.validateForm();
      formErrors.then(errors => {
        if (Object.keys(errors).length === 0) {
          setSearching(false);
          getReports(values).then((response) => {
            setSearching(true);
            setData(response.reportPageResponse.content);
            setTotalPages(response.reportPageResponse.totalPages);
          });
        } else {
          setErrors(errors);
        }
      });
    },
  });

  const clearFilter = () => {
    formik.resetForm();
    setSelectedInstitutes(initialState);
    initialSearchParam();
  };

  const handleCloseHistory = () => {
    setOpenHistory(!openHistory);
  };

  const openDialogHistory = (prescription: string) => {
    setIdReportHistory(prescription);
    setOpenHistory(true);
  };

  const openDialogSendEmail = (idReport: string) => {
    setIdSendEmail(idReport);
    setOpenSendEmail(true);
  };

  const handleCloseSendEmail = (closeEmailSend: boolean) => {
    if (closeEmailSend) {
      setReportIdItemChanged(true);
      setSearching(false);
    }

    setOpenSendEmail(!openSendEmail);
  };

  const debouncedReports = debounce(reports, 300);
  
  const changePage = useCallback((newPage: number) => {
    if (page !== newPage) {
      setPage(newPage);
      debouncedReports(newPage);
      setReportIdItemChanged(false);
      setReportId("");
    }
  }, [page, formik.values, debouncedReports]);

  return (
    <>
      <div
        title="hiddenPDFReport"
        id="hiddenPDFReport"
        style={{ display: "none" }}
      />
      <DialogHistoryReport
        open={openHistory}
        handleClose={handleCloseHistory}
        prescription={idReportHistory}
      />
      <DialogSendMailReport
        open={openSendEmail}
        handleClose={handleCloseSendEmail}
        reportId={idSendEmail}
      />
      <ModalConfirmation
        open={confirmationModal.open}
        message={confirmationModal.message}
        title="Bloqueio de laudo"
        onClose={handleCloseModalConfirmation}
        onConfirm={handleBlockReport}
      />

      <Grid container>
        <Grid item xs={12} sm={12}>
          <Box mb={1}>
            <Typography
              variant="h5"
              style={{ fontWeight: "bolder" }}
              color="textPrimary"
            >
              Resultado de Exames
            </Typography>
            {/* <Typography variant='subtitle1' color='textSecondary'>
              Você pode alterar abaixo os dados anteriormente inseridos
            </Typography> */}
          </Box>
        </Grid>
      </Grid>

      <ModalSearchInstitutions
        open={open}
        onCallBack={handleSearchInstitute}
        onClose={onClose}
        resetSelection={resetInstitutesSelection}
      />

      <Accordion
        expanded={expand === "filter"}
        onChange={onChangeExpand("filter")}
      >
        <AccordionSummary aria-controls="filter" id="filter">
          {expand === "filter" ? (
            <KeyboardArrowDown style={{ marginRight: "5px" }} />
          ) : (
            <KeyboardArrowRight style={{ marginRight: "5px" }} />
          )}

          <Typography>Filtros</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: "flex", flexDirection: "column" }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container alignItems="flex-end">
              <Grid item xs={12} md={2}>
                <Box mb={1} mr={1}>
                  <Typography className={classes.typographyStyle}>
                    Período Solicitação:
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    margin="none"
                    label="Data Início"
                    id="start-date"
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    {...formik.getFieldProps("CollectionDateStart")}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={2}>
                <Box mb={1} mr={1}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    margin="none"
                    label="Data Fim"
                    id="end-date"
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    {...formik.getFieldProps("CollectionDateEnd")}
                  />
                </Box>
              </Grid>

              {user?.typeUser !== 2 && (
                <Grid item xs={12} md={4}>
                  <Box mb={1} mr={1}>
                    <Typography className={classes.typographyStyle}>
                      Instituição
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: "iconCursor",
                      }}
                      value={
                        selectedInstitutes.map(inst => inst.corporateName ? inst.corporateName.trim() : '')
                        .filter(name => name.length > 0)
                        .join(', ')
                      }
                      onClick={() => setOpen(true)}
                      size="small"
                      variant="outlined"
                      margin="none"
                      id="search-user-institute"
                      placeholder="Clique para selecionar a instituição"
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            {selectedInstitutes.length > 0 ? (
                              <Close
                                className="iconCursor"
                                onClick={handleChangeInstituteIcon}
                              />
                            ) : (
                              <Search className="iconCursor" />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} md={4}>
                <Box mb={1} mr={1}>
                  <Typography className={classes.typographyStyle}>
                    Nome Paciente
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    margin="none"
                    {...formik.getFieldProps("NamePatient")}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container alignItems="flex-end">
              <Grid item xs={12} md={2}>
                <Box mb={1} mr={1}>
                  <Typography className={classes.typographyStyle}>
                    Filiação
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    margin="none"
                    {...formik.getFieldProps("MotherName")}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={2}>
                <Box mb={1} mr={1}>
                  <Typography className={classes.typographyStyle}>
                    Lote
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    margin="none"
                    {...formik.getFieldProps("Batch")}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={2}>
                <Box mb={1} mr={1}>
                  <Typography className={classes.typographyStyle}>
                    DNV
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    margin="none"
                    {...formik.getFieldProps("Dnv")}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={2}>
                <Box mb={1} mr={1}>
                  <Typography className={classes.typographyStyle}>
                    Prontuário
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    margin="none"
                    {...formik.getFieldProps("medicalRecordNumber")}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Box mb={1} mr={1}>
                  <Typography className={classes.typographyStyle}>
                    Visualizar
                  </Typography>

                  <FormControl
                    variant="outlined"
                    size="small"
                    margin="none"
                    fullWidth
                  >
                    <Select
                      placeholder="Visualizar"
                      labelId="reportViewed"
                      id="reportViewed"
                      fullWidth
                      displayEmpty
                      onChange={(e) =>
                        formik.setFieldValue("reportViewed", e.target.value)
                      }
                      value={formik.values.reportViewed}
                      error={
                        formik.touched.reportViewed &&
                        Boolean(formik.errors.reportViewed)
                      }
                    >
                      <MenuItem value="T">Tudo</MenuItem>
                      <MenuItem value="N">Não Visualizados</MenuItem>
                      <MenuItem value="S">Visualizados</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} md={1}>
                <Box mb={1} mr={1}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Pesquisar
                  </Button>
                </Box>
              </Grid>

              <Grid item xs={12} md={1}>
                <Box mb={1} mr={1}>
                  <Button
                    onClick={clearFilter}
                    fullWidth
                    variant="contained"
                    color="secondary"
                  >
                    Limpar
                  </Button>
                </Box>
              </Grid>
            </Grid>

            {user?.typeUser === 0 && (
              <Grid item xs={12} sm={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="reportPreviousVersions"
                      size="medium"
                      {...formik.getFieldProps("reportPreviousVersions")}
                      checked={formik.values.reportPreviousVersions}
                      color="primary"
                    />
                  }
                  label="Exibir versões anteriores dos laudos"
                />
              </Grid>
            )}

            {formik.errors.general && <div style={{ textAlign: 'right', color: 'red', position: 'relative', top: '-32px' }}>{formik.errors.general}</div>}


          </form>
        </AccordionDetails>
      </Accordion>

      <DataGrid
        data={data}
        columns={columns}
        totalPages={totalPages}
        changePage={changePage}
        reportIdItemChanged={{
          page,
          changed: reportIdItemChanged,
          reportId,
          filters: formik.values,
          searching,
        }}
      />
    </>
  );
};

export default ReportsPjIJC;
