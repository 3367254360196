import { useEffect } from 'react'
import { Router } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core';
import { Toaster } from 'react-hot-toast';

import './style.tsx';
import Routes from '../../routes';
import history from '../../routes/history';
import { AuthProvider } from '../../providers/AuthProvider/contexts/AuthContext';
import SimpleBackdrop from '../../components/LoadingBackdrop';
import theme from './style';

function App() {
  useEffect(() => {
    const script = document.createElement('script')

    script.type = 'text/javascript'
    script.id = 'recaptchaAPI'
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_PK}`
    script.async = true

    document.body.appendChild(script)
  }, [])

  return (
    <AuthProvider>
      <MuiThemeProvider theme={theme}>
        <SimpleBackdrop />
        <Router history={history}>
          <Routes />
        </Router>
        <Toaster position="bottom-center"/>
      </MuiThemeProvider>
    </AuthProvider>
  )
}

export default App;
