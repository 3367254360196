import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(
  ({
    text: {
      marginBottom: '10px',
      fontSize: '16px'
    }
  })
)
