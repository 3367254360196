import useStyles from "./style";
import SignaturePad from 'react-signature-pad-wrapper';
import {Button, Grid, Typography} from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import * as service from '../../service/evolution-service'
import {useParams} from "react-router-dom";
import history from "../../../../routes/history";

interface IRouteParams {
    evolutionId?: string;
    type?: string
}

const EvolutionSign = () => {
    const style = useStyles();


    const { evolutionId, type } = useParams<IRouteParams>();
    const [baseData, setBaseData]= useState<string>('data:image/png;base64,');
    const [signatureImage, setSignatureImage] = useState<string>('');

    const [signaturePadRef, setSignaturePadRef] = useState(React.createRef<SignaturePad>);

    useEffect(() => {
        service.getPatientSignature(Number(evolutionId), Number(type))
            .then((response) => setSignatureImage(response.attachment))
            .catch((error) => console.error(error));
    }, []);

    useEffect(() => {
        // @ts-ignore
        signaturePadRef.current.penColor = 'rgb(230, 70, 131)';
        if (signatureImage) {
            // @ts-ignore
            signaturePadRef.current?.fromDataURL(baseData.concat(signatureImage));
        }
    }, [signatureImage]);

    function clear(): void {
        service.removeSignature(evolutionId as string, type as string).then(res => {
            // @ts-ignore
            signaturePadRef.current.clear();
        }).catch(err => {

        })
    }

    function back() {
        history.push(`/formulario-evolucao/${evolutionId}/3`)
    }

    function save(): void {
        // @ts-ignore
        const data = signaturePadRef?.current?.toDataURL() || '';
        service.savePatientSignature(Number(evolutionId), Number(type), data);
    }

    return(
        <Grid>
            <Typography color='primary'>Assine na linha abaixo:</Typography>
            <SignaturePad ref={signaturePadRef}/>
            <hr/>
            <Grid className={style.signPad}>
                <Button onClick={clear}>Limpar</Button>
                <Grid className={style.singPadRightButtons}>
                    <Button onClick={() => back()}>Sair</Button>
                    <Button onClick={() => save()} color='primary'>Salvar</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}


export default EvolutionSign
