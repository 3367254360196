import { makeStyles } from "@material-ui/core/styles";
import Logo from '../../assets/images/logo-ijc.png';

export default makeStyles((theme) => ({
    root: {
        height: '100vh'
    },
    imageMobile: {
        width:'100vw',
        height:'30vh',
        filter: 'hue-rotate(300deg) saturate(0.8)'
   },
    image: {
         backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
         backgroundImage: `linear-gradient(rgba(141, 153, 174, 0.5), rgba(231, 68, 129, 0.8)), url(${Logo})`,
         backgroundSize: '42% 100vh',
         backgroundRepeat: 'no-repeat',
         backgroundAttachment: 'fixed'
    },
    paper: {
        margin: theme.spacing(4, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        justifyContent:'center',
        display:'flex'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        maxWidth: '500px'
    },
    submit: {
        textTransform: 'none',
        height: '50px',
        fontSize: '17px',
        margin: theme.spacing(3, 0, 2),
    },
    linkRequestPass: {
        color: '#e64783',
        fontWeight: 'bold',
    },
    paperRadio:{
        width: 'auto',
        height: 120,
        fontWeight: 'bold',
        padding: '10px',
        marginRight:'10px',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#f2f2f2'
    },
    buttonChangeLogin:{
        color:'white',
        cursor: 'pointer',
        backgroundColor:'#FD5F4A',
        position: 'fixed',
        width: 125,
        height: 135,
        margin: 10,
        borderRadius: 5
    },
    buttonChangeLoginMobile:{
        color:'white',
        cursor: 'pointer',
        backgroundColor:'#FD5F4A',
        position: 'absolute',
        width: 100,
        height: 115,
        margin: 10,
        borderRadius: 5,
    },
    textConnect: {
      fontSize: '15px',
      display: 'inline-block',
    },
    textConnectLink: {
      textDecoration: 'underline',
      cursor: 'pointer',
    }
}));

