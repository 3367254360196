function openPdfBlob(blob: Blob | MediaSource) {
  let blobUrl = URL.createObjectURL(blob)

  const nav = (window.navigator as any)

  if(nav.msSaveOrOpenBlob)
    blobUrl = nav.msSaveOrOpenBlob(blob)

  return blobUrl
}

export default openPdfBlob
