import api from "../../utils/api"
import { IEmailReport } from "./components/DialogSendMailReport";


export const getReports = async (params?: any, pageNumber?: number): Promise<any> => {
  const { data } = await api.get('/report', {
    params: {
      ...params,
      pageNumber
    }
  })

  return data
}

export const blockReport = async (reportId: number): Promise<void> => {
  await api.put(`/report/block/${reportId}`)
}

export const getReportPDFBase64 = async (reportId: string, visualizationType: number): Promise<any> => {
  const { data } = await api.get(`/report/${reportId}/pdf`, {
    params: {
      visualizationType,
    }
  })

  return data
}

export const getHistoryReport = async (reportId: string): Promise<any> => {
  const historyReport = await api.get(`/report/${reportId}/historic`)
  return historyReport.data
}

export const sendReportEmail = async (data:IEmailReport): Promise<any> => {
  return await api.post('report/send-email', data)
}
