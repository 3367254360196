import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  status: {
    padding: '10px',
    borderRadius: '5px',
    textAlign: 'center'
  },

  availableColor: {
    backgroundColor: 'rgba(0, 218, 48, 0.2)',
    color: '#006F18',
    marginRight: '10px'
  },

  notAvailableColor: {
    backgroundColor: 'rgba(178,100,14,0.2)',
    color: '#ab5f0a'
  },

  changedColor: {
    color: '#727272',
    backgroundColor: 'rgba(114, 114, 144, 0.2)',
  },

  typographyStyle: {
    fontSize: `0.9rem`,
    fontWeight: `bold`,
    marginBottom: `10px`
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
}))
