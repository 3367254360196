import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    "&.MuiSelect-root":{
        "&.MuiSelect-select.Mui-disabled":{
            border: 'solid red'
        }
    },
    inputLabel: {
        fontSize: '15px',
        fontWeight: 'bold',
        color: '#5f5f5f',
    },
    button: {
        textTransform: 'none',
        height: '50px',
        fontSize: '17px',
    },
    textLink: {
      color: '#e64783',
    },
    label: {
      color: '#5f5f5f',
      fontSize: '15px',
      fontWeight: 'bold',
      cursor: 'pointer',
    }
}))
