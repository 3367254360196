import { Box, Button, Link, Grid, TextField, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from "@material-ui/core"

import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import history from '../../../routes/history';
import { getMaterialsById, getMaterialStatusLog } from "../services";
import { IMaterialContent, IMaterialRequestLog } from '../interfaces'
import { Context } from '../../../providers/AuthProvider/contexts/AuthContext';
import { dateStringToDateFormat , dateStringToDateTimeFormat} from '../../../utils/convertDate';
import { ModalStatusConfirmation } from "../components/ModalStatusConfirmation";
import showHasPermission from '../../../utils/showHasPermission'
interface IRouteParams {
    requestNumber: string;
}

type StatusOptions = 'Entregue' | 'Cancelada' | 'Confirmada'

export const MaterialRquestDetails = () => {

    const { user } = useContext(Context);
    const [materialStatusLog, setMaterialStatusLog] = useState<IMaterialRequestLog[]>([])
    
    const [solicitationStatusClicked, setSolicitationStatusClicked] = useState<StatusOptions>('Confirmada')
    const [statusNumber , setStatusNumber] = useState<number>(0)
    const [materialItem, setMaterialItem] = useState<IMaterialContent>()
    const { requestNumber } = useParams<IRouteParams>()
    const [confirmationModal, setConfirmationModal] = useState<boolean>(false)
    
    useEffect(() => {

        async function getMaterial() {
            try {
                await getMaterialsById(requestNumber).then( data => 
                    {
                        setMaterialItem(data)
                        setStatusNumber(data.status)
                    })
                }
            catch (error) {
                console.log(error)
                history.push('/solicitacao-de-materiais/')
            }

        }

        async function getStatusLog() {
            try {
                const getStatusLog = await getMaterialStatusLog(requestNumber);
                
                setMaterialStatusLog(getStatusLog.map(log => ({
                    ...log,
                    createdAt : dateStringToDateTimeFormat(log.createdAt)
                })))
            }
            catch (error) {
                console.log(error)
                history.push('/solicitacao-de-materiais/')
            }
        }

        getMaterial().then(r => getStatusLog()) //catch material request data 
        //getStatusLog() catch material status log
    }, [requestNumber , statusNumber ])

    const onChangeRequestMaterialStatus = (value : StatusOptions) => {
        setSolicitationStatusClicked(value) 
       
        handleOpenModalConfirmation()
    }



    //Modal Config

    const handleOpenModalConfirmation = () => {
        

        setConfirmationModal(true)
    }

    const handleCloseModalConfirmation = () => {
        setConfirmationModal(false)
    }

    return (
        <>

            <Grid container className='header-grid' justifyContent='space-between' >

                <Box mt={2} mb={2}>
                    <Grid item >
                        <Link style={{ width: '100%', fontWeight: 'bold' }} href="" variant="body2" onClick={() => history.push('/solicitacao-materiais')} >
                            Voltar
                        </Link>
                    </Grid>
                    <Grid item >
                        <Typography variant="h5" color="textPrimary">Detalhes Solicitação Material</Typography>
                    </Grid>


                </Box>
            </Grid>
            {materialItem && (
                <Grid container  >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box mb={2} >
                                <Typography className='typographyStyle'>Número Solicitação:</Typography>
                                <TextField
                                    fullWidth
                                    disabled

                                    size="small"
                                    value={materialItem.id}
                                    variant="outlined"
                                    margin="none"
                                    id="search-user-name"
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={4}>

                            <Box mb={2} >
                                <Typography className='typographyStyle'>Data Solicitação:</Typography>
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    margin="none"
                                    id="search-user-email"
                                    disabled
                                    value={dateStringToDateFormat(materialItem.requestDate)}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Box mb={2} >
                                <Typography className='typographyStyle'>Status : </Typography>
                                <TextField
                                    fullWidth
                                    disabled
                                    size="small"
                                    variant="outlined"
                                    margin="none"
                                    id="search-user-email"
                                    value={materialItem.statusName}
                                />
                            </Box>
                        </Grid>


                    </Grid>
                    <Grid container >
                        <Grid item xs={12} sm={12}>
                            <Box mb={2} >
                                <Typography className='typographyStyle'>Instituição:</Typography>
                                <TextField
                                    fullWidth

                                    size="small"
                                    disabled
                                   
                                    variant="outlined"
                                    margin="none"
                                    id="search-user-name"
                                    value={`${materialItem.instituteCnes} - ${materialItem.institutionCorporateName}`}

                                />
                            </Box>
                        </Grid>

                    </Grid>
                    <Grid container spacing={2}>

                        <Grid item xs={12} sm={8} >
                            <Box mb={2} >
                                <Typography className='typographyStyle'>Tipo Material:</Typography>
                                <TextField
                                    fullWidth

                                    size="small"
                                    variant="outlined"
                                    margin="none"
                                    disabled
                                    id="search-user-name"
                                    value={materialItem.materialRequestTypeName}

                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Box mb={2} >
                                <Typography className='typographyStyle'>Qtde. Solicitada:</Typography>
                                <TextField
                                    fullWidth

                                    size="small"
                                    variant="outlined"
                                    margin="none"
                                    id="search-user-name"
                                    disabled
                                    value={materialItem.amount}

                                />
                            </Box>
                        </Grid>


                    </Grid>
                </Grid>
            )}

            <Box mt={3} mb={3}>
                <Typography variant="h5" color="textPrimary" style={{ marginBottom: '1rem' }}>Histórico:</Typography>
                <TableContainer component={Paper}>
                    <Table size='small' aria-label='a historic table'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Data/Hora</TableCell>
                                <TableCell>Histórico</TableCell>
                                <TableCell>Usuário Responsável</TableCell>

                            </TableRow>
                        </TableHead>
                        {materialStatusLog.length === 0 ? (
                            <TableBody>
                            <TableRow>
                                <TableCell align='right' padding='normal'><i>Nenhum registro encontrado.</i></TableCell>
                            </TableRow>
                        </TableBody>
                        ): (
                            <TableBody>
                                {materialStatusLog.map((row: IMaterialRequestLog) => (
                                    
                                    <TableRow key={row.id}>
                                        <TableCell>{row.createdAt}</TableCell>
                                        <TableCell>
                                           
                                             <>
                                               <p>
                                                   {row.newStatusDescription}
                                                </p> 
                                                <p>
                                                    {row.newStatus===3 && <label>Motivo : </label>}
                                                    {row.observation}
                                                </p>
                                            </>
                                        </TableCell>
                                        <TableCell>{row.userName}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                      

                    </Table>
                </TableContainer>
            </Box>
      

            {user?.typeUser === 0 && (
                <Grid container item justifyContent="flex-start" spacing={3}>
                    { statusNumber === 0 && showHasPermission('SolicitacaoMaterial_AlteraçãoStatusConfirmada') && (
                        <Grid item xs={12} sm={3} md={2}>
                            <Button fullWidth variant="contained" onClick={() => onChangeRequestMaterialStatus( 'Confirmada')} color="primary">Confirmar Solicitação</Button>
                        </Grid>
                    )}
                    { statusNumber === 1 && showHasPermission('SolicitacaoMaterial_AlteraçãoStatusEntregue') && (
                        <Grid item xs={12} sm={3} md={2}>

                            <Button onClick={() => onChangeRequestMaterialStatus( 'Entregue')} fullWidth variant="contained" color="primary">Marcar como Entregue</Button>
                        </Grid>
                    )}
                    { statusNumber !== 3 && showHasPermission('SolicitacaoMaterial_AlteraçãoStatusCancelado') && (
                        <Grid item xs={12} sm={3} md={2}>

                            <Button  onClick={() => onChangeRequestMaterialStatus('Cancelada' )}  fullWidth variant="contained" color="secondary">Cancelar Solicitação</Button>
                        </Grid>
                    )}
                </Grid>
            )}
            <ModalStatusConfirmation
                open={confirmationModal}
                title='Confirmar Status da Solicitação'
                onClose={handleCloseModalConfirmation}
                statusModeClicked={solicitationStatusClicked}
                setStatusMode={setStatusNumber}
                materialItemId={materialItem?.id}
            />
        </>
    )
}