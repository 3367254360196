import { AttachFileOutlined, DeleteOutline } from '@material-ui/icons'
import { ClassNameMap } from '@material-ui/styles'
import Dropzone from 'react-dropzone'
import Snackbar from '../Snackbar'

import useStyles from './style'

type propsUploadFile = {
  file: File | File[] | undefined
  setFile: (file: File | File[] | undefined) => void
  optionalClass?: ClassNameMap<
    | "dropZoneBox"
    | "icon"
    | "dropZoneArea"
    | "dropZoneBgUnUploaded"
    | "dropZoneBgUploaded"
  >
  labelFileInput?: string
  isDisabled?: boolean
  multipleFiles?: boolean
}

const UploadFile = ({
  file,
  setFile,
  isDisabled,
  labelFileInput = "Upload de arquivo",
  multipleFiles,
}: propsUploadFile) => {
  const classes = useStyles()

  const handleGetFile = (
    acceptedFile: File,
    fileRejections: any[],
    acceptedFiles?: File[]
  ) => {
    if (acceptedFiles && multipleFiles) {
      setFile(acceptedFiles)
    } else {
      setFile(acceptedFile)
    }

    if (fileRejections.length > 0) {
      Snackbar({
        message:
          "Formato do arquivo inválido. São permitidos apenas imagem e PDF",
        type: "error",
      })
    }
  }

  return (
    <div
      className={` ${classes.dropZoneBox} ${
        isDisabled && file === undefined
          ? classes.disabledDropZoneBgUpload
          : file === undefined
          ? classes.dropZoneBgUnUploaded
          : classes.dropZoneBgUploaded
      }`}
    >
      <Dropzone
        accept={["image/*", "application/pdf"]}
        maxFiles={0}
        multiple={multipleFiles || false}
        onDrop={(a, e) => handleGetFile(a[0], e, a)}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              {...getRootProps()}
              style={
                isDisabled ? { cursor: "not-allowed" } : { cursor: "pointer" }
              }
              className={classes.dropZoneArea}
            >
              <input disabled={isDisabled} {...getInputProps()} />
              <AttachFileOutlined className={classes.icon} />
              <span>
                {file === undefined
                  ? labelFileInput
                  : "Arquivo(s) adicionado(s)"}
              </span>
            </div>
          </section>
        )}
      </Dropzone>
      {file === undefined ? (
        ""
      ) : (
        <div
          className={classes.dropZoneArea}
          style={{ cursor: "pointer", borderTop: "1px solid" }}
          onClick={() => setFile(undefined)}
        >
          <DeleteOutline className={classes.icon} />
          <span>Excluir Arquivo</span>
        </div>
      )}
    </div>
  )
}

export default UploadFile
