/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Fab, FormControl, Grid, InputAdornment, MenuItem, Select, TextField, Typography } from "@material-ui/core"
import { Add, Search, Edit, LockOpen, Close, Visibility } from "@material-ui/icons"
import { useState, useContext } from "react"
import ModalSearchInstitution, { CbInstituteProps } from "../../../../components/ModalSearchInstitution"
import ShowHasPermission from "../../../../utils/showHasPermission"
import MUIDataTable from "mui-datatables";
import ContextUsers from '../../context'
import { useEffect } from "react"
import { IGetUserProps, IUsers } from '../../interfaces'
import { getGroupUser, unlockUser } from '../../services'
import { debounce } from "lodash"
import './style.css'
import Snackbar from "../../../../components/Snackbar"
import { Context } from "../../../../providers/AuthProvider/contexts/AuthContext"
import { useMediaQuery } from 'react-responsive'


const ViewDataGridUser = () => {

    type SelectableRows = 'multiple' | 'single' | 'none'
    type ResponsiveType = 'vertical' | 'standard' | 'simple'

    const isTablet = useMediaQuery({ minWidth: 600, maxWidth: 1100 })

    const { user } = useContext(Context);

    const initialState = {
        tasyCode: 0,
        cnes: '',
        corporateName: '',
        cnpj: '',
        cityState: '',
        webAccess: ''
    }

    async function loadUsers() {
        const userData = await getGroupUser({ searchCnes: user.instituteCnes });
        setDataUsers(userData.content)
        setTotalRows(userData.totalRecords)
        setRowsPerPage(userData.pageable.pageSize)
    }

    const initialValuePagination = () => {
        setPage(0)
        setTotalRows(5)
    }

    const { setMode, setUserId, setIsReadOnly } = useContext(ContextUsers)
    const [dataUsers, setDataUsers] = useState<IUsers[]>([]);

    //Pagination states
    const [totalRows, setTotalRows] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [page, setPage] = useState(0)

    //Search states
    const [searchStatusMemo, setSearchStatus] = useState('all')
    const [searchTypeUserMemo, setSearchTypeUser] = useState('all')
    const [searchUser, setSearchUser] = useState()
    const [searchEmail, setSearchEmail] = useState()
    const [searchCnesState, setSearchCnesState] = useState('')

    const [open, setOpen] = useState(false);
    const [cbInstitute, setCbInstitute] = useState<CbInstituteProps>(initialState)

    const onUnlockUser = async (userId: number) => {
        try {
            await unlockUser(userId)
            setUserId(userId)
            Snackbar({ message: 'Usuário desbloqueado com sucesso', type: 'success' })
            await loadUsers()
        }
        catch (e) {
            // Interceptor 
        }


        //DISPARAR TOAST DE SUCESSO , caso o response seja 200

    }



    const onViewCallback = (userId: number) => {
        setUserId(userId)
        setIsReadOnly(true)
        setMode('edit')
    }

    const onEditCallback = (userId: number) => {
        setUserId(userId)
        setIsReadOnly(false)
        setMode('edit')
    }

    const onAddCallback = () => {
        setIsReadOnly(false)
        setMode('add')
    }

    const onClose = () => {
        setOpen(false);
    }

    const requestInstitute = async ({ pageNumber,
        pageSize,
        searchUserValue,
        searchEmailValue,
        searchStatus,
        searchTypeUser,
        searchCnes
    }: IGetUserProps) => {
        await getGroupUser({
            pageNumber: pageNumber + 1,
            pageSize,
            searchUserValue: searchUserValue ?? searchUser,
            searchEmailValue: searchEmailValue ?? searchEmail,
            searchStatus: searchStatus ?? searchStatusMemo,
            searchTypeUser: searchTypeUser ?? searchTypeUserMemo,
            searchCnes: searchCnes ?? searchCnesState,
        }).then(data => {
            setDataUsers(data.content)
            setTotalRows(data.totalRecords)
            setPage(data.pageable.pageNumber - 1)
            setRowsPerPage(data.pageable.pageSize)
        }).catch(((err) => {
            initialValuePagination()
        }))
    }

    const handleChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
        const status: any = event.target.value
        setSearchStatus(status)
        requestInstitute({ pageNumber: 0, pageSize: rowsPerPage, searchStatus: status, searchCnes: user.instituteCnes })
    };

    const handleChangeTypeUser = (event: React.ChangeEvent<{ value: unknown }>) => {
        const typeUser: any = event.target.value
        setSearchTypeUser(typeUser)
        requestInstitute({ pageNumber: 0, pageSize: rowsPerPage, searchTypeUser: typeUser })
    }

    const handleChangeInstituteIcon = (event: any) => {
        event.stopPropagation();
        setCbInstitute(initialState)
    }

    const onSearchUserFilter = async (event: any) => {
        const searchValue = event.target.value

        setSearchUser(searchValue)
        await requestInstitute({ pageNumber: 0, pageSize: rowsPerPage, searchUserValue: searchValue, searchCnes: user.instituteCnes })
    }

    const onSearchEmailFilter = (event: any) => {
        const searchValue = event.target.value

        setSearchEmail(searchValue)
        requestInstitute({ pageNumber: 0, pageSize: rowsPerPage, searchEmailValue: searchValue, searchCnes: user.instituteCnes })
    }



    useEffect(() => {
        loadUsers()
    }, [])

    useEffect(() => {
        if (cbInstitute.cnes !== searchCnesState) {
            setSearchCnesState(cbInstitute.cnes);
            requestInstitute({ pageNumber: 0, pageSize: 10, searchCnes: cbInstitute.cnes });
        }
    }, [cbInstitute, searchCnesState]);

    const options = {
        search: false,
        download: false,
        expandableRowsHeader: false,
        filter: false,
        print: false,
        selectableRowsHeader: false,
        viewColumns: false,
        rowsPerPageOptions: [5, 10],
        rowsPerPage: rowsPerPage,
        responsive: 'simple' as ResponsiveType,
        selectableRows: 'none' as SelectableRows,
        page: page,
        textLabels: {
            body: {
                noMatch: "Nenhum registro encontrado.",
            },
            pagination: {
                next: "Próxima Pagina",
                previous: "Pagina Anterior",
                rowsPerPage: "",
                displayRows: "de",
            },
        },
        count: totalRows,
        serverSide: true,
        onTableChange: async (action: any, tableState: any) => {
            switch (action) {
                case 'changePage':
                    requestInstitute({ pageNumber: tableState.page, pageSize: tableState.rowsPerPage });
                    break;
                case 'changeRowsPerPage':
                    requestInstitute({ pageNumber: tableState.page, pageSize: tableState.rowsPerPage });

                    break;
                default:
            }
        },
        setTableProps: () => {
            return {
                size: 'small'
            };
        },
    }

    const formatCnes = (cnes: string) => {
        if (!cnes) {
            return cnes;
        }
        return cnes
            .split(',')
            .map(item => item.trim())
            .join(', ');
    }
    const columns = [
        {
            name: "id",
            label: "Código",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "name",
            label: "Nome",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "email",
            label: "E-mail",
            options: {
                display: !isTablet,
                filter: false,
                sort: false,
                customBodyRender: (value: string) => {
                    if (!value) {
                        return value;
                    }
                    return value.toLowerCase();
                }
            }
        },
        {
            name: "typeUserName",
            label: "Tipo Usuário",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "instituteCnes",
            label: "CNES",
            options: {               
                filter: false,
                sort: false,
                customBodyRender: (value: string) => {
                    return formatCnes(value);
                }
            }
        },

        {
            name: "active",
            label: "Ativo",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (index: boolean) => {
                    return (
                        <>{index ? 'Sim' : 'Não'}</>
                    )
                }
            },
        },
        {
            name: "Ações",
            options: {
                filter: false,
                sort: false,
                empty: true,
                setCellProps: () => ({ align: "center", }),
                setCellHeaderProps: () => ({ align: "center", }),
                customBodyRenderLite: (index: number) => {
                    return (
                        <Grid container  /* justifyContent="center" alignItems="center" */>
                            <Grid item xs={4} md={4} >
                                <Button color="primary" onClick={() => { onEditCallback(dataUsers[index].id) }} disabled={!ShowHasPermission('Usuário_Alteração')}>
                                    <Edit titleAccess='Editar' />
                                </Button>
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <Button style={{ color: 'rgb(43,100,150)', cursor: 'pointer' }} onClick={() => { onViewCallback(dataUsers[index].id) }}>
                                    <Visibility titleAccess='Visualizar Dados' />
                                </Button>
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <Button color="secondary" disabled={!ShowHasPermission('Usuário_DesbloqueioUsuarios') || !dataUsers[index].blockedUser}
                                    onClick={() => onUnlockUser(dataUsers[index].id)}>
                                    <LockOpen titleAccess='Desbloquear Usuário' />
                                </Button>
                            </Grid>

                        </Grid>
                    );
                }
            }
        }
    ];

    return (
        <>
            <ModalSearchInstitution open={open} onCallBack={setCbInstitute}
                onClose={onClose} />

            <Grid container className='header-grid'>
                <Grid item >
                    <Typography variant="h5" color="textPrimary">Usuários</Typography>
                </Grid>
            </Grid>
            <Box pt={'10px'} className='mainBox'>
                <Grid container justifyContent="space-between" alignItems="flex-end">
                    <Grid item sm={2}>
                        <Box mb={2}>
                            <Fab title='Adicionar' size="small" color="primary" onClick={onAddCallback}
                                aria-label="add" disabled={!ShowHasPermission('Usuário_Cadastro')}>
                                <Add />
                            </Fab>
                        </Box>
                    </Grid>
                    <Grid item container sm={10} justifyContent={"flex-start"}>

                        {/* {user?.typeUser !== 2 ? ( */}
                        <Grid item xs={12} sm={5}>
                            <Box mb={2} mr={1}>
                                <Typography className='typographyStyle'>Usuário</Typography>
                                <TextField
                                    fullWidth
                                    onChange={debounce((e) => onSearchUserFilter(e), 1000)}
                                    size="small"
                                    variant="outlined"
                                    margin="none"
                                    id="search-user-name"
                                    placeholder="Digite o nome"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Search />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Box>
                        </Grid>
                        {/* ) : ''} */}

                        <Grid item xs={12} sm={5}>
                            <Box mb={2} mr={1}>
                                <Typography className='typographyStyle'>E-mail</Typography>
                                <TextField
                                    fullWidth
                                    onChange={debounce((e) => onSearchEmailFilter(e), 1000)}
                                    size="small"
                                    variant="outlined"
                                    margin="none"
                                    id="search-user-email"
                                    placeholder="Digite o e-mail"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Search />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Box mb={2} mr={1}>
                                <Typography className='typographyStyle'>Status</Typography>
                                <FormControl variant="outlined" size="small" margin="none" fullWidth>
                                    <Select
                                        placeholder="Status"
                                        labelId="status-lb"
                                        id="slc-status"
                                        onChange={handleChangeStatus}
                                        value={searchStatusMemo}
                                    >
                                        <MenuItem value={'all'}>Todos</MenuItem>
                                        <MenuItem value={'true'}>Ativos</MenuItem>
                                        <MenuItem value={'false'}>Inativos</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                {user?.typeUser !== 2 && (
                    <Grid container justifyContent="flex-end">
                        <Grid item xs={12} sm={6}>
                            <Box mb={2} mr={1}>
                                <Typography className='typographyStyle'>Perfil</Typography>
                                <FormControl variant="outlined" size="small" margin="none" fullWidth>
                                    <Select
                                        placeholder="Perfil"
                                        labelId="perfil-lb"
                                        id="slc-perfil"
                                        onChange={handleChangeTypeUser}
                                        value={searchTypeUserMemo}
                                    >
                                        <MenuItem value={'all'} selected /* value={'all'} */>Todos</MenuItem>
                                        <MenuItem value={'0'}  /* value={'usuario_interno'} */  >Usuários Internos</MenuItem>
                                        <MenuItem value={'2'}  /* value={'usuario_instituicao'} */  >Usuários Instituições</MenuItem>
                                        <MenuItem value={'1'}/* value={'usuario_particular'} */  >Clientes Particulares</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                            <Box mb={2} mr={1}>
                                <Typography className='typographyStyle'>Instituição</Typography>

                                {cbInstitute && (
                                    <TextField
                                        fullWidth
                                        inputProps={
                                            {
                                                className: 'iconCursor',

                                            }
                                        }
                                        value={cbInstitute.corporateName}
                                        onClick={() => setOpen(true)}
                                        size="small"
                                        variant="outlined"
                                        margin="none"
                                        id="search-user-institute"
                                        placeholder="Clique para selecionar a instituição"
                                        InputProps={{

                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {cbInstitute.corporateName !== '' ?
                                                        <Close className='iconCursor' onClick={handleChangeInstituteIcon} />
                                                        : <Search className='iconCursor' />}

                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}

                            </Box>
                        </Grid>
                    </Grid>
                )}
                {dataUsers && (
                    <MUIDataTable
                        options={options}
                        title={""}
                        data={dataUsers}
                        columns={columns}
                    />
                )}
            </Box>
        </>
    )
}

export default ViewDataGridUser