/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography,
    Checkbox,
    Link
} from "@material-ui/core"
import { useContext, useEffect, useState } from 'react'
import ContextUserGroup from '../../context'
import useStyles from '../../style'
import * as Yup from 'yup'
import { useFormik } from "formik"
import { Close, Search, Visibility, VisibilityOff, ArrowDropDown, ArrowDropUp } from "@material-ui/icons"
import ModalSearchInstitutions, { CbInstituteProps } from "../../../../components/ModalSearchInstitutions"
import { MultiSelect } from "react-multi-select-component"
import { getActiveGroupUser, getSector, postUser, updateUserById, getCanEditInstitute } from "../../services"
import { IInstitutionUser, IPhysicalPerson, IInternalUser, ListGroupUser, MultiSelectBind } from '../../interfaces'
import { getUsersById } from '../../services'
import InputMask from "react-input-mask"
import { Context } from "../../../../providers/AuthProvider/contexts/AuthContext";
import { getInstitute } from '../../../../components/ModalSearchInstitutions/services/';

import './style.css'

type CbInstituteProps = {
    tasyCode: number,
    cnes: string,
    corporateName: string,
    cnpj: string,
    cityState: string,
    webAccess: string
}

type Pageable = {
    pageNumber: number,
    pageSize: number,
}

type Response = {
    data: {
        content: CbInstituteProps[],
        pageable: Pageable,
        totalPages: number,
        totalRecords: number,
    }
}

const CreateUpdateUsers = () => {
    const initialState = {
        tasyCode: 0,
        cnes: '',
        corporateName: '',
        cnpj: '',
        cityState: '',
        webAccess: ''
    }

    const { mode, userId, setMode, isReadOnly } = useContext(ContextUserGroup)
    const { user } = useContext(Context)
    const classes = useStyles();
    const [visibilityPassword, setVisibilityPassword] = useState(false)
    const [resetInstitutesSelection, setResetInstitutesSelection] = useState(false)
    const [activeGroupUser, setActiveGroupUser] = useState<MultiSelectBind[]>([]);
    const [selectedGroupUser, setSelectedGroupUser] = useState<ListGroupUser[]>([]);
    const [open, setOpen] = useState(false);
    const [selectedInstitutes, setSelectedInstitutes] = useState<CbInstituteProps[]>([])
    const [optSector, setOptSector] = useState<{ name: string, id: number }[]>([]);
    const [canEdit, setCanEdit] = useState(false)
    const [changed, setChanged] = useState(false)
    const [typeTermsAcceptCheck, setTypeTermsAcceptCheck] = useState(true)
    const titlePage = mode === 'edit' ? `Editar Usuário ` : 'Adicionar'
    const titlePageRedOnly = 'Visualizar Usuário'

    const handleChangeGroupUser = (groupList: ListGroupUser[]) => {
        setSelectedGroupUser(groupList) //Mostra o formato compatível com o componente antes de alterar o formato para ser enviado ao backend
        const formListGroupUser = alterGroupListFormat(groupList)
        formik.setFieldValue('groups', formListGroupUser)
        setChanged(true)
    }

    //Alterar o formato do objeto groups para enviar p backend
    const alterGroupListFormat = (groupListToFormat: ListGroupUser[]) => {
        return groupListToFormat.map(group => group.value) //array com os indices de cada grupo
    }

    //Ao enviar os dados chamar o metodo para converter o objeto de grupos de usuários
    function handleTermsAccept(type: string) {
        let check = true

        if (type === '2') check = false

        setTypeTermsAcceptCheck(check)

        formik.setFieldValue('termsAccept', check)
    }

    useEffect(() => {
        if (selectedInstitutes && typeof selectedInstitutes === 'object' && selectedInstitutes.length > 0) {
            const instituteIds = selectedInstitutes.map(institute => institute.tasyCode);
            formik.setFieldValue('instituteIds', instituteIds);
        }
    }, [selectedInstitutes]) // eslint-disable-line react-hooks/exhaustive-deps

    const [isInstituteUserTypeAdd, setIsInstituteUserTypeAdd] = useState(false)

    useEffect(() => {
        getActiveGroupUser().then(resp => {
            let activeGroup: MultiSelectBind[] = [];
            resp.data.forEach((e: any) => {
                activeGroup.push({ label: e.name, value: e.id })
            })
            setActiveGroupUser(activeGroup)
        })

        getCanEditInstitute().then(resp => {
            setCanEdit(resp.data)
        })

        getSector().then(resp => {
            setOptSector(resp.data)
        })

        setTypeTermsAcceptCheck(true)

        //Aplicar regra para tratar se o usuário é do tipo Instituição (userType = 2)
        //Flag para tratar essa condição e bloquear os campos de tipo de usuário e consulta de instituição.
        if (mode === 'add' && user?.typeUser === 2) {
            setIsInstituteUserTypeAdd(true);
            formik.setFieldValue('typeUser', 2);

            getInstitute({
                Cnes: user.instituteCnes,
                PageNumber: 1,
                PageSize: 5
            }).then((response: Response) => {

                setSelectedInstitutes(response.data.content);
            })
                .catch(((err) => {
                }))

            handleTermsAccept('2');
        }
    }, [])

    const onClose = () => {
        setOpen(false);
    }

    const handleChangeInstituteIcon = (event: any) => {
        event.stopPropagation();
        setSelectedInstitutes([]);
        setResetInstitutesSelection(true);
        formik.setFieldValue('instituteIds', []);
    }

    const formik = useFormik({        
        initialValues: {
            id: 0,
            name: '',
            email: '',
            password: '',
            instituteIds: [],
            instituteCnes: '',
            sectorId: 0,
            active: true,
            typeUser: 0,
            badgeNumber: '',
            dddCellPhone: '',
            numberCellPhone: '',
            dnv: '',
            medicalRecordNumber: '',
            tasyCode: null,
            groups: [],
            termsAccept: typeTermsAcceptCheck,
        },
        validationSchema: Yup.object({
            typeUser: Yup.number().required('Obrigatório.'),
            name: Yup.string().required('Obrigatório.').max(100),
            numberCellPhone: Yup.string().nullable().when('typeUser', {
                is: 1,
                then: Yup.string().required('Obrigatório.')
            }),
            dddCellPhone: Yup.string().nullable().when('numberCellPhone', {
                is: (val: string) => !!val,
                then: Yup.string().required('Obrigatório.')
            }),
            email: Yup.string().email('E-mail no formato inválido.').required('Obrigatório.'),
            instituteIds: Yup.array().when('typeUser', {
                is: 2,
                then: Yup.array().min(1, 'Obrigatório.')
            }),
            sectorId: Yup.number().nullable().when('typeUser', {
                is: 2,
                then: Yup.number().notOneOf([0], 'Obrigatório.')
            }),
            badgeNumber: Yup.string().nullable().when('typeUser', {
                is: (val: number) => val === 0,
                then: Yup.string().required('Obrigatório.')
            }),
            dnv: Yup.string().nullable(true).when('typeUser', {
                is: 1,
                then: Yup.string().max(11, 'O DNV deve ter no máximo 11 caracteres.').required('Obrigatório.')
            }),
            medicalRecordNumber: Yup.string().nullable().when('typeUser', {
                is: 1,
                then: Yup.string().nullable().required('Obrigatório.')
            }),
            password: Yup.string().min(6, 'A senha deve ter no mínimo 6 caracteres.'),
            groups: Yup.array().when('typeUser', {
                is: (val: number) => val !== 1,
                then: Yup.array().min(1, "Pelo menos um grupo de usuário deve ser selecionado.")
            }),
            tasyCode: Yup.number().nullable().when('typeUser', {
                is: 1,
                then: Yup.number()
                    .typeError('Apenas números são permitidos.')
                    .nullable()
                    .required('Obrigatório.')
                    .min(3, 'Deve possuir no mínimo 3 caracteres.')
                    .lessThan(2147483648, 'Formato Inválido.')
            }),
            termsAccept: Yup.boolean().oneOf([true]),
        }),
    
        onSubmit: values => {
            // Transformar o email para minúsculas antes de enviar
            values.email = values.email.toLowerCase();
    
            let cellPhoneNotMask = ''
    
            if (values.numberCellPhone && values.numberCellPhone.length > 0) {
                cellPhoneNotMask = values.numberCellPhone.replace(/[^0-9]+/ig, "")
            }
    
            switch (values.typeUser) {
                case 0:
                    {
                        let internalUser: IInternalUser = {
                            name: values.name,
                            email: values.email,
                            password: values.password,
                            dddCellPhone: !!values.dddCellPhone ? values.dddCellPhone : undefined,
                            numberCellPhone: !!values.numberCellPhone ? cellPhoneNotMask : undefined,
                            typeUser: values.typeUser,
                            active: values.active,
                            badgeNumber: values.badgeNumber,
                            groups: changed ? values.groups : alterGroupListFormat(values.groups) /* .map((e:IUserGroups ) => e.id) */
                        }
                        if (mode === 'add') {
                            postUser(internalUser).then(() => (setMode('')))
                            break;
                        }
                        if (mode === 'edit' && userId) {
                            updateUserById(userId, internalUser).then(() => setMode(''))
                            break;
                        }
    
                    }
                    break;
                case 1:
                    {
                        let physicalUser: IPhysicalPerson = {
                            name: values.name,
                            email: values.email,
                            password: values.password,
                            dddCellPhone: values.dddCellPhone,
                            numberCellPhone: cellPhoneNotMask,
                            typeUser: values.typeUser,
                            active: values.active,
                            tasyCode: Number(values.tasyCode),
                            dnv: values.dnv,
                            medicalRecordNumber: values.medicalRecordNumber
                        }
    
                        if (mode === 'add') {
                            postUser(physicalUser).then(() => (setMode('')))
                            break;
                        }
                        if (mode === 'edit' && userId) {
                            updateUserById(userId, physicalUser).then(() => setMode(''))
                            break;
                        }
                    }
    
                    break;
                case 2:
    
                    if (mode === 'add') {
    
                        let instituteUser: IInstitutionUser = {
                            name: values.name,
                            email: values.email,
                            password: values.password,
                            dddCellPhone: !!values.dddCellPhone.length ? values.dddCellPhone : undefined,
                            numberCellPhone: !!values.numberCellPhone.length ? cellPhoneNotMask : undefined,
                            typeUser: values.typeUser,
                            active: values.active,
                            groups: changed ? values.groups : alterGroupListFormat(values.groups),
                            instituteIds: values.instituteIds,
                            badgeNumber: values.badgeNumber,
                            sectorId: values.sectorId,
                        }
    
                        postUser(instituteUser).then(() => (setMode('')))
                        break;
                    }
    
                    if (mode === 'edit' && userId) {
                        let instituteUser: IInstitutionUser = {
                            name: values.name,
                            email: values.email,
                            password: values.password,
                            dddCellPhone: !!values.dddCellPhone && values.dddCellPhone.length ? values.dddCellPhone : undefined,
                            numberCellPhone: !!values.numberCellPhone && values.numberCellPhone.length ? cellPhoneNotMask : undefined,
                            typeUser: values.typeUser,
                            active: values.active,
                            groups: changed ? values.groups : alterGroupListFormat(values.groups),
                            instituteIds: values.instituteIds,
                            badgeNumber: values.badgeNumber,
                            sectorId: values.sectorId,
                        }
    
                        updateUserById(userId, instituteUser).then(() => setMode(''))
                        break;
                    }
                    break;
            }
        }
    })
    

    //Pegar Usuário para Editar
    useEffect(() => {
        async function getUser() {
            if (userId && mode === 'edit') {
                const user = await getUsersById(userId)
                setSelectedInstitutes(user.institutes)
                formik.setValues(user)
                setSelectedGroupUser(user.groups)
            }
        }
        getUser()
    }, [userId])

    const handlerSubmitForm = () => {
        Yup.object().validate(formik.values, { abortEarly: false })
        formik.submitForm()
    }

    const handleChangeTypeUser = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        formik.resetForm()
        setSelectedGroupUser([])
        setSelectedInstitutes([])
        formik.setFieldValue('typeUser', event.target.value)

        handleTermsAccept('1')

        if (event.target.value === 2)
            handleTermsAccept('2')
    }

    return (
        <>
            <ModalSearchInstitutions
                selectedInstitutes={(selectedInstitutes && typeof selectedInstitutes === 'object' && selectedInstitutes.length > 0) ? selectedInstitutes : []}
                open={open}
                onClose={onClose}
                onCallBack={(selectedInstitutes) => {
                    setResetInstitutesSelection(false);
                    setSelectedInstitutes(selectedInstitutes);
                    setOpen(false);
                }}
                resetSelection={resetInstitutesSelection}
            />
            <Grid container>
                <Grid item >
                    <Typography variant="h5" style={{ fontWeight: 'bolder' }}
                        color="textPrimary">{`${isReadOnly ? titlePageRedOnly : titlePage}`}</Typography>
                </Grid>
            </Grid>
            <form onSubmit={formik.handleSubmit}>
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item xs={7} sm={5}>
                        <Box mt={1} mb={5}>
                            <Typography className={classes.inputLabel}>Perfil Usuário</Typography>
                            <FormControl variant="outlined" size="small" margin="none" fullWidth>
                                <Select
                                    disabled={mode === 'edit' ? true : isInstituteUserTypeAdd ?? true}
                                    placeholder="Perfil"
                                    labelId="perfil-lb"
                                    name='typeUser'
                                    id="slc-perfil"
                                    onChange={handleChangeTypeUser}
                                    value={formik.values.typeUser}
                                >
                                    <MenuItem value={0}>Usuário Interno</MenuItem>
                                    <MenuItem value={2}>Usuário Instituição</MenuItem>
                                    <MenuItem value={1}>Cliente Particular</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={5} sm={1}>
                        <Box>
                            <FormControlLabel value="top" control={
                                <Switch disabled={isReadOnly || isInstituteUserTypeAdd} checked={formik.values.active} onClick={formik.handleChange} name="active" color="primary" />
                            } label={formik.values.active ? 'Ativo' : 'Inativo'} labelPlacement="end" />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" alignItems="flex-start" spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Typography className={classes.inputLabel}>Nome Completo </Typography>
                        <TextField
                            disabled={isReadOnly}
                            size="small"
                            variant="outlined"
                            margin="none"
                            required
                            fullWidth
                            id="name"
                            placeholder="Nome Completo"
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                            {...formik.getFieldProps('name')}
                        />
                    </Grid>
                </Grid>
                <Box mb={1} />
                <Grid container justifyContent="center" alignItems="flex-start" spacing={1}>
                    <Grid item xs={12} sm={mode !== 'edit' ? 3 : 6}>
                        <Typography className={classes.inputLabel}>E-mail</Typography>
                        <TextField
                            disabled={isReadOnly}
                            size="small"
                            variant="outlined"
                            margin="none"
                            required
                            fullWidth
                            id="email"
                            placeholder="E-mail"
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            {...formik.getFieldProps('email')}
                            onBlur={(e) => {
                                formik.handleBlur(e);
                            }}
                            onKeyUp={(e) => {
                              const target = e.target as HTMLInputElement;
                              formik.setFieldValue('email', target.value.toLowerCase());
                            }}
                            onChange={(e) => {
                              const target = e.target as HTMLInputElement;
                              formik.setFieldValue('email', target.value.toLowerCase());
                            }}
                        />

                    </Grid>
                    {mode !== 'edit' && (
                        <Grid item xs={12} sm={3}>
                            <>
                                <Typography className={classes.inputLabel}>Senha</Typography>
                                <TextField
                                    disabled={isReadOnly}
                                    size="small"
                                    variant="outlined"
                                    margin="none"
                                    required
                                    fullWidth
                                    id="password"
                                    placeholder="Senha"
                                    type={visibilityPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    aria-label='toggle password visibility'
                                                    onClick={() => setVisibilityPassword(!visibilityPassword)}
                                                >
                                                    {visibilityPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                    {...formik.getFieldProps('password')}
                                />
                            </>
                        </Grid>
                    )}
                </Grid>
                <Box mb={1} />
                <Grid container justifyContent="center" alignItems="flex-start" spacing={1}>
                    <Grid item xs={4} sm={1}>
                        <Typography className={classes.inputLabel}>DDD</Typography>
                        <InputMask
                            disabled={isReadOnly}
                            mask="99"
                            value={formik.values.dddCellPhone}
                            onChange={(e) => formik.setFieldValue('dddCellPhone', e.target.value)}
                        >{() => <TextField
                            disabled={isReadOnly}
                            size="small"
                            variant="outlined"
                            margin="none"
                            fullWidth
                            id="dddCellPhone"
                            placeholder="DDD"
                            error={formik.touched.dddCellPhone && Boolean(formik.errors.dddCellPhone)}
                            helperText={formik.touched.dddCellPhone && formik.errors.dddCellPhone}
                        />}
                        </InputMask>
                    </Grid>
                    <Grid item xs={8} sm={2}>
                        <Typography className={classes.inputLabel}>Celular</Typography>
                        <InputMask
                            disabled={isReadOnly}
                            mask="9 9999-9999"
                            maskPlaceholder="_"
                            value={formik.values.numberCellPhone}
                            onChange={(e) => formik.setFieldValue('numberCellPhone', e.target.value)}
                        >
                            {() => <TextField
                                disabled={isReadOnly}
                                size="small"
                                variant="outlined"
                                margin="none"
                                fullWidth
                                id="numberCellPhone"
                                placeholder="Celular"
                                error={formik.touched.numberCellPhone && Boolean(formik.errors.numberCellPhone)}
                                helperText={formik.touched.numberCellPhone && formik.errors.numberCellPhone}
                            />}
                        </InputMask>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                    </Grid>
                </Grid>

                <Box mt={2} mb={4} />

                {formik.values.typeUser !== 1 && (<>
                    <Grid container justifyContent="center" alignItems="flex-start" spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.inputLabel}>Grupo Usuários</Typography>
                            <FormControl fullWidth >
                                <MultiSelect
                                    disabled={isReadOnly}
                                    overrideStrings={{
                                        "selectSomeItems": "Selecione",
                                        "allItemsAreSelected": "Todos os grupos selecionados.",
                                        "selectAll": "Todos"
                                    }}
                                    ArrowRenderer={(e) => e.expanded ? <ArrowDropUp style={{ color: '#5f5f5f' }} /> : <ArrowDropDown style={{ color: '#5f5f5f' }} />}
                                    disableSearch
                                    options={activeGroupUser}
                                    value={selectedGroupUser}
                                    onChange={handleChangeGroupUser}
                                    labelledBy="Select"
                                    className="multi-select-item"
                                />
                                <FormHelperText error>{formik.touched.groups && formik.errors.groups}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>

                    {formik.values.typeUser === 2 && (
                        <Grid container justifyContent="center" alignItems="flex-start" spacing={1}>
                            <Grid item xs={12} sm={5}>
                                <Typography className={classes.inputLabel}>Instituições</Typography>
                                <TextField
                                    disabled={!canEdit && (mode === 'edit' || isInstituteUserTypeAdd)}
                                    fullWidth
                                    inputProps={
                                        canEdit || 
                                        (mode !== 'edit' && !isInstituteUserTypeAdd)
                                            ? { style: { cursor: 'pointer' } }
                                            : { style: { cursor: '' } }
                                    }
                                    onClick={() => canEdit || (formik.values.typeUser === 2 && mode === 'add' && !isInstituteUserTypeAdd) ? setOpen(true) : null}
                                    size="small"
                                    variant="outlined"
                                    margin="none"
                                    id="search-user-institute"
                                    placeholder="Clique para selecionar a instituição"
                                    error={formik.touched.instituteIds && Boolean(formik.errors.instituteIds)}
                                    helperText={formik.touched.instituteIds && formik.errors.instituteIds}
                                    value={Array.isArray(selectedInstitutes) && selectedInstitutes.length > 0
                                        ? selectedInstitutes
                                            .map(institute => institute.corporateName ? institute.corporateName.trim() : '')
                                            .filter(name => name.length > 0)
                                            .join(', ')
                                        : ''}
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            (mode !== 'edit' && !isInstituteUserTypeAdd &&
                                                <InputAdornment position="end">
                                                    {selectedInstitutes.length > 0 ?
                                                        <Close style={formik.values.typeUser === 2 ? { cursor: 'pointer' } : { cursor: 'not-allowed' }} onClick={handleChangeInstituteIcon} />
                                                        : <Search style={formik.values.typeUser === 2 ? { cursor: 'pointer' } : { cursor: 'not-allowed' }} />}

                                                </InputAdornment>)
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={1}>
                                <Typography className={classes.inputLabel}>CNES</Typography>
                                <TextField
                                    disabled={true}
                                    size="small"
                                    variant="outlined"
                                    margin="none"
                                    required
                                    fullWidth
                                    id="cnesNumber"
                                    value={Array.isArray(selectedInstitutes) ? selectedInstitutes.filter(Boolean).map(institute => institute.cnes).join(', ') : ''}
                                />
                            </Grid>
                        </Grid>)}

                    <Box mb={1} />

                    <Grid container justifyContent="center" alignItems="flex-start" spacing={1}>
                        <Grid item xs={12} sm={3} >
                            <Typography className={classes.inputLabel}>Nº Crachá</Typography>
                            <TextField
                                disabled={formik.values.typeUser === 1 || isReadOnly}
                                inputProps={
                                    formik.values.typeUser === 1
                                        ? { style: { cursor: 'not-allowed' } }
                                        : {}
                                }
                                size="small"
                                variant="outlined"
                                margin="none"
                                required
                                fullWidth
                                id="badgeNumber"
                                placeholder="Nº de Crachá"
                                error={formik.touched.badgeNumber && Boolean(formik.errors.badgeNumber)}
                                helperText={formik.touched.badgeNumber && formik.errors.badgeNumber}
                                {...formik.getFieldProps('badgeNumber')}
                            />
                        </Grid>
                        {formik.values.typeUser === 0 &&
                            <Grid item xs={12} sm={3}>
                                <Typography className={classes.inputLabel}>Código TASY </Typography>
                                <TextField
                                    disabled={isReadOnly}
                                    size="small"
                                    variant="outlined"
                                    margin="none"
                                    required
                                    fullWidth
                                    id="tasyCode"
                                    placeholder="Cod TASY"
                                    error={formik.touched.tasyCode && Boolean(formik.errors.tasyCode)}
                                    helperText={formik.touched.tasyCode && formik.errors.tasyCode}
                                    {...formik.getFieldProps('tasyCode')}
                                />
                            </Grid>
                        }
                        {formik.values.typeUser === 2 && (
                            <Grid item xs={12} sm={3}>
                                <Typography className={classes.inputLabel}>Setor Usuário</Typography>
                                <FormControl variant="outlined" size="small" margin="none" fullWidth
                                    disabled={formik.values.typeUser !== 2}
                                >
                                    <Select
                                        disabled={isReadOnly}
                                        style={{ cursor: 'not-allowed' }}
                                        name='sectorId'
                                        id="sectorId"
                                        onChange={formik.handleChange}
                                        value={formik.values.sectorId}
                                        error={formik.touched.sectorId && Boolean(formik.errors.sectorId)}
                                    >
                                        <MenuItem value={0} disabled>
                                            Selecione um setor.
                                        </MenuItem>
                                        {optSector.map((e) => (
                                            <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText error>{formik.touched.sectorId && formik.errors.sectorId}</FormHelperText>
                                </FormControl>
                            </Grid>
                        )}
                    </Grid>
                    {formik.values.typeUser === 2 && mode === 'add' && (
                        <Grid container justifyContent="center" alignItems="flex-start" spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <Checkbox
                                    size="medium"
                                    {...formik.getFieldProps('termsAccept')}
                                    checked={formik.values.termsAccept}
                                    id="termsAccept"
                                    color='primary'
                                />
                                <label htmlFor="termsAccept" className={classes.label}>
                                    Li e concordo com os {` `}
                                    <Link
                                        href={process.env.REACT_APP_URL_TERMS}
                                        target="_blank"
                                        className={classes.textLink}
                                    >termos de uso</Link>.
                                </label>
                                {formik.touched.termsAccept && Boolean(formik.errors.termsAccept) && (
                                    <p
                                        className="MuiFormHelperText-root Mui-error"
                                        style={{ paddingLeft: '13px' }}
                                    >Você precisa aceitar os termos</p>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </>
                )}
                <Box mt={2} mb={4} />
                {formik.values.typeUser === 1 && (
                    <Grid container justifyContent="center" alignItems="flex-start" spacing={1}>
                        <Grid item xs={12} sm={3}>
                            <Typography className={classes.inputLabel}>DNV</Typography>
                            <TextField
                                disabled={formik.values.typeUser !== 1 || isReadOnly}
                                inputProps={
                                    formik.values.typeUser !== 1
                                        ? { style: { cursor: 'not-allowed' } }
                                        : {}
                                }
                                size="small"
                                variant="outlined"
                                margin="none"
                                required
                                fullWidth
                                id="dnv"
                                placeholder="DNV"
                                error={formik.touched.dnv && Boolean(formik.errors.dnv)}
                                helperText={formik.touched.dnv && formik.errors.dnv}
                                {...formik.getFieldProps('dnv')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography className={classes.inputLabel}>Número Prontuário</Typography>
                            <TextField
                                disabled={formik.values.typeUser !== 1 || isReadOnly}
                                inputProps={
                                    formik.values.typeUser !== 1
                                        ? { style: { cursor: 'not-allowed' } }
                                        : {}
                                }
                                size="small"
                                variant="outlined"
                                margin="none"
                                required
                                fullWidth
                                id="medicalRecordNumber"
                                placeholder="Nº Prontuário"
                                error={formik.touched.medicalRecordNumber && Boolean(formik.errors.medicalRecordNumber)}
                                helperText={formik.touched.medicalRecordNumber && formik.errors.medicalRecordNumber}
                                {...formik.getFieldProps('medicalRecordNumber')}
                            />
                        </Grid>
                        <Grid container justifyContent="center" alignItems="flex-start" spacing={1}>
                            <Grid item xs={12} sm={6} >
                                <Box mt={2}>
                                    <Typography className={classes.inputLabel}>Código TASY </Typography>
                                    <TextField
                                        disabled={isReadOnly}
                                        size="small"
                                        variant="outlined"
                                        margin="none"
                                        required
                                        fullWidth
                                        id="tasyCode"
                                        placeholder="Cod TASY"
                                        error={formik.touched.tasyCode && Boolean(formik.errors.tasyCode)}
                                        helperText={formik.touched.tasyCode && formik.errors.tasyCode}
                                        {...formik.getFieldProps('tasyCode')}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <Box mt={2} mb={4} />
                <Grid container justifyContent="center" alignItems="flex-start" spacing={2}>
                    {!isReadOnly &&
                        <Grid item xs={12} sm={2}>
                            <Button className={classes.button} onClick={handlerSubmitForm} fullWidth variant="contained" color="primary">Salvar</Button>
                        </Grid>
                    }
                    <Grid item xs={12} sm={2}>
                        <Button className={classes.button} onClick={() => setMode('')} fullWidth variant="contained" color="secondary">Voltar</Button>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export default CreateUpdateUsers;
