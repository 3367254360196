import {Dialog, DialogTitle, DialogContent, DialogActions} from '@material-ui/core'
import Button from '@material-ui/core/Button'

import useStyles from './style'
import { Grid, Typography } from '@material-ui/core'

const ModalConfirmation = (props: { open: any; title: any; message: any; onConfirm: any; onReject?: any; onClose: any }) => {
    const classes = useStyles()
    const {
        open,
        title,
        onConfirm,
        onReject,
        onClose,
        message
    } = props
    return (
        <Dialog
            open={Boolean(open)}
            onClose={onClose}
            fullWidth
            maxWidth='xs'
        >
            <DialogTitle className={classes.title}>
                {title}
            </DialogTitle>
            <DialogContent>
                <Typography variant="body2">{message}</Typography>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="center" spacing={1} >
                    <Grid item>
                    <Button
                            className={classes.button}
                            variant='contained'
                            color='primary'
                            onClick={() => {
                                onConfirm && onConfirm()
                                onClose()
                            }}>
                            Confirmar
                        </Button>
                    </Grid>
                    <Grid item>
                    <Button
                            className={classes.button}
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                onReject && onReject()
                                onClose()
                            }}>
                            Cancelar
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default ModalConfirmation