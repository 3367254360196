import React, { useContext } from "react";
import ReportsPF from './components/ReportsPf'
import ReportsPjIJC from './components/ReportsPjIJC'
import { Context } from "../../providers/AuthProvider/contexts/AuthContext";

const Reports = () => {

  const { user } = useContext(Context)

  return (
    <>
      {
        user?.typeUser === 1 ? <ReportsPF /> : <ReportsPjIJC />
      }
    </>
  )

}

export default Reports