import api from "../../../utils/api"

interface IPhysicalPerson {

  email: string,
  password: string,
  typeLoginPf: number,
  dnv: string,
  numberCellPhone: string,
  dddCellPhone: string,
  medicalRecordNumber: string
}

const API_ROUTE='/user/physical-person'

export const postPhysicalPerson = async(physicalPersonData : Partial<IPhysicalPerson>) => {
    return await api.post(API_ROUTE,physicalPersonData)
}