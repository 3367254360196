import React, { useState } from "react";
import ViewMaterialRequestConfiguration from './components/ViewMaterialRequestConfiguration'
import AddEditMaterialRequestConfiguration from './components/AddEditMaterialRequestConfiguration'

const MaterialRequestConfiguration = () => {

  const [mode, setMode] = useState('view')
  const [idMaterialConfiguration, setIdMaterialConfiguration] = useState<number | undefined>(undefined)

  return (
    <>
      {
        mode === 'view' ?
          <ViewMaterialRequestConfiguration setMode={setMode} idMaterialConfiguration={idMaterialConfiguration} setIdMaterialConfiguration={setIdMaterialConfiguration} />
          :
          <AddEditMaterialRequestConfiguration setMode={setMode} idMaterialConfiguration={idMaterialConfiguration} setIdMaterialConfiguration={setIdMaterialConfiguration} />
      }
    </>
  )

}

export default MaterialRequestConfiguration