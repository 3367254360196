import api from "../../../utils/api"
import {PatientFilter} from "../model/filters/patient-filter";
import {AxiosResponse} from "axios";
import {Patient} from "../model/patient";
import {Evolution} from "../model/evolution";
import {EvolutionFilters} from "../model/filters/evolution-filters";
import {EvolutionListResponse} from "../model/evolution-list-response";
import {Citizenship} from "../model/citizenship";
import {CreateEvolution} from "../model/create-evolution";
import {AnswerQuestion} from "../model/answer-question";
import {SettingsResponse} from "../model/settings-response";
import {SignatureResponse} from "../model/signature-response";
import {PatientPhoto} from "../model/patient-photo";
import { Attachment } from "../model/attachment";


export const getPatientByCpf = async (filter: PatientFilter): Promise<AxiosResponse<Patient>> => {
    return await api.get(`evolutions/patient/${filter.cpf}`);
}


export const createEvolution = async (createEvolutionArg: CreateEvolution): Promise<AxiosResponse<Evolution>> => {
    return await api.post(`evolutions`, createEvolutionArg);
}

export const updateEvolution = async (createEvolutionArg: CreateEvolution): Promise<AxiosResponse<Evolution>> => {
    return await api.put(`evolutions`, createEvolutionArg);
}

export const listEvolutions = async (params: EvolutionFilters): Promise<AxiosResponse<EvolutionListResponse>> => {
    return await api.get(`evolutions`, {params});
}

export const listCitizenship = async(): Promise<AxiosResponse<Citizenship[]>> => {
    return await api.get('fetch/citizenship');
}

export const removeSignature = async(evolutionId: string, typeEvaluationId: string): Promise<AxiosResponse<void>> => {
    return await api.delete(`evolutions/remove-signature/${evolutionId}/${typeEvaluationId}`);
}

export const getEvolutionQuestions = async (evolutionId: number): Promise<AxiosResponse<Evolution>> => {
    return await api.get(`evolutions/${evolutionId}`);
}

export const answerQuestion = async (evolutionId: number, body: AnswerQuestion): Promise<AxiosResponse<void>> => {
    return await api.put(`evolutions/${evolutionId}/answer/`, body);
}

export const getProgramsAndEvolutionTypes = async (): Promise<AxiosResponse<SettingsResponse>> => {
    return await api.get('evolutions/filter-settings');
}

export const updateEvolutionProgram = async (params: {id: number, programId: number}): Promise<AxiosResponse<void>> => {
    return await api.put('evolutions/program', {}, {params});
}

export const updateTypeEvaluation = async (params: {id: number, typeEvaluationId: number}): Promise<AxiosResponse<void>> => {
    return await api.put('evolutions/type-evaluation', {}, {params});
}

export const updateEvaluationStage = async (params: { id: number, stage: number }): Promise<AxiosResponse<void>> => {
    return await api.put(`evolutions/${params.id}/save-stage/${params.stage}`, {});
}

export const getSignature = async (params: { evaluationId: number, type: number }): Promise<AxiosResponse<SignatureResponse>> => {
    return await api.get('evolutions/signatures', {params});
}
export const saveSignature = async (form: FormData): Promise<AxiosResponse<void>> => {
    return await api.post('evolutions/save-signatures', form);
}

export const getPatientPhoto = async (params: { personId: number }): Promise<AxiosResponse<PatientPhoto>> => {
    return await api.get('evolutions/photo', {params});
}

export const setPatientPhoto = async (form: FormData): Promise<AxiosResponse<void>> => {
    return await api.post('evolutions/photo', form);
}

export const getPdf = async (evolutionId: number): Promise<AxiosResponse<string>> => {
    return await api.get(`evolutions/${evolutionId}/report`);
}

export const releaseEvolution = async (evolutionId: number): Promise<AxiosResponse<void>> => {
    return await api.put(`evolutions/${evolutionId}/release`);
}

export const uploadAttachment = async (form: FormData): Promise<AxiosResponse<void>> => {
    return await api.post('evolutions/attachment', form);
}

export const getAttachments = async (evolutionId: number): Promise<AxiosResponse<Attachment[]>> => {
    return await api.get(`evolutions/${evolutionId}/attachment`);
}

export const downloadAttachment = async (attachmentId: number): Promise<AxiosResponse<Blob>> => {
    return await api.get(`evolutions/attachment/${attachmentId}`, {
        responseType: 'blob'
    });
}

export const deleteAttachment = async (attachmentId: number): Promise<AxiosResponse<void>> => {
    return await api.delete(`evolutions/attachment/${attachmentId}`);
}
