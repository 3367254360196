import { IQuestions } from './interfaces'

// QUESTION ID 1
export const unityType: Array<IQuestions> = [
  {
    id: 1,
    name: 'Hospital/Maternidade',
    checked: false,
    hasObservation: false,
  },
  {
    id: 2,
    name: 'UBS/AMA',
    checked: false,
    hasObservation: false,
  },
  {
    id: 3,
    name: 'Clínica/Laboratório',
    checked: false,
    hasObservation: false,
  },
  {
    id: 4,
    name: 'Outros',
    checked: false,
    hasObservation: true,
    observation: ''
  },
]

// QUESTION ID 2
export const serviceForm: Array<IQuestions> = [
  {
    id: 5,
    name: 'Atendimento SUS dentro do município de São Paulo',
    checked: false,
    hasObservation: false,
  },
  {
    id: 6,
    name: 'Atendimento SUS em outros municípios do estado de São Paulo',
    checked: false,
    hasObservation: false,
  },
  {
    id: 7,
    name: 'Atendimento Particular/Convênio',
    checked: false,
    hasObservation: false,
  },
  {
    id: 8,
    name: 'Outros',
    checked: false,
    hasObservation: true,
    observation: ''
  },
]

// QUESTION ID 3
export const trainingCategory: Array<IQuestions> = [
  {
    id: 9,
    name: 'Atualização/reciclagem profissional',
    checked: false,
    hasObservation: false,
  },
  {
    id: 10,
    name: 'Treinamento para novos profissionais',
    checked: false,
    hasObservation: false,
  },
  {
    id: 11,
    name: 'Treinamento para novos clientes',
    checked: false,
    hasObservation: false,
  },
  {
    id: 12,
    name: 'Outros',
    checked: false,
    hasObservation: true,
    observation: ''
  },
]

// QUESTION ID 4
export const trainingPeriod: Array<IQuestions> = [
  {
    id: 13,
    name: 'Manhã (09h às 11h)',
    checked: false,
    hasObservation: false,
  },
  {
    id: 14,
    name: 'Tarde (14h às 16h)',
    checked: false,
    hasObservation: false,
  },
  {
    id: 15,
    name: 'Noite (18h às 20h)',
    checked: false,
    hasObservation: false,
  },
  {
    id: 16,
    name: 'Outros',
    checked: false,
    hasObservation: true,
    observation: ''
  },
]

// QUESTION ID 5
export const professionalToBeTraineds: Array<IQuestions> = [
  {
    id: 17,
    name: 'Médicos',
    checked: false,
    hasObservation: false,
  },
  {
    id: 18,
    name: 'Enfermeiros',
    checked: false,
    hasObservation: false,
  },
  {
    id: 19,
    name: 'Técnicos de Enfermagem',
    checked: false,
    hasObservation: false,
  },
  {
    id: 20,
    name: 'Auxiliares de Enfermagem',
    checked: false,
    hasObservation: false,
  },
  {
    id: 21,
    name: 'Administrativos',
    checked: false,
    hasObservation: false,
  },
  {
    id: 22,
    name: 'Transportadores (motoboys, motoristas, mensageiros e etc.)',
    checked: false,
    hasObservation: false,
  },
  {
    id: 23,
    name: 'Outros',
    checked: false,
    hasObservation: true,
    observation: ''
  },
]