import api from '../../../utils/api'
import {
  IMaterialContent,
  IMaterialsResponse,
  IGetMaterialRequest,
  IMaterialTypes,
  IMaterialRequestStatus,
  IMaterialRequestLog,
  IMaterialsExportExcel
} from '../interfaces'

import {dateTimeStringToDateTimeFormat} from '../../../utils/convertDate'

export const getMaterialRequest = async ({
    pageNumber,
    pageSize,
    searchInstituteCnes,
    searchStatus,
    searchTypeMaterial ,
    searchByStartDate,
    searchByEndDate

    }: IGetMaterialRequest) : Promise<IMaterialsResponse> => {

    let queryParam = ``

    if(searchInstituteCnes){
       queryParam = queryParam + `&InstituteCnes=${searchInstituteCnes}`
    }


    if(searchStatus !== null){
            queryParam = queryParam + `&Status=${searchStatus}`

      }

     if(searchTypeMaterial !== null){

          queryParam = queryParam + `&MaterialRequestTypeId=${searchTypeMaterial}`

    }

     if (searchByStartDate){
       const dateTime = dateTimeStringToDateTimeFormat(searchByStartDate)
        queryParam += `&RequestDateBegin=${dateTime}`
    }

    if (searchByEndDate){
      const dateTime = dateTimeStringToDateTimeFormat(searchByEndDate)
       queryParam += `&RequestDateEnd=${dateTime}`
   }

    if(pageNumber){
       queryParam += `&PageNumber=${pageNumber}`
    }

    if(pageSize){
       queryParam += `&PageSize=${pageSize}`
    }

    const materialData  = await api.get(`material-request?${queryParam}`).then(response => response.data)
    return materialData;
 }


export const getMaterials = async(searchByStartDate: any,searchByEndDate: any) :Promise<IMaterialsResponse> => {
   const dateTimeStart = searchByStartDate
   const dateTimeEnd = searchByEndDate

   const queryParam = `&RequestDateBegin=${dateTimeStart}&RequestDateEnd=${dateTimeEnd}`

   return await api.get(`material-request?${queryParam}`).then(response => response.data)
}

export const getMaterialsById = async(id : string) : Promise<IMaterialContent> => {
   return await api.get(`material-request/${id}`).then(resp => resp.data)
}


export const getMaterialsTypes = async() :Promise<IMaterialTypes[]> => {
   return await api.get('material-request-type').then(resp => resp.data)
}

export const changeMaterialRequestStatus = async(data : IMaterialRequestStatus , id : number) : Promise<void> => {
    await api.put(`material-request/change-status/${id}` , data )
}

export const getMaterialStatusLog = async(id :string) : Promise<IMaterialRequestLog[]> => {
   return await api.get(`material-request/${id}/log`).then(resp => resp.data)
}

export const getMaterialsExportExcel = (data: IMaterialsExportExcel) => {
  return api.get('material-request/dashboard/excel', {
    params: data,
    responseType: 'blob',
  })
}
