import toast from 'react-hot-toast';

const Snackbar = ({ message, type }: { message: string, type: string }) => {

    if (type === 'success') {
        toast.success(message,{duration:5000})
    }

    if (type === 'error') {
        toast.error(message,{duration:4000})

        //TO-DO - Se for costumizar a mensagem utilizar o padrão abaixo.
        // toast.error(message,
        //     {
        //         style: {
        //             background: '#EBAABB',                   
        //             padding: '10px',                   
        //         },
        //     })
    }

   
}

export default Snackbar