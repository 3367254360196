import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    signPad: {
        display: 'flex'
    },
    singPadRightButtons: {
        display: 'flex',
        justifyContent: "flex-end",
        width: '100%'
    }
}))
