import { useContext } from "react";
import { Context } from "../providers/AuthProvider/contexts/AuthContext";

/**
     * Verifica se o usuário logado possui a permissão do recurso.
     * @param permission Permissão do recurso.
     * @returns Boolean - true se o usuário possui a permissão ou false se não possuir.
     * 
     * @observação Deve ser utilizada em todas as ações nas telas de CRUD do sistema.
*/
const ShowHasPermission = (permission: string) => {

    const { user } = useContext(Context);
    const hasPermission = user?.permissions?.includes(permission)

    if (hasPermission) {
        return true
    }

    return false
}

export default ShowHasPermission