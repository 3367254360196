export const formatQuestion = async (question: any): Promise<any> => {
  let result: Array<{}> = []
  question.map((type: any) => {
    if (type.checked) {
      result.push({
        id: type.id,
        observation: type.observation
      })
    }
    return false;
  })

  return result
}

export const mountQuestions = async (unityType: any, serviceForm: any, trainingCategory: any, trainingPeriod: any, professionalToBeTraineds: any): Promise<any> => {
  const result = [
    {
      id: 1,
      answers: unityType
    },
    {
      id: 2,
      answers: [serviceForm]
    },
    {
      id: 3,
      answers: trainingCategory
    },
    {
      id: 4,
      answers: trainingPeriod
    },
    {
      id: 5,
      answers: professionalToBeTraineds
    }
  ]

  return result
}