import useStyles from "./style";
import {useMediaQuery} from "react-responsive";
import {
    Avatar, Badge,
    Box,
    Button,
    Dialog,
    DialogContent,
    Grid,
    IconButton, MenuItem, Select, SvgIcon,
    TextField, Tooltip,
    Typography
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import {useFormik} from "formik";
import React, {ReactNode, useEffect, useState} from "react";
import ReactInputMask from "react-input-mask";
import * as service from "../../../service/evolution-service";
import {Patient} from "../../../model/patient";
import {Autocomplete} from "@material-ui/lab";
import {Citizenship} from "../../../model/citizenship";
import {Evolution} from "../../../model/evolution";
import {dateStringToDate} from "../../../../../utils/convertDate";
import Snackbar from "../../../../../components/Snackbar";
import {camelCase} from "lodash";
import history from "../../../../../routes/history";

interface EvolutionFilterModalProps {
    patient?: Patient;
    evolutionId?: number;
    photoTasyCode?: number;
    isEditing: boolean;
    open?: boolean;
    handleClose: () => void;
    disableForm?: boolean;
}

type Gender = {
    label: string;
    value: string;
};

const CreateEditEvolutionModal = (args: EvolutionFilterModalProps) => {
    const styles = useStyles();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 598px)' });
    const genderOptions: Gender[] = [
        { label: "Masculino", value: "M" },
        { label: "Feminino", value: "F" },
        { label: "Indefinido", value: "I" },
    ];

    const [userPicture, setUserPicture] = useState('');
    const [citizenship, setCitizenship] = useState<Citizenship[]>([]);

    const [selectedCitizenship, setSelectedCitizenship] = useState<Citizenship | null>({description: "", id: ""});
    const [selectedGender, setSelectedGender] = useState<Gender | null>({ label: "", value: ""});
    const [selectedBorn, setSelectedBorn] = useState<string | null>(null);

    const form = useFormik({
        initialValues: {...args.patient},
        onSubmit: async (_) => {
            try {
                if (args.isEditing) {
                    try {
                        await updateEvolution();
                        updateOriginalPatient();
                        if (args.patient!.picture !== userPicture)
                        {
                            await updatePatientPhoto(args.photoTasyCode!);
                        }

                        args?.handleClose();
                        Snackbar({ message: 'Informações do paciente salvas', type: 'success' });
                    } catch (_) {
                        Snackbar({ message: 'Falha ao editar as informações do paciente', type: 'error' });
                    }
                } else {
                    const response = await createEvolution();
                    setPatientPhoto(response);
                }
            } catch (e) {
                handlerFormErrors(e);
            }
        },
    });

    async function getPatientData(docNumber: string): Promise<void> {
        if (!args.disableForm) {
            let patient: Patient | undefined = undefined;
            let picture = null
            try {
                patient = await service.findPatient(docNumber, true);
                picture = await service.getPatientPhoto(patient?.tasyCode);
            } catch (e) {
                console.log (e);
            }

            if (picture) {
                setUserPicture(`data:image/png;base64,${picture.patientPhoto}`);
            }

            if (patient) {
                setFormValues(citizenship, patient);
            }
        }
    }

    async function createEvolution(): Promise<Evolution> {
        const { activity, company } = form.values;
        const patient: Patient = form.values as Patient;
        patient.citizenship = Number(patient.citizenship) > 0 ? Number(patient.citizenship) : null;
        patient.gender = selectedGender ? selectedGender?.value : '';
        return await service.createEvolution({patient, activity, company})
    }

    async function updateEvolution(): Promise<Evolution> {
        const { activity, company } = form.values;
        const patient: Patient = form.values as Patient;
        patient.evolutionId = args.evolutionId!;
        patient.gender = selectedGender?.value ?? '';
        patient.citizenship = Number(selectedCitizenship?.id) ?? '';
        patient.picture = '';
        return await service.updateEvolution({patient, activity, company})
    }

    function updateOriginalPatient() {
        const patient: Patient = form.values as Patient;
        args.patient!.name = patient.name;
        args.patient!.company = patient.company;
        args.patient!.activity = patient.activity;
        args.patient!.email = patient.email;
    }

    function handlerFormErrors(errors: any) {
        const err = errors?.response?.data?.errors;
        const patientErrors: any = {};
        for (const key in err) {
            if (Object.prototype.hasOwnProperty.call(err, key)) {
                if (key.startsWith('Patient.')) {
                    const camelCaseKey = camelCase(key.replace('Patient.', ''));
                    patientErrors[camelCaseKey] = err[key];
                }
            }
        }
        form.setErrors(patientErrors)
    }


    async function updatePatientPhoto(tasyCode: number) {
        try {
            await service.setPatientPhoto(tasyCode, userPicture);
        } catch (_) {
        }
    }

    function setPatientPhoto(evolution: Evolution): void {
        service.setPatientPhoto(evolution.patient?.tasyCode, userPicture)
            .then(res => {
                console.log(res);
            }).catch(err => {
            console.log(err);
        }).finally(() => {
            Snackbar({message: 'Cadastro realizado com sucesso', type: 'success'});
            onDestroy();
            history.push(`/formulario-evolucao/${evolution.patientEvolutionId}/1`)
        });
    }

    function onGenderChange(event: any, newValue: Gender | null) {
        form.setFieldValue('gender', newValue?.value);
        setSelectedGender(newValue as Gender | null);

        if (args.patient)
            args.patient.gender = newValue?.value ?? '';
    }

    function onCitizenshipChange(event: React.ChangeEvent<{}>, value: any) {
        form.setFieldValue('citizenship', Number(value?.id));
        setSelectedCitizenship(value);

        if (args.patient)
            args.patient.citizenship = value?.id;
    }

    function shrinkField(fieldName: string): boolean {
        const {value} = form.getFieldProps(fieldName);

        return value;
    }

    function onDestroy(): void {
        form.resetForm();
        setUserPicture('');
        setSelectedCitizenship({description: "", id: ""});
        setSelectedGender({label: "", value: ""});
        setSelectedBorn(null);
        const takePictureInput = document.getElementById('takePicture');
        if (takePictureInput) {
            takePictureInput.removeEventListener('change', (e) => null);
        }
    }

    function setFormValues(_citizenship: Citizenship[], patient?: Patient) {
        if (patient) {
            setSelectedBorn(patient?.born ? dateStringToDate(patient?.born) : '');
            form.setValues({...patient}, true);

            const selectedCitizenship = _citizenship.find(x => Number(x.id) === Number(patient?.citizenship)) ?? null;
            setSelectedCitizenship(selectedCitizenship);
            setSelectedGender(genderOptions.find(x => x.value === patient.gender) ?? null);
        }
    }

    function takePicture(): void {
        if (args.disableForm) {
            return;
        }

        const convertBase64 = (file: any): Promise<string> => {
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file);

                fileReader.onload = () => {
                    resolve(fileReader.result as string);
                };

                fileReader.onerror = (error) => {
                    reject(error);
                };
            });
        };

        const uploadImage = async (event: Event) => {
            // @ts-ignore
            const file = event?.target?.files[0];
            const base64 = await convertBase64(file);
            setUserPicture(base64);
        };


        const elementRef = document.getElementById('takePicture');
        if (elementRef) {
            elementRef.click();
            elementRef.addEventListener('change', (e) => {
                uploadImage(e)
            })
        }
    }

    function handlerClose(event: {}, reason: "backdropClick" | "escapeKeyDown") {
        if (reason !== 'backdropClick') {
            args?.handleClose();
            form.resetForm();
            setSelectedCitizenship({description: "", id: ""});
            setSelectedGender({label: "", value: ""});
            setSelectedBorn(null)
        }
    }

    function onBornChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        form.setFieldValue('born', event.target.value);
        setSelectedBorn(event.target.value);

        if (args.patient)
            args.patient.born = event.target.value;
    }

    function onTelephoneChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        form.setFieldValue("telephone", e.target.value);

        if (args.patient)
            args.patient.telephone = e.target.value;
    }

    useEffect(() => {
        service.listCitizenship().then(res => {
            setCitizenship(res);
            setFormValues(res, args.patient);
            setUserPicture(`${args.patient?.picture}`);
        });
    }, [])

    function onBlurDocNumber(event: any) {
        if (!args.isEditing)
            getPatientData(event.target.value);
    }

    function getButtonLabel(): string {
        return args.isEditing ? 'Salvar' : 'Cadastrar';
    }

    return (
        <>
                <Dialog disableBackdropClick={true}  onClose={(event, reason) => handlerClose(event, reason)} aria-labelledby="customized-dialog-title" open={args.open!} maxWidth="xs" fullScreen={isTabletOrMobile ?? false}>
                    <MuiDialogTitle disableTypography>
                        <Typography variant="h6">Protocolo Integrado de Evolução</Typography>

                        <IconButton aria-label="close" className={styles.closeButton} onClick={() => handlerClose({}, 'escapeKeyDown')}>
                            <CloseIcon />
                        </IconButton>

                    </MuiDialogTitle>

                    <DialogContent dividers>
                        <form onSubmit={form.handleSubmit}>
                            <Grid container alignItems="flex-end">

                                <Grid item xs={12} md={12} className={`${styles.contentCenter} ${styles.avatarContainer}`} onClick={() => takePicture()}>
                                    <Box mb={1} mr={1} >
                                        <Badge badgeContent = {
                                            <SvgIcon>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                     stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path stroke-linecap="round" stroke-linejoin="round"
                                                          d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z"/>
                                                    <path stroke-linecap="round" stroke-linejoin="round"
                                                          d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z"/>
                                                </svg>
                                            </SvgIcon>
                                         }
                                               anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                                            <Avatar className={styles.avatar} src={userPicture}/>
                                        </Badge>
                                    </Box>
                                    <input id={'takePicture'} type="file" name="photo" capture="user" accept="image/*" style={{display: 'none'}}/>
                                </Grid>

                                {/*docNumber*/}
                                <Grid item xs={12} md={12}>
                                    <Box mb={1} mr={1}>
                                        <Tooltip title={form.errors?.docNumber as NonNullable<ReactNode>} open={Boolean(form.errors?.docNumber)}
                                                 disableHoverListener={!form.touched.docNumber || !Boolean(form.errors?.docNumber)}>
                                            <ReactInputMask
                                                mask="999.999.999-99"
                                                disabled={args?.disableForm || args?.isEditing}
                                                value={form.values?.docNumber}
                                                onBlur={onBlurDocNumber}
                                                onChange={(e) => form.setFieldValue("docNumber", e.target.value)}>
                                                {() => (
                                                    <TextField
                                                        size="small"
                                                        variant="outlined"
                                                        margin="none"
                                                        label="CPF"
                                                        fullWidth
                                                        id="docNumber"
                                                        placeholder="000.000.000-00"
                                                        error={
                                                            Boolean(form.errors?.docNumber)
                                                        }
                                                    />
                                                )}
                                            </ReactInputMask>
                                        </Tooltip>
                                    </Box>
                                </Grid>

                                {/*NOME PACIENTE*/}
                                <Grid item xs={12} md={6}>
                                    <Box mb={1} mr={1}>
                                        <Tooltip title={form.errors?.name as NonNullable<ReactNode>} open={Boolean(form.errors?.name)}
                                                 disableHoverListener={!form.touched.name || !Boolean(form.errors?.name)}>
                                            <TextField
                                                disabled={args.disableForm}
                                                fullWidth
                                                size="small"
                                                variant="outlined"
                                                InputLabelProps={{ shrink: shrinkField('name') }}
                                                margin="none"
                                                label="Nome Paciente"
                                                error={
                                                    Boolean(form.errors?.name)
                                                }
                                                {...form.getFieldProps("name")}
                                            />
                                        </Tooltip>
                                    </Box>
                                </Grid>

                                {/*NASCIMENTO*/}
                                <Grid item xs={6} md={6}>
                                    <Box mb={1} mr={1}>
                                        <Tooltip title={form.errors?.born as NonNullable<ReactNode>} open={Boolean(form.errors?.born)}
                                                 disableHoverListener={!form.touched.born || !Boolean(form.errors?.born)}>
                                            <TextField disabled={args.disableForm}
                                                       fullWidth size="small"
                                                       variant="outlined"
                                                       margin="none"
                                                       value={selectedBorn}
                                                       onChange={(event) => onBornChange(event)}
                                                       label="Data de Nascimento"
                                                       error={
                                                           Boolean(form.errors?.born)
                                                       }
                                                       InputLabelProps={{ shrink: true }}
                                                       type="date"

                                            />
                                        </Tooltip>
                                    </Box>
                                </Grid>

                                {/*TELEFONE*/}
                                <Grid item xs={6} md={6}>
                                    <Box mb={1} mr={1}>
                                        <Tooltip title={form.errors?.telephone as NonNullable<ReactNode>} open={Boolean(form.errors?.telephone)}
                                                 disableHoverListener={!form.touched.telephone || !Boolean(form.errors?.telephone)}>
                                            <ReactInputMask
                                                disabled={args?.disableForm}
                                                mask="(99) 99999-9999"
                                                readOnly={args.disableForm}
                                                value={form.values?.telephone}
                                                onChange={onTelephoneChange}>
                                                {() => (
                                                    <TextField
                                                        size="small"
                                                        variant="outlined"
                                                        margin="none"
                                                        fullWidth
                                                        id="telephone"
                                                        InputLabelProps={{ shrink: shrinkField('telephone') }}
                                                        label="Telefone"
                                                        error={
                                                            Boolean(form.errors?.telephone)
                                                        }
                                                    />
                                                )}
                                            </ReactInputMask>
                                        </Tooltip>
                                    </Box>
                                </Grid>

                                {/*Email*/}
                                <Grid item xs={6} md={6}>
                                    <Box mb={1} mr={1}>
                                        <Tooltip title={form.errors?.email as NonNullable<ReactNode>} open={Boolean(form.errors?.email)}
                                                 disableHoverListener={!form.touched.email || !Boolean(form.errors?.email)}>
                                            <TextField disabled={args.disableForm}
                                                       fullWidth size="small"
                                                       error={
                                                           Boolean(form.errors?.email)
                                                       }
                                                       InputLabelProps={{ shrink: shrinkField('email') }}
                                                       variant="outlined"
                                                       label="Email"
                                                       type="email"{...form.getFieldProps("email")}/>
                                        </Tooltip>
                                    </Box>
                                </Grid>

                                {/*SEXO*/}
                                <Grid item xs={6} md={6}>
                                    <Box mb={1} mr={1}>
                                        <Tooltip title={form.errors?.gender as NonNullable<ReactNode>} open={Boolean(form.errors?.gender)}
                                                 disableHoverListener={!form.touched.gender || !Boolean(form.errors?.gender)}>
                                            <Autocomplete
                                                disabled={args.disableForm}
                                                fullWidth
                                                size="small"
                                                onChange={onGenderChange}
                                                options={genderOptions}
                                                value={selectedGender}
                                                getOptionLabel={(o) => o ? o.label : ''}
                                                getOptionSelected={(option, value: Gender | null) => option.label === value?.label}
                                                renderInput={(params) => (
                                                    <Tooltip title={form.errors?.gender as NonNullable<ReactNode>} open={Boolean(form.errors?.gender)}
                                                             disableHoverListener={!form.touched.gender || !Boolean(form.errors?.gender)}>
                                                        <TextField {...params} label="Sexo" variant="outlined" error={Boolean(form.errors?.gender)} />
                                                    </Tooltip>
                                                )}
                                            />
                                        </Tooltip>
                                    </Box>
                                </Grid>

                                {/*NACIONALIDADE*/}
                                <Grid item xs={12} md={6}>
                                    <Box mb={1} mr={1}>
                                        <Autocomplete
                                            disabled={args?.disableForm}
                                            options={citizenship}
                                            getOptionLabel={(option) => option.description}
                                            value={selectedCitizenship}
                                            onChange={onCitizenshipChange}
                                            getOptionSelected={((option, value: Citizenship | null) => option.id === value?.id)}
                                            renderInput={(params) => (
                                                <Tooltip title={form.errors?.citizenship as NonNullable<ReactNode>} open={Boolean(form.errors?.citizenship)}
                                                         disableHoverListener={!form.touched.citizenship || !Boolean(form.errors?.citizenship)}>
                                                    <TextField {...params} label="Nacionalidade" variant="outlined" error={Boolean(form.errors?.citizenship)} />
                                                </Tooltip>

                                            )}
                                        />
                                    </Box>
                                </Grid>

                                {/*NOME DA MAE*/}
                                <Grid item xs={12} md={6}>
                                    <Box mb={1} mr={1}>
                                        <Tooltip title={form.errors?.motherName as NonNullable<ReactNode>} open={Boolean(form.errors?.motherName)}
                                                 disableHoverListener={!form.touched.motherName || !Boolean(form.errors?.motherName)}>
                                            <TextField disabled={args.disableForm}
                                                       fullWidth
                                                       size="small"
                                                       variant="outlined"
                                                       InputLabelProps={{ shrink: shrinkField('motherName') }}
                                                       error={
                                                           Boolean(form.errors?.motherName)
                                                       }
                                                       label="Nome da mãe"
                                                       margin="none"{...form.getFieldProps("motherName")}/>
                                        </Tooltip>
                                    </Box>
                                </Grid>

                                {/*Atividade*/}
                                <Grid item xs={12} md={6}>
                                    <Box mb={1} mr={1}>
                                        <Tooltip title={form.errors?.activity as NonNullable<ReactNode>} open={Boolean(form.errors?.activity)}
                                                 disableHoverListener={!form.touched.activity || !Boolean(form.errors?.activity)}>
                                            <TextField disabled={args.disableForm}
                                                       fullWidth
                                                       size="small"
                                                       variant="outlined"
                                                       InputLabelProps={{ shrink: shrinkField('activity') }}
                                                       error={
                                                           Boolean(form.errors?.activity)
                                                       }
                                                       label="Atividade"
                                                       margin="none"{...form.getFieldProps("activity")}/>
                                        </Tooltip>
                                    </Box>
                                </Grid>

                                {/*Empresa*/}
                                <Grid item xs={12} md={12}>
                                    <Box mb={1} mr={1}>
                                        <Tooltip title={form.errors?.company as NonNullable<ReactNode>} open={Boolean(form.errors?.company)}
                                                 disableHoverListener={!form.touched.company || !Boolean(form.errors?.company)}>
                                            <TextField disabled={args.disableForm}
                                                       fullWidth
                                                       size="small"
                                                       variant="outlined"
                                                       InputLabelProps={{ shrink: shrinkField('company') }}
                                                       error={
                                                           Boolean(form.errors?.company)
                                                       }
                                                       label="Empresa"
                                                       margin="none"{...form.getFieldProps("company")}/>
                                        </Tooltip>
                                    </Box>
                                </Grid>
                            </Grid>
                            {
                                !args.disableForm ?
                                    <Grid container alignItems="flex-end" className={styles.contentCenter}>
                                        <Grid item xs={12} md={6}>
                                            <Box mb={1} mr={1}>
                                                <Button
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary">
                                                    {getButtonLabel()}
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    : null
                            }
                        </form>
                    </DialogContent>
                </Dialog>
        </>
    )
}

export default CreateEditEvolutionModal
