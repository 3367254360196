import { useState } from "react"
import ViewDataGridUserGroup from "./components/ViewDataGridUserGroup"
import CreateUpdateUserGroup from "./components/CreateUpdateUserGroup"
import ContextUserGroup from './context'

const UserGroup = () => {

    const [mode, setMode] = useState('view')
    const [groupeId, setGroupId] = useState();
    
    return (

        <ContextUserGroup.Provider value={{mode, setMode, groupeId, setGroupId}}>
            {
                mode === 'view' 
                ? <ViewDataGridUserGroup />
                : <CreateUpdateUserGroup />
            }            
        </ ContextUserGroup.Provider>

    )
}

export default UserGroup
