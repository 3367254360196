import api from '../../../utils/api'

type InstituteProps = {
    PageNumber?: any,
    PageSize?: any,
    TasyCode?: number,
    Cnes?: string,
    CorporateName?: string
}

export const getInstitute = async ({ CorporateName, Cnes, PageNumber, PageSize, TasyCode }: InstituteProps) => {


    const INSTITUTE = '/institute'

    let queryParam = ``

    if (CorporateName) {
        queryParam = queryParam + `&CorporateName=${CorporateName}`
    }

    if (Cnes) {
        queryParam = queryParam + `&Cnes=${Cnes}`
    }

    if (TasyCode) {
        queryParam = queryParam + `&TasyCode=${TasyCode}`
    }

    if (PageNumber) {
        queryParam = queryParam + `&PageNumber=${PageNumber}`
    }

    if (PageSize) {
        queryParam = queryParam + `&PageSize=${PageSize}`
    }

    return await api.get(`${INSTITUTE}?${queryParam}`)

}
