import { Box, Button, Collapse, Divider, Drawer, Fab, Grid, List, ListItem, ListItemText } from '@material-ui/core'
import { useContext, useState } from 'react'
import { Menu, MenuItem } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import { useMediaQuery } from 'react-responsive'
import MenuIcon from '@material-ui/icons/Menu'
import { Typography } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import PersonIcon from '@material-ui/icons/Person'

import { Context } from '../../providers/AuthProvider/contexts/AuthContext'
import LogoMock from '../../assets/images/logoMock.png'
import HeaderImage from '../../assets/images/headerLarge.png'
import HeaderImageM from '../../assets/images/headerP.png'
import useStyles from './style'
import history from '../../routes/history';
import { hideRecaptcha, showRecaptcha } from '../../utils/reCaptcha'


const AppBar = ({ children }: any) => {
    hideRecaptcha()
    const classes = useStyles();
    const { user, auth } = useContext(Context);
    const [openMMenu, setOpenMMenu] = useState(false)
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 598px)' })
    const isTablet = useMediaQuery({ minWidth: 600, maxWidth: 1100 })

    const goRoute = ((path: string) => {

        if (window.location.pathname === path) {
            window.location.reload()
            return
        }
        else if (path.includes('://')) {
            window.open(path, '_blank')
            return
        }
        else if (path !== '') {
            history.push(path)
            return
        }
    })

    const isMenuActive = (path: string) => {

        if (history.location.pathname.includes(path)) {
            return true
        }

        return false
    }

    const MenuAndSubMenu = (prop: any) => {
        return (
            <Menu menuButton={
                ({ open }) =>
                    <Button style={{ textTransform: 'none' }} endIcon={open ? <ExpandLess /> : <ExpandMore />}>{prop.element.title}</Button>}>
                {
                    prop.element.children.map((el: any, ind: any) => {
                        return (
                            <MenuItem key={el.title + ind} onClick={() => goRoute(el.path)} className={isMenuActive(el.path) ? classes.activeMenu : ''} >
                                <Typography className={isMenuActive(el.path) ? classes.activeTextMenu : ''} color="textPrimary" variant="caption">{el.title}</Typography>
                            </MenuItem>
                        )
                    })
                }
            </Menu>
        )
    }

    const MenuSolo = (prop: any) => {
        return (
            <Button style={{ textTransform: 'none' }} className={isMenuActive(prop.element.path) ? classes.activeMenu : ''} onClick={() => goRoute(prop.element.path)}>{prop.element.title}</Button>
        )
    }

    const ListMobileMenu = (prop: any) => {

        //TO-DO MOCK PARA TESTAR MAIS ROTAS NO MENU -
        //DESCOMENTAR E ABRIR O MENU MOBILE PARA ACRESCENTAR
        // prop.menus.push({
        //     "title": "Site do Google",
        //     "hasChildren": false,
        //     "path": 'https://www.google.com.br'
        // }, {
        //     "title": "Laudos",
        //     "hasChildren": false,
        //     "path": '/laudo'
        // },
        //     {
        //         "title": 'PAI',
        //         "hasChildren": true,
        //         "path": '',
        //         "children": [
        //             {
        //                 "title": "Filho-1",
        //                 "path": "/grupo-usuario",
        //                 "hasChildren": false
        //             },
        //             {
        //                 "title": "Filho-2",
        //                 "path": "/usuario",
        //                 "hasChildren": false
        //             }
        //         ]
        //     })

        return (
            <List
                className={classes.listMobileMenu}
                component="nav"
                aria-labelledby="nested-list-subheader">
                {
                    prop.menus.map((el: any, ind: any) => {
                        return (
                            <>
                                <ListItem button>
                                    <ListItemText primary={el.title} onClick={() => goRoute(el.path)} />
                                </ListItem>
                                {
                                    el.hasChildren
                                        ?
                                        <Collapse in={true} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {

                                                    el.children.map((elChi: any, indChi: any) => {
                                                        return (
                                                            <ListItem className={`${isMenuActive(elChi.path) ? classes.activeMenu : ''} ${classes.nestedListMenu}`} button>
                                                                <ListItemText onClick={() => goRoute(elChi.path)} primary={elChi.title} />
                                                            </ListItem>
                                                        )
                                                    })
                                                }
                                            </List>
                                        </Collapse>

                                        :
                                        <div></div>

                                }
                                <Divider />
                            </>

                        )
                    })
                }
            </List>)
    }

    return (
        <>


            <div className={classes.rootAppBar}>
                <Grid container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center">

                    <Grid item>
                        {
                            isTabletOrMobile &&
                            <>
                                <Button color="primary" onClick={() => setOpenMMenu(true)}><MenuIcon /></Button>
                                <Drawer anchor='left' open={openMMenu} onClose={() => setOpenMMenu(false)}>
                                    <ListMobileMenu menus={user?.menus} />
                                </Drawer>
                            </>
                        }
                    </Grid>

                    <Grid item>
                        <Menu menuButton={
                            <Fab title='Usuário' size="small" color="primary" aria-label="usuário">
                                <PersonIcon />
                            </Fab>}>
                            <MenuItem>
                                <Typography onClick={() => history.push('/alterar-senha')} variant="subtitle2">Alterar Senha</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => {
                                showRecaptcha()
                                auth?.handleLogoff()
                            }}>
                                <Typography variant="subtitle2">Sair</Typography>
                            </MenuItem>
                        </Menu>

                    </Grid>

                </Grid>

                <Grid spacing={1} container item justifyContent="flex-start" alignItems="center">
                    <Grid item  >
                        <img alt="logo-ijc" src={LogoMock} width="130px" />
                    </Grid>
                    <Grid item >
                        <Box display="flex" flexWrap="wrap" flexDirection="row">
                            {
                                !isTabletOrMobile &&
                                user?.menus.map((el: any, ind: any) => {
                                    return (
                                        <div key={el.title + ind}>
                                            {el.hasChildren ?
                                                <MenuAndSubMenu element={el} />
                                                : <MenuSolo element={el} />}
                                        </ div>
                                    )
                                })
                            }
                        </Box>
                    </ Grid>
                </Grid>
                <Grid >
                    {!isTabletOrMobile &&
                        <Grid item md={12}>
                            <img alt="img-divider-header" src={isTablet ? HeaderImageM : HeaderImage} width={'100%'} />
                        </Grid>}
                </Grid>
                <Box pt={3}>
                    {children ?? <div>Carregando...</div>}
                </Box>
            </div>
        </>
    );
}

export default AppBar

