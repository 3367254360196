import React, { useContext } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';

import Spinner from './components/spinner';
import { Context } from "../../providers/AuthProvider/contexts/AuthContext";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 999,
        color: '#fff',
    },
}));


const SimpleBackdrop = () => {
  const { auth } = useContext(Context);  
  const classes = useStyles();

  return (       
      <Backdrop className={classes.backdrop} open={auth?.isLoading ?? false} >
        <Spinner />       
      </Backdrop>    
  );
}

export default SimpleBackdrop