import { useContext, useState } from "react";
import { Context } from "../../providers/AuthProvider/contexts/AuthContext";
import history from '../../routes/history'
import Snackbar from '../../components/Snackbar'
import api from "../../utils/api";
import ModalSearchInstitutions from '../../components/ModalSearchInstitutions'
import { Box, Grid } from "@material-ui/core";

type CbInstituteProps = {
    tasyCode: number,
    cnes: string,
    corporateName: string,
    cnpj: string,
    cityState: string,
    webAccess: string
}

const Dashboard = (props: any) => {

    const { auth } = useContext(Context);
    
    const [open, setOpen] = useState(false);
    const [cbInstitute, setCbInstitute] = useState<CbInstituteProps>()


    const onClose = () => {
        setOpen(false);
    }

    const resp = async () => {
        await api.post
            ('/user/ijc/login', { email: 'lasdiuid', password: 'asdjksajkj' })
    }

    const menssageTeste = () => {

        let obj = {
            message: 'Deu Errado',
            type: 'error'
        }

        return Snackbar(obj)
    }


    return (
        <>
            <h1>Dashboard Aplicação</h1>
            <button onClick={auth?.handleLogoff}>DESLOGAR</button>
            <button onClick={() => history.push('/cadastro')}>Cadastro </button>
            <button onClick={() => resp()}>TESTE ERRO API</button>
            <button onClick={menssageTeste}>Teste Menssagem</button>
            <button onClick={() => setOpen(true)}>Pesquisa Instituições</button>
            <ModalSearchInstitutions open={open} onCallBack={setCbInstitute}
                onClose={onClose} />

            <Grid container direction='column'>
                <Box mt={3} mb={1}>
                    <label>{cbInstitute?.corporateName}</label>
                </Box>
                <Box mb={1}>
                    <label>{cbInstitute?.cityState}</label>
                </Box>
                <Box mb={1}>
                    <label>{cbInstitute?.cnpj}</label>
                </Box>
                <Box mb={1}>
                    <label>{cbInstitute?.tasyCode}</label>
                </Box>
            </Grid>

        </>
    )
}

export default Dashboard