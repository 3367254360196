import { makeStyles } from "@material-ui/core/styles";
import Logo from '../../assets/images/logo-ijc.png';

export default makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {        
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundImage: `linear-gradient(rgba(141, 153, 174, 0.5), rgba(231, 68, 129, 0.8)), url(${Logo})`,
        backgroundSize: '42% 100vh',        
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed'  
    },
    paper: {     
        margin: theme.spacing(4, 4),  
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        justifyContent:'center',
        display:'flex'                
    },    
    avatarMB: {
        margin: theme.spacing(1),               
    },    
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
        maxWidth: '500px'
    },
    
    submit: {
        textTransform: 'none',
        height: '50px',
        fontSize: '17px',       
        margin: theme.spacing(3, 0, 2),
    },  
    paperRadio:{       
        width: 100,
        height: 120,
        fontWeight: 'bold',
        padding: '10px',
        marginRight:'10px',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#f2f2f2'
    },
}));