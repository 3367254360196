import { useState, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  CssBaseline,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ApartmentIcon from "@material-ui/icons/Apartment";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Context } from "../../providers/AuthProvider/contexts/AuthContext";
import history from "../../routes/history";
import useStyles from "./style";
import LogoMock from "../../assets/images/logoMock.png";
import Logo from "../../assets/images/logo-rectangle.png";
import {
  Assignment,
  PhoneAndroid,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import DialogHelpDV from "./components/DialogHelpDV";
import { RECAPTCHA_ACTIONS } from "../../utils/reCaptcha";
import { loginPF } from "../../providers/AuthProvider/services";
import { AxiosError } from "axios";
import CreatePasswordFirstAccess from "./components/CreatePasswordFirstAccess";
import { convertToDigits } from "../../utils/convertToDigits";
import SnackBar from "../../components/Snackbar";
import ReactInputMask from "react-input-mask";
import DialogFirstAccess from "./components/DialogFirstAccess";
import PhraseReferenceServiceNeonatal from "../../components/PhraseReferenceServiceNeonatal";

import "./style.css";

type ErrorContent = {
  typeLoginPf: number;
  dnv: string;
  cellPhone: string;
  medicalRecordNumber: string;
};

declare global {
  interface Window {
    grecaptcha: any;
  }
}

export const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.ijc.org.br/">
        Instituto Jô Clemente
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

export const HeaderImage = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  if (isTabletOrMobile) {
    return (
      <>
        <Box display="flex" justifyContent="center">
          <img alt="logo ijc" src={LogoMock} style={{ width: "60%" }} />
        </Box>

        <Box>
          <PhraseReferenceServiceNeonatal />
        </Box>
      </>
    );
  } else {
    return (
      <Box mb={5} style={{ width: "70%" }}>
        <img alt="logo ijc" src={LogoMock} style={{ width: "45%" }} />

        <PhraseReferenceServiceNeonatal />
      </Box>
    );
  }
};

export const ImageLeft = () => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={false} md={5} sm={5} className={classes.image}></Grid>
    </>
  );
};

export const ImageTopMobile = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container>
        <img
          src={Logo}
          alt="Logo mãe com bebê"
          className={classes.imageMobile}
        />
        <Grid
          container
          onClick={() => history.push("/login/hospitais")}
          alignItems="center"
          justifyContent="center"
          className={classes.buttonChangeLoginMobile}
        >
          <Grid item>
            <Box textAlign="center">
              <ApartmentIcon fontSize="large" />
              <Typography variant="subtitle2" style={{ fontSize: "18px" }}>
                Hospitais, Clique aqui
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export const StyledRadio = (props: any) => {
  return <Radio color="primary" checkedIcon={<CheckCircleIcon />} {...props} />;
};

const LoginPF = () => {
  const classes = useStyles();
  const [visibilityPassword, setVisibilityPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const { authenticatedUser } = useContext(Context);
  const handleClose = () => {
    setOpen(false);
  };
  const [firstAccess, setFirstAccess] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>();
  const [errorContent, setErrorContent] = useState<ErrorContent>();

  const [openFirstAccessHelp, setOpenFirstAccessHelp] = useState(false);
  const handleCloseFirstAccess = () => {
    setOpenFirstAccessHelp(false);
  };

  const formik = useFormik({
    initialValues: {
      dnv: "",
      cellPhone: "",
      medicalRecordNumber: "",
      password: "",
      loginType: "dnv",
    },

    validationSchema: Yup.object({
      dnv: Yup.string().when("loginType", {
        is: "dnv",
        then: Yup.string().required("Obrigatório"),
      }),
      cellPhone: Yup.string().when("loginType", {
        is: "cell",
        then: Yup.string().required("Obrigatório"),
      }),
      medicalRecordNumber: Yup.string().when("loginType", {
        is: "pron",
        then: Yup.string().required("Obrigatório"),
      }),
      password: Yup.string()
        .min(6, "A senha deve possuir 6 ou mais caracteres")
        .required("Obrigatório"),
      loginType: Yup.string().required("Obrigatório"),
    }),
    onSubmit: (values) => {
      setPasswordErrorMessage(null);
      /* console.log(values.cellPhone.replace(/\D/g,"")) */

      /* handleRecaptcha(RECAPTCHA_ACTIONS.SUBMIT_FORM_RESET) */

      //TO-DO - Fazer o login e verificar se é o primeiro acesso (firstAccess == true)
      //        Exibir a tela de criação de usuário e senha interno
      //        Verificar se volta para o login ou permite o usuário acessar a aplicação
      // Se (firstAccess == false) faz o login normalmente.

      //RECAPTCHA_ACTIONS.LOGIN).catch((error : any) => console.log('entrou aqui'))

      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_RECAPTCHA_PK, {
            action: RECAPTCHA_ACTIONS.LOGIN,
          })
          .then(async (token: any) => {
            //TO-DO - Validar Recaptcha no backend e tratar aqui quando ouver.

            switch (values.loginType) {
              case "dnv":
                loginPF(
                  {
                    dnv: values.dnv,
                    password: values.password,
                    typeUser: 1,
                    typeLoginPf: 0,
                  },
                  token
                )
                  .then((resp) => {
                    if (authenticatedUser) {
                      authenticatedUser(resp); //autentica o user e manda p página laudo
                    }
                  })
                  .catch((err: AxiosError) => {
                    if (
                      err.response?.status === 400 &&
                      err.response.data.content["clientPhysicalPerson"]
                    ) {
                      setPasswordErrorMessage(err.response?.data.detail);
                    }
                    if (
                      err.response?.status === 401 &&
                      err.response.data.content["firstAccess"]
                    ) {
                      SnackBar({
                        message: err.response.data.detail,
                        type: "error",
                      });
                      setErrorContent(err.response.data.content);
                      setFirstAccess(true);
                    }
                  });
                break;

              case "cell":
                loginPF(
                  {
                    cellPhone: convertToDigits(values.cellPhone),
                    password: values.password,
                    typeUser: 1,
                    typeLoginPf: 1,
                  },
                  token
                )
                  .then((resp) => {
                    if (authenticatedUser) {
                      authenticatedUser(resp);
                    }
                  })
                  .catch((err: AxiosError) => {
                    if (
                      err.response?.status === 400 &&
                      err.response.data.content["clientPhysicalPerson"]
                    ) {
                      setPasswordErrorMessage(err.response?.data.detail);
                    }
                    if (
                      err.response?.status === 401 &&
                      err.response.data.content["firstAccess"]
                    ) {
                      SnackBar({
                        message: err.response.data.detail,
                        type: "error",
                      });
                      setErrorContent(err.response.data.content);
                      setFirstAccess(true);
                    }
                  });
                break;

              case "pron":
                loginPF(
                  {
                    medicalRecordNumber: values.medicalRecordNumber,
                    password: values.password,
                    typeUser: 1,
                    typeLoginPf: 2,
                  },
                  token
                )
                  .then((resp) => {
                    if (authenticatedUser) {
                      authenticatedUser(resp);
                    }
                  })
                  .catch((err: AxiosError) => {
                    if (
                      err.response?.status === 400 &&
                      err.response.data.content["clientPhysicalPerson"]
                    ) {
                      setPasswordErrorMessage(err.response?.data.detail);
                    }
                    if (
                      err.response?.status === 401 &&
                      err.response.data.content["firstAccess"]
                    ) {
                      SnackBar({
                        message: err.response.data.detail,
                        type: "error",
                      });
                      setErrorContent(err.response.data.content);
                      setFirstAccess(true);
                    }
                  });

                break;

              default:
                console.log("Opção desconhecida");
            }
          })
          .catch((errorRecaptcha: any) => {
            console.log(errorRecaptcha);
          });
      });
    },
  });

  const ButtonChangeTypeUserLogin = () => {
    return (
      <Grid
        container
        onClick={() => history.push("/login/hospitais")}
        justifyContent="flex-end"
      >
        <Grid item className={classes.buttonChangeLogin}>
          <Box textAlign="center" m="15px">
            <ApartmentIcon fontSize="large" />
            <Typography variant="subtitle2" style={{ fontSize: "18px" }}>
              Hospitais, Clique aqui
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {firstAccess ? (
        <CreatePasswordFirstAccess
          typeLoginPf={errorContent?.typeLoginPf}
          dnv={errorContent?.dnv}
          cellPhone={errorContent?.cellPhone}
          medicalRecordNumber={errorContent?.medicalRecordNumber}
        />
      ) : (
        <>
          <DialogHelpDV handleClose={handleClose} open={open} />
          <DialogFirstAccess
            handleClose={handleCloseFirstAccess}
            open={openFirstAccessHelp}
          />

          {isTabletOrMobile && <ImageTopMobile />}

          <Grid container className={`${classes.root} login-wrapper-main`}>
            <CssBaseline />

            {!isTabletOrMobile && <ImageLeft />}

            <Grid
              item
              xs={12}
              md={isTabletOrMobile ? 12 : 7}
              sm={isTabletOrMobile ? 12 : 7}
              component={Paper}
              square
            >
              <div className={classes.paper} hidden={true}>
                <form onSubmit={formik.handleSubmit} className={classes.form}>
                  {!isTabletOrMobile && <ButtonChangeTypeUserLogin />}

                  <HeaderImage />

                  <Box mb={2}>
                    <Typography
                      variant="h4"
                      style={{ fontWeight: "bolder" }}
                      color="textPrimary"
                    >
                      Entrar
                    </Typography>
                    <Typography
                      variant="inherit"
                      style={{ fontSize: "18px" }}
                      color="textSecondary"
                    >
                      Escolha uma forma de acesso
                    </Typography>
                  </Box>

                  <Box display="flex" mt={4} mb={4}>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="loginType"
                      onChange={formik.handleChange}
                      value={formik.values.loginType}
                    >
                      <Paper className={classes.paperRadio}>
                        <Box flexWrap="nowrap" textAlign="center">
                          <AssignmentIndIcon color="primary" fontSize="large" />
                          <Typography
                            variant="subtitle2"
                            style={{ fontSize: "18px" }}
                            color="textSecondary"
                          >
                            DNV
                          </Typography>
                          <FormControlLabel
                            value="dnv"
                            label=""
                            labelPlacement="top"
                            control={<StyledRadio />}
                          />
                        </Box>
                      </Paper>

                      <Paper className={classes.paperRadio}>
                        <Box flexWrap="nowrap" textAlign="center">
                          <PhoneAndroid color="primary" fontSize="large" />
                          <Typography
                            variant="subtitle2"
                            style={{ fontSize: "18px" }}
                            color="textSecondary"
                          >
                            Celular
                          </Typography>
                          <FormControlLabel
                            value="cell"
                            label=""
                            labelPlacement="top"
                            control={<StyledRadio />}
                          />
                        </Box>
                      </Paper>

                      <Paper className={classes.paperRadio}>
                        <Box flexWrap="nowrap" textAlign="center">
                          <Assignment color="primary" fontSize="large" />
                          <Typography
                            variant="subtitle2"
                            style={{ fontSize: "18px" }}
                            color="textSecondary"
                          >
                            Prontuário
                          </Typography>
                          <FormControlLabel
                            value="pron"
                            label=""
                            labelPlacement="top"
                            control={<StyledRadio />}
                          />
                        </Box>
                      </Paper>
                    </RadioGroup>
                  </Box>

                  {!!passwordErrorMessage && (
                    <Box mb={4}>
                      <Typography
                        variant="h6"
                        color="primary"
                        className={classes.messagePassword}
                      >
                        <p style={{ fontSize: '14px', color: '#ef4c36' }}>{passwordErrorMessage}</p>
                      </Typography>
                    </Box>
                  )}

                  {formik.values.loginType === "dnv" && (
                    <Box mb={1}>
                      <Grid container justifyContent="space-between">
                        <label
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            color: "#5f5f5f",
                          }}
                        >
                          DNV
                        </label>

                        <Link
                          style={{ cursor: "pointer" }}
                          onClick={() => setOpen(true)}
                          color="inherit"
                        >
                          Onde encontrar?
                        </Link>
                      </Grid>
                      <TextField
                        size="small"
                        variant="outlined"
                        margin="none"
                        required
                        fullWidth
                        id="dnv"
                        placeholder="Nº documento"
                        error={formik.touched.dnv && Boolean(formik.errors.dnv)}
                        helperText={formik.touched.dnv && formik.errors.dnv}
                        {...formik.getFieldProps("dnv")}
                      />
                    </Box>
                  )}
                  {formik.values.loginType === "cell" && (
                    <Box mb={1}>
                      <label
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          color: "#5f5f5f",
                        }}
                      >
                        Celular
                      </label>
                      <ReactInputMask
                        mask="(99) 9 9999-9999"
                        value={formik.values.cellPhone}
                        onChange={(e) =>
                          formik.setFieldValue("cellPhone", e.target.value)
                        }
                      >
                        {() => (
                          <TextField
                            size="small"
                            variant="outlined"
                            margin="none"
                            fullWidth
                            id="cellPhone"
                            placeholder="Celular"
                            error={
                              formik.touched.cellPhone &&
                              Boolean(formik.errors.cellPhone)
                            }
                            helperText={
                              formik.touched.cellPhone &&
                              formik.errors.cellPhone
                            }
                          />
                        )}
                      </ReactInputMask>
                    </Box>
                  )}

                  {formik.values.loginType === "pron" && (
                    <Box mb={1}>
                      <label
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          color: "#5f5f5f",
                        }}
                      >
                        Prontuário
                      </label>
                      <TextField
                        size="small"
                        variant="outlined"
                        margin="none"
                        required
                        fullWidth
                        id="pron"
                        placeholder="Prontuário"
                        error={
                          formik.touched.medicalRecordNumber &&
                          Boolean(formik.errors.medicalRecordNumber)
                        }
                        helperText={
                          formik.touched.medicalRecordNumber &&
                          formik.errors.medicalRecordNumber
                        }
                        {...formik.getFieldProps("medicalRecordNumber")}
                      />
                    </Box>
                  )}

                  <Box mb={1}>
                    <Grid container justifyContent="space-between">
                      <label
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          color: "#5f5f5f",
                        }}
                      >
                        Senha
                      </label>
                      <Link
                        style={{ cursor: "pointer" }}
                        onClick={() => setOpenFirstAccessHelp(true)}
                        color="inherit"
                      >
                        Primeiro Acesso?
                      </Link>
                    </Grid>
                    <TextField
                      size="small"
                      variant="outlined"
                      margin="none"
                      required
                      fullWidth
                      placeholder="Senha"
                      type={visibilityPassword ? "text" : "password"}
                      id="password"
                      autoComplete="current-password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setVisibilityPassword(!visibilityPassword)
                              }
                            >
                              {visibilityPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      {...formik.getFieldProps("password")}
                    />
                  </Box>
                  <Box textAlign="end">
                    <Link
                      className={classes.linkRequestPass}
                      href="#"
                      variant="body2"
                      onClick={() => history.push("/nova-senha-cliente")}
                    >
                      Esqueceu a senha?
                    </Link>
                  </Box>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Entrar
                  </Button>
                  <Box mt={5}>
                    <Copyright />
                  </Box>
                </form>
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default LoginPF;
