import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    textAreaContainer: {
      padding: '5px'
    },
    textArea: {
        width: '100%',
    },
    contentCenter : {
        justifyContent: 'center',
        display: 'flex'
    },
    editLink: {
        paddingRight: '15px'
    },
}))
