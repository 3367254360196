
import { useContext } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import AppBar from "../layouts/Appbar";
import { Context } from "../providers/AuthProvider/contexts/AuthContext";
import ShowHasPermission from "../utils/showHasPermission";
// import { handleLogin } from "../utils/reCaptcha";

import { routes } from "./routes";
//import { Context } from "../providers/contexts/AuthContext";


/** Componente que renderiza as rotas privadas da aplicação
 * 
 * `Rotas privadas` são aquelas que necessitam
 *  de autenticação via token
*/




const PrivateRoute = (props: any) => {
    if (props.auth.authenticated) {
        if (ShowHasPermission(props.permission)) {
            return (
                <AppBar key={props.id}>
                    <Route key={props.key} exact={props.exact} path={props.path} component={props.component} />
                    {/* <Route exact {...props} /> */}
                </AppBar>
            );
        } else if (!props.permission) {
            return (
                <AppBar key={props.id}>
                    <Route key={props.key} exact={props.exact} path={props.path} component={props.component} />
                </AppBar>
            );
        } else {
            return <Redirect key={props.id} to='/sem-autorizacao' />
        }
    }
    else if (props.auth.checkIsAuthStore()) {
       
        return <Redirect key={props.id} to={props.location.pathname} />
    }
    else {
        return <Redirect key={props.id} to='/login/hospitais' />
    }
};

/**Componente que renderiza as rotas publicas da aplicação
 * 
 * `Rotas públicas` são aquelas que não necessitam 
 * de autenticação. Ex: Telas de Login ou Redefinir de senha
 *  
*/
const PublicRoute = (props: any) => {

    return (
        <>           
          <Route key={props.id} exact={props.exact} path={props.path} component={props.component} />
        </>
    )
};

const Routes = () => {

    const { auth } = useContext(Context);

    return (
        <Switch>
            {routes.map(({ id, component, path, publicPath, exact=false , permission }) => {
                if (publicPath) {
                    return <PublicRoute key={id} exact={exact} component={component} path={path} publicPath={publicPath} />
                }

                return <PrivateRoute key={id} auth={auth} exact={exact} component={component} path={path} publicPath={publicPath} permission={permission} />
            })}
            <Redirect path='*' to='/login/clientes' />
        </Switch>
    )
};

export default Routes;
