import api from '../../utils/api'

type valuesType = {
  tipo: number
  message: string
  contactPhoneNumber: string
  file: any
}

type tipoSugestoes = {
  id: number
  description: string
}

export const enviarSugestaoService = async (values: valuesType) => {
  const formData = new FormData()

  formData.append('typeMessageSacId', values.tipo.toString())
  formData.append('contactPhoneNumber', values.contactPhoneNumber)
  formData.append('message', values.message)

  if (values.file !== undefined) {
    if (values.file.length > 1) {
      values.file.forEach((item: any) => {
        formData.append('Attachments', item)
      })
    } else {
      formData.append('Attachments', values.file[0])
    }
  }

  await api.post('/message', formData)
}

export const getSuggestionsType = async (): Promise<Array<tipoSugestoes>> => {
  const response = await api.get('/type-message')

  return response.data
}
