import { Box, Button, Fab, FormControl, Grid, InputAdornment, MenuItem, Select, TextField, Typography } from "@material-ui/core"
import { deleteGroup, getUserGroup } from "../../services";
import { useState, useContext } from "react";
import { Edit, DeleteOutline, Add, Search } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import { useEffect } from "react";
import { debounce } from "lodash";


import ContextUserGroup from "../../context";
import ModalConfirmation from "../../../../components/ModalConfirmation";
import Snackbar from "../../../../components/Snackbar";
import ShowHasPermission  from "../../../../utils/showHasPermission";

const ViewwDataGridUserGroup = () => {
    type SelectableRows = 'multiple' | 'single' | 'none';
    type ResponsiveType = 'vertical' | 'standard' | 'simple';

    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [page, setPage] = useState(0)
    const [searchMemo, setSearchNameGroup] = useState()
    const [searchStatusMemo, setSearchStatus] = useState('all')
    const [confirmationModal, setConfirmationModal] = useState({ open: false, message:<></> })
    const { setMode, setGroupId, groupeId }: any = useContext(ContextUserGroup)
   

    //Utiliza ao iniciar o componente
    useEffect(() => {
        getUserGroup(1, 5).then((resp: any) => {
            setData(resp.data.content)
            setTotalRows(resp.data.totalRecords)
            setRowsPerPage(resp.data.pageable.pageSize)
        })
    }, [])


    //Utiliza na paginação e pesquisa da tabela
    const requestOnChange = async (page: any, rowsPerPage: any, searchParam?: any, statusParam?: any) => {
        await getUserGroup(page + 1, rowsPerPage, searchParam ?? searchMemo, statusParam ?? searchStatusMemo).then((resp: any) => {
            setData(resp.data.content)
            setTotalRows(resp.data.totalRecords)
            setRowsPerPage(resp.data.pageable.pageSize)
            setPage(resp.data.pageable.pageNumber - 1)
        })
    }

    const onSearchFilter = async (event: any) => {
        const search = event.target.value
        setSearchNameGroup(search)
        requestOnChange(0, rowsPerPage, search)
    }

    const handleChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
        const status: any = event.target.value
        setSearchStatus(status)
        //setPage(0)              
        requestOnChange(0, rowsPerPage, null, status)
    };

    const options = {
        search: false,
        download: false,
        expandableRowsHeader: false,
        filter: false,
        print: false,
        selectableRowsHeader: false,
        viewColumns: false,
        rowsPerPageOptions: [5, 10],
        rowsPerPage: rowsPerPage,
        responsive: 'simple' as ResponsiveType,
        selectableRows: 'none' as SelectableRows,
        page: page,
        textLabels: {
            body: {
                noMatch: "Nenhum registro encontrado.",
            },
            pagination: {
                next: "Próxima Pagina",
                previous: "Pagina Anterior",
                rowsPerPage: "",
                displayRows: "de",
            },
        },
        count: totalRows,
        serverSide: true,
        onTableChange: async (action: any, tableState: any) => {
            switch (action) {
                case 'changePage':
                    requestOnChange(tableState.page, tableState.rowsPerPage);
                    break;
                case 'changeRowsPerPage':
                    requestOnChange(tableState.page, tableState.rowsPerPage);

                    break;
                default:
            }
        },
        setTableProps: () => {
            return {
                size: 'small'
            };
        },
    }

    const columns = [
        {
            name: "id",
            label: "Código",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "name",
            label: "Nome",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "active",
            label: "Ativo",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (index: boolean) => {
                    return (
                        <>{index ? 'Sim' : 'Não'}</>
                    )
                }
            },
        },
        {
            name: "Ações",
            options: {
                filter: false,
                sort: false,
                empty: true,
                setCellProps: () => ({ align: "center", }),
                setCellHeaderProps: () => ({ align: "center", }),
                customBodyRenderLite: (index: any) => {
                    return (
                        <>
                            <Button color="primary" onClick={() => { onEditCallback(data[index]) }} disabled={!ShowHasPermission('GrupoUsuario_Alteração')}>
                                <Edit titleAccess='Editar' />
                            </Button>
                            <Button color="secondary" onClick={() => {handleOpenModalConfirmation(data[index])}} disabled={!ShowHasPermission('GrupoUsuario_Exclusão')}>
                                <DeleteOutline titleAccess='Excluir' />
                            </Button>
                        </>
                    );
                }
            }
        }
    ];

    const handleOpenModalConfirmation = (row: any) => {
        setGroupId(row.id)
        const nameGroup = row.name
        const message = <>Deseja realmente excluir o grupo: <b>{nameGroup}?</b></>

        setConfirmationModal({
            open: true,
            message: message
        })
    }

    const handleCloseModalConfirmation = () => {
        setConfirmationModal({
            open: false,
            message:confirmationModal.message
        })
    }

    const onEditCallback = (row: any) => {
        setGroupId(row.id)
        setMode('edit')
    }

    const onAddCallback = (row: any) => {
        setMode('add')
    }
    
    const onDeleteCallback = async () => {
           
            deleteGroup(groupeId).then(() => {
                const index = data.findIndex((o: any) => o.id === groupeId)
                data.splice(index, 1);
                setTotalRows(totalRows - 1)

                let pageIn = page

                if (data.length === 0) {
                    pageIn = pageIn - 1
                }

                getUserGroup(pageIn + 1, rowsPerPage).then((resp: any) => {
                    setSearchStatus('all')
                    setData(resp.data.content)
                    setTotalRows(resp.data.totalRecords)
                    setRowsPerPage(resp.data.pageable.pageSize)
                    setPage(resp.data.pageable.pageNumber - 1)
                    Snackbar({ message: 'Grupo excluido com sucesso!', type: 'success' })
                })
            })
    }

    // //Verifica se o usuário logado possui a permissão para acessar o recurso.
    // const showHasPermission = (permission:string) => {
        
    //     const hasPermission = user?.permissions?.includes(permission)

    //     if(hasPermission){
    //         return true
    //     }

    //     return false
    // }

    return (
        <>
            <Grid container>
                <Grid item >
                    <Typography variant="h5" style={{ fontWeight: 'bolder' }} color="textPrimary">Grupos Usuários</Typography>
                </Grid>
            </Grid>
            <Box pt={'10px'}>
                <Grid container justifyContent="space-between" alignItems="flex-end">
                    <Grid item sm={1}>
                        <Box mb={2}>
                            <Fab onClick={onAddCallback} title='Adicionar' size="small" color="primary" aria-label="add" disabled={!ShowHasPermission('GrupoUsuario_Cadastro')}>
                                <Add />
                            </Fab>
                        </Box>
                    </Grid>
                    <Grid item container sm={11} justifyContent={"flex-end"}>
                        <Grid item xs={8} sm={6}>
                            <Box mb={2} mr={1}>
                                <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Pesquisar</Typography>
                                <TextField
                                    fullWidth
                                    onChange={debounce((e) => onSearchFilter(e), 1000)}
                                    size="small"
                                    variant="outlined"
                                    margin="none"
                                    id="search-table"
                                    placeholder="Digite o nome do grupo"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Search />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Box>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <Box mb={2}>
                                <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Status</Typography>
                                <FormControl variant="outlined" size="small" margin="none" fullWidth>
                                    <Select
                                        placeholder="Status"
                                        labelId="demo-customized-select-label"
                                        id="demo-customized-select"
                                        onChange={handleChangeStatus}
                                        value={searchStatusMemo}
                                    >
                                        <MenuItem value={'all'}>Todos</MenuItem>
                                        <MenuItem value={'true'}>Ativos</MenuItem>
                                        <MenuItem value={'false'}>Inativos</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <MUIDataTable
                    options={options}
                    title={""}
                    data={data}
                    columns={columns}
                />
            </Box>
            <ModalConfirmation
                open={confirmationModal.open}
                message={confirmationModal.message}
                title='Excluir'
                onClose={handleCloseModalConfirmation}
                onConfirm={onDeleteCallback}
            />
        </>
    )
}

export default ViewwDataGridUserGroup