import { useContext, useEffect, useState } from "react"
import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid, Switch, TextField, Typography } from "@material-ui/core"
import { useFormik } from "formik"
import * as Yup from 'yup'

import ContextUserGroup from "../../context"
import { getUsergroupById, getPermissions, postGroup, putGroup } from "../../services"
import useStyles from './style'
import { iGroupPost, iGroupPut } from "../../interfaces"
import Snackbar from "../../../../components/Snackbar"

const CreateUpdateUserGroup = () => {
    const classes = useStyles();
    const { setMode, mode, groupeId }: any = useContext(ContextUserGroup)
    const [group, setGroupInfo]: any = useState();
    const [groupPermission, setGroupPermission]: any = useState();

    useEffect(() => {

        getPermissions().then(resp => {
            setGroupPermission(resp.data)
        });

        if (mode === 'edit') {
            getUsergroupById(groupeId).then(resp => {
                setGroupInfo(resp.data)
            })
        }
    }, [mode, groupeId])

    useEffect(() => {
        setFormValues()
    }, [group])// eslint-disable-line react-hooks/exhaustive-deps


    const titlePage = mode === 'edit' ? 'Editar' : 'Adicionar'
    const arrayInit: any = []

    const formik = useFormik({
        initialValues: {
            id: 0,
            name: '',
            status: false,
            permissions: arrayInit
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Obrigatório').max(60, 'O nome do grupo deve ter no máximo 60 caracteres.')
        }),
        onSubmit: values => {

            if (mode === 'add') {
                const groupPost: iGroupPost = {
                    name: values.name,
                    status: values.status,
                    permissions: values.permissions
                }

                createGroup(groupPost)
            }

            if (mode === 'edit') {

                const groupPut: iGroupPut = {
                    id: values.id,
                    name: values.name,
                    active: values.status,
                    permissions: values.permissions
                }

                updateGroup(groupPut)
            }

        }
    })

    const createGroup = (group: iGroupPost) => {
        postGroup(group).then(() => {
            Snackbar({ message: 'Grupo adicionado com sucesso!', type: 'success' })
            setMode('view')
        })
    }

    const updateGroup = (group: iGroupPut) => {
        putGroup(group).then(() => {
            Snackbar({ message: 'Grupo editado com sucesso!', type: 'success' })
            setMode('view')
        })
    }

    const setFormValues = () => {
        if (group) {

            let permissionsId: any[] = []

            formik.setFieldValue('id', group.id)
            formik.setFieldValue('name', group.name)
            formik.setFieldValue('status', group.active)

            group.groupPermissions.forEach((per: any, ind: any) => {
                per.permissions.forEach((permi: any) => {
                    permissionsId.push(permi.id)
                })
            })
            formik.setFieldValue('permissions', permissionsId)
        }
    }


    const isCheck = (id: number) => {

        const check = formik.values.permissions.includes(id, 0)
        return check

    }

    const handleCheck = (evt: any, id: any) => {
        const listPermTemp = formik.values.permissions

        if (evt.target.checked) {
            listPermTemp.push(id)
        }
        else {
            const index = listPermTemp.indexOf(id);
            if (index > -1) {
                listPermTemp.splice(index, 1);
            }
        }

        formik.setFieldValue('permissions', listPermTemp)
    }

    return (
        <>
            <Grid container>
                <Grid item >
                    <Typography variant="h5" style={{ fontWeight: 'bolder' }} color="textPrimary">{`${titlePage}`}</Typography>
                </Grid>
            </Grid>

            <form onSubmit={formik.handleSubmit} >
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={7} sm={4}>
                        <Box mt={1} mb={5}>
                            <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Nome do Grupo</Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                margin="none"
                                required
                                fullWidth
                                id="name"
                                placeholder="Nome do Grupo"
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                                {...formik.getFieldProps('name')}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={5} sm={1}>
                        <Box ml={5}>
                            <FormControlLabel value="top" control={
                                <Switch checked={formik.values.status} onClick={formik.handleChange} name="status" color="primary" />
                            } label={formik.values.status ? 'Ativo' : 'Inativo'} labelPlacement="end" />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container justifyContent="center" alignItems="stretch" spacing={2} >
                    {groupPermission && groupPermission.map((g: any, gi: any) => {
                        return (
                            <Grid item md={3} sm={6} xs={12}>
                                <Box mt={1} mb={5}>
                                    <FormControl component="fieldset">
                                        <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>{g.displayGroup}</Typography>
                                    </FormControl>
                                    <Divider />
                                    <FormGroup>
                                        {g.permissions.map((p: any, pi: any) => {
                                            return (
                                                <FormControlLabel
                                                    control={<Checkbox onChange={(e) => handleCheck(e, p.id)} color="primary" checked={isCheck(p.id) ? true : false} name={p.displayName} />}
                                                    label={p.displayName}
                                                />
                                            )
                                        })}
                                    </FormGroup>
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>

                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item sm={2}>
                        <Button className={classes.button} type="submit" fullWidth variant="contained" color="primary">Salvar</Button>
                    </Grid>
                    <Grid item sm={2}>
                        <Button className={classes.button} onClick={() => setMode('view')} fullWidth variant="contained" color="secondary">Voltar</Button>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export default CreateUpdateUserGroup