import { useState, useContext } from 'react'
import {
  Box,
  Button,
  CssBaseline,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  Checkbox,
  Link,
} from '@material-ui/core'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { postPhysicalPerson } from '../../services/'
import { Copyright, HeaderImage } from '../../../Login'
import Logo from '../../../../assets/images/logo-rectangle.png'
import useStyles from '../../style'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import {
  handleRecaptcha,
  RECAPTCHA_ACTIONS,
} from '../../../../utils/reCaptcha'

import { useMediaQuery } from 'react-responsive'
import { Context } from '../../../../providers/AuthProvider/contexts/AuthContext'
import { AxiosResponse } from 'axios'

interface ICreatePasswordFirstAccessProps {
  typeLoginPf: number
  dnv: string
  cellPhone: string
  medicalRecordNumber: string
}

const CreatePasswordFirstAccess = ({
  typeLoginPf,
  dnv,
  cellPhone,
  medicalRecordNumber,
}: Partial<ICreatePasswordFirstAccessProps>) => {
  const { authenticatedUser } = useContext(Context)
  const classes = useStyles()
  const [visibilityPassword, setVisibilityPassword] = useState(false)
  const [visibilityConfPassword, setVisibilityConfPassword] = useState(false)

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 598px)' })

  const formik = useFormik({
    initialValues: {
      email: '',
      confirmEmail: '',
      password: '',
      confirmPassword: '',
      termsAccept: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('E-mail no formato inválido.')
        .required('Obrigatório'),
      confirmEmail: Yup.string()
        .email('E-mail no formato inválido.')
        .oneOf(
          [Yup.ref('email'), null],
          'O e-mail deve ser igual ao informado acima.'
        )
        .required('Obrigatório'),
      password: Yup.string()
        .min(6, 'A senha deve possuir 6 ou mais caracteres')
        .required('Obrigatório'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais.')
        .required('Obrigatório'),
      termsAccept: Yup.boolean().oneOf([true]),
    }),
    onSubmit: (values) => {
      
      let physicalPerson = {}

      if (typeLoginPf === 0) {
        physicalPerson = {
          email: values.email,
          password: values.password,
          typeLoginPf: typeLoginPf,
          dnv: dnv,
        }
      }
      if (typeLoginPf === 1) {
        physicalPerson = {
          email: values.email,
          password: values.password,
          typeLoginPf: typeLoginPf,
          cellPhone: cellPhone,
        }
      }
      if (typeLoginPf === 2) {
        physicalPerson = {
          email: values.email,
          password: values.password,
          typeLoginPf: typeLoginPf,
          medicalRecordNumber: medicalRecordNumber,
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const physicalP = postPhysicalPerson(physicalPerson).then(
        (resp: AxiosResponse) => {
          if (authenticatedUser) {
            authenticatedUser(resp)
          }
        }
      )

      handleRecaptcha(RECAPTCHA_ACTIONS.SUBMIT_FORM_RESET)
  
    },
  })

  return (
    <>
      {isTabletOrMobile && (
        <Grid container>
          <img
            src={Logo}
            alt='Logo mãe com bebê'
            className={classes.imageMobile}
          />
        </Grid>
      )}

      <Grid container className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} md={5} sm={5} className={classes.image} />

        <Grid item xs={12} md={7} sm={7} component={Paper} square>
          <div className={classes.paper} hidden={true}>
            <form onSubmit={formik.handleSubmit}>
              <HeaderImage />

              <Box>
                <Typography
                  variant='h4'
                  style={{ fontWeight: 'bolder' }}
                  color='textPrimary'
                >
                  Primeiro Acesso
                </Typography>
                <Typography
                  variant='inherit'
                  style={{ fontSize: '18px' }}
                  color='textSecondary'
                >
                  Vimos que você está realizando o seu primeiro acesso.
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography
                  variant='inherit'
                  style={{ fontSize: '18px' }}
                  color='textSecondary'
                >
                  Precisamos cadastrar seu e-mail e senha no sistema.
                </Typography>
              </Box>
              <Box mb={1}>
                <label
                  style={{
                    fontSize: '15px',
                    fontWeight: 'bold',
                    color: '#5f5f5f',
                  }}
                >
                  E-mail
                </label>
                <TextField
                  size='small'
                  variant='outlined'
                  margin='none'
                  required
                  fullWidth
                  placeholder='Email'
                  type='text'
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  {...formik.getFieldProps('email')}
                  onKeyUp={(e) => {
                    const target = e.target as HTMLInputElement;
                    formik.setFieldValue('email', target.value.toLowerCase());
                  }}
                  onChange={(e) => {
                    const target = e.target as HTMLInputElement;
                    formik.setFieldValue('email', target.value.toLowerCase());
                  }}
                />
              </Box>
              <Box mb={1}>
                <label
                  style={{
                    fontSize: '15px',
                    fontWeight: 'bold',
                    color: '#5f5f5f',
                  }}
                >
                  Confirmar E-mail
                </label>
                <TextField
                  size='small'
                  variant='outlined'
                  margin='none'
                  required
                  fullWidth
                  placeholder='Confirmar E-mail'
                  type='text'
                  error={
                    formik.touched.confirmEmail &&
                    Boolean(formik.errors.confirmEmail)
                  }
                  helperText={
                    formik.touched.confirmEmail && formik.errors.confirmEmail
                  }
                  {...formik.getFieldProps('confirmEmail')}
                  onKeyUp={(e) => {
                    const target = e.target as HTMLInputElement;
                    formik.setFieldValue('confirmEmail', target.value.toLowerCase());
                  }}
                  onChange={(e) => {
                    const target = e.target as HTMLInputElement;
                    formik.setFieldValue('confirmEmail', target.value.toLowerCase());
                  }}
                />
              </Box>
              <Box mb={1}>
                <label
                  style={{
                    fontSize: '15px',
                    fontWeight: 'bold',
                    color: '#5f5f5f',
                  }}
                >
                  Senha
                </label>
                <TextField
                  size='small'
                  variant='outlined'
                  margin='none'
                  required
                  fullWidth
                  placeholder='Senha'
                  type={visibilityPassword ? 'text' : 'password'}
                  id='password'
                  autoComplete='current-password'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={() =>
                            setVisibilityPassword(!visibilityPassword)
                          }
                        >
                          {visibilityPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  {...formik.getFieldProps('password')}
                />
              </Box>
              <Box mb={1}>
                <label
                  style={{
                    fontSize: '15px',
                    fontWeight: 'bold',
                    color: '#5f5f5f',
                  }}
                >
                  Confirmar Senha
                </label>
                <TextField
                  size='small'
                  variant='outlined'
                  margin='none'
                  required
                  fullWidth
                  placeholder='Confirmar Senha'
                  type={visibilityConfPassword ? 'text' : 'password'}
                  id='confirmPassword'
                  autoComplete='current-password'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={() =>
                            setVisibilityConfPassword(!visibilityConfPassword)
                          }
                        >
                          {visibilityConfPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                  {...formik.getFieldProps('confirmPassword')}
                />
              </Box>

              <Box mb={1}>
                <Checkbox
                  size='medium'
                  {...formik.getFieldProps('termsAccept')}
                  checked={formik.values.termsAccept}
                  id='termsAccept'
                  color='primary'
                />

                <label htmlFor='termsAccept' className={classes.label}>
                  Li e concordo com os {` `}
                  <Link
                    href={process.env.REACT_APP_URL_TERMS}
                    target='_blank'
                    className={classes.textLink}
                  >
                    termos de uso
                  </Link>
                  .
                </label>

                {formik.touched.termsAccept &&
                  Boolean(formik.errors.termsAccept) && (
                    <p className='MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required MuiFormHelperText-marginDense'>
                      Você precisa aceitar os termos
                    </p>
                  )}
              </Box>

              <Button
                disabled={!(formik.isValid && formik.dirty)}
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
              >
                Cadastrar
              </Button>
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default CreatePasswordFirstAccess
