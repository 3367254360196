import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    "&.MuiSelect-root":{
        "&.MuiSelect-select.Mui-disabled":{
            border: 'solid red'
        }
    },
    inputLabel: {
        fontSize: '15px',
        fontWeight: 'bold',
        color: '#5f5f5f',
    },
    button: {
        textTransform: 'none',
        height: '50px',
        fontSize: '17px',
    },
    dropZoneBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: '4px',
      textAlign: 'center',
      height: '132px',
      color: '#fff'
    },
    dropZoneArea: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '110px'
    },
      dropZoneBgUnUploaded: {
        backgroundColor: theme.palette.grey[300],
      },
      dropZoneBgUploaded: {
        backgroundColor: '#047827'
      },
      icon: {
        fontSize: '40px',
        marginBottom: '10px'
      }
}))