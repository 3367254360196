import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    rootAppBar: {
        padding: '12px',

        '& .rc-menu__item--active': {
            backgroundColor: '#f2c7d6'
        },

        '& .rc-menu__item--hover': {
            backgroundColor: '#f2c7d6'
        },  
        
        '& .grecaptcha-badge': {
            visibility: 'hidden; !important'
        }
    },

    listMobileMenu: {
        width: 200,
        maxWidth: 360,
    },

    nestedListMenu:{
        paddingLeft: theme.spacing(4),
    },

    activeMenu:{      
        backgroundColor: '#efefef'
    },

    activeTextMenu:{
        fontWeight:'bold',
    },    

}))