import { Dialog, DialogContent, Typography, IconButton  } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { useMediaQuery } from 'react-responsive';

import showDnv from '../../../../assets/images/show-dnv.jpg'
import useStyles from './style';


const DialogHelpDV = ({handleClose, open}:{handleClose:any, open:boolean}) => {    
    const classes = useStyles()
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 598px)' })

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={'lg'} fullScreen={isTabletOrMobile ?? false} >
            <MuiDialogTitle disableTypography>
                <Typography variant="h6">Onde encontrar o numero da DNV.</Typography>                
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>               
            </MuiDialogTitle>
            <DialogContent dividers>
                <img alt="Numero da DNV. Declaração de Nascido Vivo" src={showDnv} width={'100%'}/>
            </DialogContent>           
        </Dialog>
    )
}

export default DialogHelpDV