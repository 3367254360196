import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({

  mainBox : {
      minWidth:400,
      minHeight:200,
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    paddingBottom: 0
  },
  buttonsBox : {
    display:'flex' , 
    justifyContent:'space-around' , 
    margin:'0 7px 7px 7px'
  },
  button: {
    textTransform: 'none',
    fontSize: '15px',
    /* width: 100,
    height: 50 */
  }
}))