import React from "react";

import "./spinner.css";
import LogoMin from '../../../assets/images/logo-min-ijc.png'

const Spinner = () => {      
    return (
        <>
            <div className="loader"></div>
            <img alt='logo ijc' src={LogoMin} className="imageLogo" />           
        </>

    )
}

export default Spinner