import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    typographyStyle: {
        fontSize: `0.9rem`,
        fontWeight: `bold`,
        marginBottom: `10px`
    },
    btnApplyFilter : {
        justifyContent: 'center'
    },
    formSection: {
        padding: '10px'
    },
    closeButton: {
        margin: 'unset',
        float: 'right'
    }

}))
