import { Dialog, DialogContent, Typography, IconButton } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import { useMediaQuery } from 'react-responsive'
import useStyles from './DialogHelpDV/style'

const DialogFirstAccess = ({ handleClose, open }: { handleClose: any, open: boolean }) => {
    const classes = useStyles()
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 598px)' })

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={'lg'} fullScreen={isTabletOrMobile ?? false} >
            <MuiDialogTitle disableTypography>
                <Typography variant="h6">Primeiro acesso</Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <DialogContent dividers>
                <Typography variant="body2">

                    <p><b>Para o seu primeiro acesso você deve seguir os seguintes passos:</b></p> <br />

                    <p>1. Informe o DNV, Celular ou Número de Prontuário.</p>
                    <p>2. No campo Senha informe a data de nascimento (apenas números), por exemplo:
                        Data Nascimento: 01/01/2021 Senha: 01012021.</p>
                    <p>3. Cadastre seu e-mail e senha definitiva para continuar.</p><br />

                    <p><b>Próximos acessos:</b></p> <br />

                    <p>1. Informe o DNV, Celular ou Número de Prontuário.</p>
                    <p>2. Informe no campo Senha a senha cadastrada em seu primeiro acesso.</p>
                    <p>3. Caso tenha esquecido a senha basta clicar no link "Esqueceu a senha?". Neste caso, será enviado para o
                        e-mail cadastrado no primeiro acesso um link para cadastro de nova senha.</p><br/>

                    <p><b>Nota: A senha deve possuir 6 caracteres, isto é, letras, números ou caracteres especiais.</b></p>
                </Typography>
            </DialogContent>
        </Dialog>
    )
}

export default DialogFirstAccess