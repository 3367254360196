import { useEffect, useState, useCallback, useContext, useMemo } from 'react';
import { Box, Grid } from '@material-ui/core';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import { useMediaQuery } from 'react-responsive';
import { Context } from '../../providers/AuthProvider/contexts/AuthContext';
import { getReports } from '../../modules/Reports/service';
import './style.css';

type Column = {
  name: string | undefined;
  label: string | undefined;
  alignCenter?: boolean;
  width?: string | undefined;
  minWidth?: string | undefined;
  customRender?: (index: any) => void;
  sortable?: boolean;
};

type propsValues = {
  columns: Array<Column>;
  data: Array<any>;
  totalPages: number;
  changePage: (value: number) => void;
  reportIdItemChanged?: {
    page: number;
    changed: boolean;
    reportId?: string | number;
    filters?: any;
    searching?: boolean;
  } | undefined;
};

const DataGrid = (values: propsValues) => {
  const { columns, data, totalPages, changePage, reportIdItemChanged } = values;
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 599px)' });
  const [reportIdDataCurrent, setReportIdDataCurrent] = useState(data);
  const [changedReportActive, setChangedReportActive] = useState(false);
  const [sortColumn, setSortColumn] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const { reports: { setReportIdData }, user } = useContext(Context);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    changePage(value);
    setChangedReportActive(false);
    setReportIdData([]);
  };

  const getChangeReport = useCallback(async (page: number) => {
    const { reportPageResponse: { content } } = await getReports(reportIdItemChanged?.filters, page);

    if (content) {
      const reportIdNewList = content?.map((item: { reportId: number }) => {
        if (item?.reportId === reportIdItemChanged?.reportId)
          return { ...item };

        return item;
      });

      setReportIdDataCurrent(reportIdNewList);
      setReportIdData(content);
      setChangedReportActive(true);
    }
  }, [reportIdItemChanged?.filters, reportIdItemChanged?.reportId, setReportIdData]);

  useEffect(() => {
    setChangedReportActive(false);
    setReportIdData([]);
  }, []);

  useEffect(() => {
    if (reportIdItemChanged?.searching) {
      changePage(1);
      setChangedReportActive(false);
      setReportIdData([]);
      setSortColumn(undefined); // Resetando a ordenação
      setSortDirection('asc');  // Resetando a direção da ordenação
    }
  }, [reportIdItemChanged?.searching]);

  useEffect(() => {
    if (!changedReportActive)
      setReportIdDataCurrent(data);
  }, [changedReportActive, data]);

  useEffect(() => {
    if (reportIdItemChanged !== undefined && reportIdItemChanged?.changed && reportIdItemChanged?.reportId)
      getChangeReport(reportIdItemChanged?.page);
  }, [reportIdItemChanged?.changed]);

  const handleSort = (columnName: string | undefined) => {
    if (columnName === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(columnName);
      setSortDirection('asc');
    }
  };

  const getColumnValue = (row: any, columnName: string | undefined) => {
    if (columnName === 'batch') {
      const batchParts = row.batch ? row.batch.split('/') : [''];
      return batchParts[1] || '';
    }
    return row[columnName];
  };

  const sortedData = useMemo(() => {
    if (!sortColumn) return reportIdDataCurrent;

    return [...reportIdDataCurrent].sort((a, b) => {
      let aValue = getColumnValue(a, sortColumn);
      let bValue = getColumnValue(b, sortColumn);

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }

      if (aValue === bValue) return 0;
      if (sortDirection === 'asc') {
        return aValue < bValue ? -1 : 1;
      } else {
        return aValue > bValue ? -1 : 1;
      }
    });
  }, [reportIdDataCurrent, sortColumn, sortDirection]);

  return (
    <Grid container>
      <Grid item xs={12}>
        {sortedData?.length >= 1 ? (
          <>
            <div className="content">
              <table className="table" cellSpacing="0">
                <tbody>
                  {sortedData?.map((row: any, index: any) =>
                    <tr
                      className={`tableItems ${row?.reportViewed === 'S' ? 'report-viewed-row' : ''}`}
                      key={index}
                      style={{ display: `${user?.typeUser === 1 && row?.status === 'Alterado' ? 'none' : ''}` }}
                    >
                      {columns?.map((column, indexColumn: any) =>
                        <td
                          className={
                            `fields
                            ${row?.reportViewed === 'N' ? `report-not-view-field ${column.name === 'status' ? 'report-not-view-field-status' : ''}` : ''}
                            ${row?.reportViewed === 'S' ? `report-viewed-field ${column?.name === 'status' ? 'report-viewed-field-status' : ''}` : ''}
                          `}
                          key={indexColumn}
                          style={{
                            position: 'relative',
                            textAlign: column?.alignCenter ? 'center' : 'left',
                            width: column?.width && !isTabletOrMobile ? column?.width : '',
                            minWidth: column?.minWidth && !isTabletOrMobile ? column?.minWidth : '',
                          }}>
                          {index === 0 && column?.sortable ? (
                            <span style={{ cursor: 'pointer' }} className="label" onClick={() => handleSort(column?.name)}>
                              {column?.label}
                              {sortColumn === column?.name && (
                                sortDirection === 'asc' ? <ArrowUpward style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '-2px', fontSize: '14px' }} /> : <ArrowDownward style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '-2px', fontSize: '14px' }} />
                              )}
                              {sortColumn != column?.name && (
                                <>
                                  <ArrowUpward style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '-2px', fontSize: '14px' }} />
                                  <ArrowDownward style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '-13px', fontSize: '14px' }} />
                                </>
                              )}
                            </span>
                          ) : index === 0 ? (
                            <span className="label">{column?.label}</span>
                          ) : null}
                          {column?.customRender ?
                            column?.customRender(index)
                            :
                            <span className="value">{getColumnValue(row, column.name)}</span>
                          }
                        </td>
                      )}
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {totalPages >= 2 && (
              <Grid container justifyContent="center">
                <Pagination
                  count={totalPages}
                  onChange={handleChange}
                  defaultPage={1}
                  page={reportIdItemChanged && reportIdItemChanged?.page}
                  style={{ justifyContent: 'center' }}
                />
              </Grid>
            )}
          </>
        ) : (
          <Grid container justifyContent="center">
            <Box mt={2}>
              Nenhum registro encontrado
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default DataGrid;
