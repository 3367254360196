import { createContext, useState, useEffect } from 'react';
import { configInterceptApi, confiHeaderTokenApi } from '../../../utils/api';
import {
  iUserAuthContext,
  iUser,
  iAuth,iUserPFRequestAuth,
  iUserIJCRequestAuth,
  iLoginRequest,
  iUserPJRequestAuth,
  iUserResetNewPass,
  iUserNewPass,
  typeUser,
  iReports,
} from '../interfaces'
import history from '../../../routes/history';
import { loginIjc, loginPF, loginPJ, newPassResetCode, resetPassIjc, resetPassPj } from '../services';
import {AxiosError, AxiosResponse} from 'axios'


const Context = createContext<iUserAuthContext>({} as iUserAuthContext);
const AuthProvider = ({ children }: { children: any }) => {
    const [authenticated, setAuthenticated] = useState(false)
    const [user, setuser] = useState<iUser>()
    const [reportIdData, setReportIdData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        configInterceptApi(setIsLoading)
        setAuthenticated(checkIsAuthStore());


        //TO-DO - Melhorar essa logica para retornar as informações do usuário
        const userStore: any = localStorage.getItem('user')
        var userParsed: iUser = JSON.parse(userStore)


        setuser(userParsed)
    }, [])

    const authenticatedUser = async(resp : AxiosResponse) => {
        localStorage.setItem('user', JSON.stringify(resp.data))
        confiHeaderTokenApi(resp.data.token)
        setuser(resp.data)
        setAuthenticated(true)

        if(resp.data.permissions.includes("Laudo_ListagemVisualizacao")){
            history.push('/laudo')
        }
        else{
            if(resp.data.menus[0].hasChildren){
                let firstPath = resp.data.menus[0].children[0].path
                history.push(firstPath)
            }else{
                let firstPath = resp.data.menus[0].path
                history.push(firstPath)
            }
        }
    }


    const checkIsAuthStore = () => {

        const userStore: any = localStorage.getItem('user');

        if (userStore) {

            var userParsed: iUser = JSON.parse(userStore)

            confiHeaderTokenApi(userParsed.token)
            return true;
        }

        return false;
    }

    const handleLogin = async (loginRequest: iLoginRequest , action : string) => {
         window.grecaptcha.ready(() => {
            window.grecaptcha
                .execute(process.env.REACT_APP_RECAPTCHA_PK, { action: action })
                .then(async (token: any) => {
                   //TO-DO - Validar Recaptcha no backend e tratar aqui quando ouver.


                   if (loginRequest.loginType === '0') {

                    const request: iUserPJRequestAuth = {
                        email: loginRequest.email,
                        password: loginRequest.password,
                        cnes: loginRequest.cnes,
                        typeUser: 2
                    }

                    await loginPJ(request, token).then(resp =>{


                        authenticatedUser(resp)
                    })
                }

                if (loginRequest.loginType === '1') {

                    const request: iUserIJCRequestAuth = {
                        email: loginRequest.email,
                        password: loginRequest.password,
                        typeUser: 0
                    }

                    await loginIjc(request, token).then((resp: AxiosResponse) =>{

                        authenticatedUser(resp)
                    })
                }


                // Logar usuário Cliente
                if (loginRequest.loginType === '2') {

                    const request: Partial<iUserPFRequestAuth> = {
                        email: loginRequest.email,
                        password: loginRequest.password,
                        typeUser: loginRequest.typeUser,
                        dnv : loginRequest.dnv,
                        medicalRecordNumber : loginRequest.medicalRecordNumber,
                        typeLoginPf: loginRequest.typeLoginPf,
                        cellPhone : loginRequest.cellPhone
                    }

                    await loginPF(request,token).then(resp =>{

                        authenticatedUser(resp)

                    })
                }
                })
                 .catch((error : AxiosError) => {

                    console.log(error)
                    /* setPhysicalPersonErrors(error ?? null) */
                })
        })


    }

    const handleLogoff = async () => {
        localStorage.removeItem('user');
        confiHeaderTokenApi(undefined)
        setAuthenticated(false)
        history.push('/login/clientes')
    }

    const handleResetPassword = async (userResetNewPass: iUserResetNewPass , action : string) => {
        //Verificar funcionalidade depois que alterado
        let ret: boolean = false;

            await window.grecaptcha
                .execute(process.env.REACT_APP_RECAPTCHA_PK, { action: action })
                .then(async (token: any) => {

                   if (userResetNewPass.loginType === '0') {

                       userResetNewPass.typeUser = typeUser['pj']

                       ret = await resetPassPj(userResetNewPass , token).then(() => {
                           return true
                       }).catch((err) => {
                           return false
                       })
                   }

                   if (userResetNewPass.loginType === '1') {
                       userResetNewPass.typeUser = typeUser['ijc']

                       ret = await resetPassIjc(userResetNewPass, token).then(() => {
                            return true
                       }).catch((err) => {
                            return false
                       })
                   }
                   if (userResetNewPass.loginType === '2') {
                       userResetNewPass.typeUser = typeUser['pf']

                       ret = await resetPassIjc(userResetNewPass, token).then(() => {
                           return true
                       }).catch((err) => {
                           return false
                       })
                   }

                })
                .catch((error : Error) => {
                    console.log(error)
                })
        return ret;

    }

    const handleNewPassword = async (userNewPass: iUserNewPass, action : string) => {
        let ret: boolean = false;

       await new Promise(function(resolve, reject){
            window.grecaptcha.ready(() => {
                window.grecaptcha
                    .execute(process.env.REACT_APP_RECAPTCHA_PK, { action: action })
                    .then(async (token: any) => {
                        await newPassResetCode(userNewPass, token).then(() => {
                            ret = true
                            return resolve(ret)
                        }).catch((err) => {
                            ret = false
                            return reject(ret)
                        })
                    })
                    .catch((error : Error) => {
                        reject(ret)
                        console.log(error)
                    })
                })
            })
    }



    const auth: iAuth = {
        authenticated: authenticated,
        isLoading: isLoading,
        handleLogin: handleLogin,
        handleLogoff: handleLogoff,
        handleResetPassword: handleResetPassword,
        handleNewPassword: handleNewPassword,
        checkIsAuthStore: checkIsAuthStore
    }

    const reports: iReports = {
      reportIdData,
      setReportIdData,
    }

    const contextoUser: iUserAuthContext = {
      auth,
      user,
      authenticatedUser,
      reports,
    }

    return (
        <Context.Provider value={contextoUser}>
            {children}
        </Context.Provider>
    )
}

export { Context, AuthProvider }
