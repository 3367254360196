import { useEffect, useState } from "react";
import { useContext } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, CssBaseline, FormControlLabel, Grid, Link, Paper, RadioGroup, TextField, Typography } from "@material-ui/core";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ApartmentIcon from '@material-ui/icons/Apartment';
import { useParams } from "react-router-dom"
import history from '../../routes/history';
import useStyles from './style';
import { Context } from "../../providers/AuthProvider/contexts/AuthContext";
import { HeaderImage, ImageLeft, ImageTopMobile, StyledRadio } from "../Login";
import { useMediaQuery } from "react-responsive";
import { handleRecaptcha, RECAPTCHA_ACTIONS } from "../../utils/reCaptcha";


type IRouteParams = {
    typeUser:string;
}


const Copyright = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.ijc.org.br/">
                Instituto Jô Clemente
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const ResetPassword = () => {
    const { auth } = useContext(Context);
    const classes = useStyles();
    const [isSendResetPass, setIsSendResetPass] = useState(false);
    const [isSuccessResetPass, setIsSuccessResetPass] = useState(false);

    const [renderResetPass, setRenderResetPass] = useState(false);
    const { resetPasswordCode  } = useParams<any>();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 598px)' })
    const { typeUser} = useParams<IRouteParams>()
    useEffect(() => {
        handleRecaptcha(RECAPTCHA_ACTIONS.LOGIN)

        if (resetPasswordCode) {
            setRenderResetPass(true)
        }

    }, [resetPasswordCode])


    const formik = useFormik({
        initialValues: {
            email: '',
            cnes: '',
            loginType: typeUser ?? '0'
        },
        validationSchema: Yup.object({
            email: Yup.string().email('E-mail no formato inválido.').required('Obrigatório'),
            cnes: Yup.string().when('loginType', {
                is: '0',
                then: Yup.string().required('Obrigatório')
            }),
            loginType: Yup.string().required('Obrigatório')
        }),
        onSubmit: values => {
            auth?.handleResetPassword(values, RECAPTCHA_ACTIONS.RESET_PASSWORD).then((ret: boolean) => {
                setIsSendResetPass(true)
                setIsSuccessResetPass(ret)
            })
        }
    })

    const InformationSendResetPass = () => {
        return (
            <Box m={5} >
                {isSuccessResetPass
                    ? <Typography variant='h6' >Enviamos um e-mail para você com o link para redefinição da senha.</Typography>

                    : <><Typography variant='h6' >O e-mail ou o CNES informado não foi identificado.</Typography>
                        <Button fullWidth
                            onClick={() => setIsSendResetPass(false)}
                            variant="text" color="secondary"
                            className={classes.submit}> Tentar novamente </Button></>}
            </Box>
        )
    }

    const InformationFormResetPass = () => {
        return (
            <Box m={5} >
                {isSuccessResetForm
                    ? <Typography variant='h6' >Senha alterada com sucesso.</Typography>

                    : <><Typography variant='h6' >Não foi possível criar a nova senha.</Typography>
                        <Button fullWidth
                            onClick={() => { setRenderResetPass(false); history.push('/nova-senha') }} //replace('/nova-senha')}
                            variant="text" color="secondary"
                            className={classes.submit}> Tentar novamente </Button></>}
            </Box>
        )
    }

    const [isSendResetForm, setIsSendResetForm] = useState(false);
    const [isSuccessResetForm, setIsSuccessResetForm] = useState(false);


    const FormResetPassWord = () => {
        const formikRestPass = useFormik({
            initialValues: {
                password :'',
                confirmPassword: '',
                resetPasswordCode: resetPasswordCode
            },
            validationSchema: Yup.object({
                password: Yup.string().min(6, 'A senha deve possuir 6 ou mais caracteres'),
                confirmPassword: Yup.string()
                    .oneOf([Yup.ref('password')], 'Confirmação incorreta')

            }),
            onSubmit: async values => {
               await auth?.handleNewPassword({
                    resetPasswordCode: values.resetPasswordCode,
                    newPassword: values.password
                }, RECAPTCHA_ACTIONS.RESET_PASSWORD_CONFIRMATION).then((ret: boolean) => {
                     setIsSuccessResetForm(true)
                     setIsSendResetForm(true)
                }).catch(() => {
                    setIsSuccessResetForm(false)
                    setIsSendResetForm(true)
                })
            }
        })

        return (
            <>{isSendResetForm ?
                <InformationFormResetPass /> :
                <form onSubmit={formikRestPass.handleSubmit} className={classes.form}>
                    <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Nova Senha</Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                margin="none"
                                type='password'
                                /* type={visibilityPassword ? 'text' : 'password'} */
                                required
                                fullWidth
                                id="password"
                                /* placeholder="Senha Atual" */
                                /* InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                onClick={() => setVisibilityPassword(!visibilityPassword)}
                                            >
                                                {visibilityPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }} */
                                error={formikRestPass.touched.password && Boolean(formikRestPass.errors.password)}
                                helperText={formikRestPass.touched.password && formikRestPass.errors.password}
                                {...formikRestPass.getFieldProps('password')}
                            />
                    <Box mt={1} mb={2}>
                        <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Confirmar Senha</Typography>
                        <TextField
                            size="small"
                            variant="outlined"
                            margin="none"
                            type='password'
                            /* type={visibilityNewPassword ? 'text' : 'password'} */
                            required
                            fullWidth
                            id="confirmPassword"
                            /* placeholder="Confirmar Senha" */
                            /* InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton
                                            aria-label='toggle password visibility'
                                            onClick={() => setVisibilityNewPassword(!visibilityNewPassword)}
                                        >
                                            {visibilityNewPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }} */
                            error={formikRestPass.touched.confirmPassword && Boolean(formikRestPass.errors.confirmPassword)}
                            helperText={formikRestPass.touched.confirmPassword && formikRestPass.errors.confirmPassword}
                            {...formikRestPass.getFieldProps('confirmPassword')}
                        />
                    </Box>
                    <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                        Enviar
                    </Button>
                </form>
            }</>
        )
    }

    return (
        <>

            <Grid container component="main" className={classes.root}>
            <CssBaseline />
            {isTabletOrMobile && <ImageTopMobile />}
            {!isTabletOrMobile && <ImageLeft />}

            <Grid item xs={12} md={7} component={Paper} square >
                <div className={classes.paper}>
                    <Link
                      style={{ width: '100%', padding: '10px', fontWeight:'bold' }}
                      href="#"
                      variant="body2"
                      onClick={() => history.push('/login/hospitais')}
                    >
                      Voltar
                    </Link>

                    <div className={classes.form}>
                        <HeaderImage />
                    </div>

                    {isSendResetPass
                        ?
                        <InformationSendResetPass />
                        :
                        renderResetPass
                            ?
                            <FormResetPassWord />
                            :
                            <form onSubmit={formik.handleSubmit} className={classes.form}>

                                <Box mb={2}>
                                    <Typography variant="h4" style={{ fontWeight: 'bolder' }} color="textPrimary">
                                        Recuperação de senha
                                    </Typography>
                                    <Typography variant="inherit" style={{ fontSize: '18px' }} color="textSecondary">
                                        Enviaremos um e-mail com o link para a redefinição da senha.
                                    </Typography>
                                </Box>
                                <Box display="flex" mt={4} mb={4}>
                                    <RadioGroup row aria-label="position" name="loginType" onChange={formik.handleChange} value={formik.values.loginType}>
                                        <Paper className={classes.paperRadio}>
                                            <Box flexWrap="nowrap" textAlign="center">
                                                <ApartmentIcon color="primary" fontSize="large" />
                                                <Typography variant="subtitle2" style={{ fontSize: '18px' }} color="textSecondary">
                                                    Instituição
                                                </Typography>
                                                <FormControlLabel value="0" label="" labelPlacement="top" control={<StyledRadio />} />
                                            </Box>
                                        </Paper>
                                        <Paper className={classes.paperRadio}>
                                            <Box flexWrap="nowrap" textAlign="center">
                                                <AssignmentIndIcon color="primary" fontSize="large" />
                                                <Typography variant="subtitle2" style={{ fontSize: '18px' }} color="textSecondary">
                                                    Interno
                                                </Typography>
                                                <FormControlLabel value="1" label="" labelPlacement="top" control={<StyledRadio />} />
                                            </Box>
                                        </Paper>
                                    </RadioGroup>
                                </Box>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                    {...formik.getFieldProps('email')}
                                />
                                {formik.values.loginType === '0' &&
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="cnes"
                                        label="CNES"
                                        error={formik.touched.cnes && Boolean(formik.errors.cnes)}
                                        helperText={formik.touched.cnes && formik.errors.cnes}
                                        {...formik.getFieldProps('cnes')}
                                    />}
                                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                                    Enviar
                                </Button>
                            </form>}
                    <Box mt={5}>
                        <Copyright />
                    </Box>
                </div>
            </Grid>
        </Grid>

        </>
    );
}

export default ResetPassword
