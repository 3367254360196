import api from "../../../utils/api"
import { API_ROUTE } from "../constants"
import { iGroupPost, iGroupPut } from "../interfaces"

export const getUserGroup = async (pageNumber?:any, pageSize?:any, name?:any, status?:any) => {
    
    let queryParam = ``    

    if(name){
         queryParam = queryParam + `&Name=${name}`
    }

    if(status){
        
        if(status !== 'all'){
            queryParam = queryParam + `&Active=${status}`
        }
        
    }

    if(pageNumber){
        queryParam = queryParam + `&PageNumber=${pageNumber}`
    }

     if(pageSize){
        queryParam = queryParam + `&PageSize=${pageSize}`
    }
    
    return await api.get(`${API_ROUTE.USER_GROUP}?${queryParam}`)
}

export const getUsergroupById = async (groupId:any)=>{
    return await api.get(`${API_ROUTE.USER_GROUP}/${groupId}`)
}

export const getPermissions = async () =>{
    return await api.get(`${API_ROUTE.GET_PERMISSIONS}`)
}

export const postGroup = async (groupRequest: iGroupPost) =>{
    return await api.post(`${API_ROUTE.USER_GROUP}`, groupRequest)
}

export const putGroup = async (groupRequest: iGroupPut) =>{
    return await api.put(`${API_ROUTE.USER_GROUP}`, groupRequest)
}

export const deleteGroup = async (groupId:any) =>{
    return await api.delete(`${API_ROUTE.USER_GROUP}/${groupId}`)
}