import React, { useEffect, useState } from "react";
import { Grid, Typography, TextField, Box, InputAdornment, Fab, Button } from "@material-ui/core"
import { Edit, Add, Search, Close, HistoryOutlined } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import { getMaterialRequest } from "../service";
import ShowHasPermission from "../../../utils/showHasPermission";
import ModalSearchInstitutions, { CbInstituteProps } from "../../../components/ModalSearchInstitutions";
import LogMaterialRequestConfig from "./LogMaterialRequestConfig";

type materialConfigurationProps = {
  setMode: React.Dispatch<React.SetStateAction<string>>
  idMaterialConfiguration: number | undefined;
  setIdMaterialConfiguration: React.Dispatch<React.SetStateAction<number | undefined>>
}

const ViewMaterialRequestConfiguration = (props: materialConfigurationProps) => {
  type SelectableRows = 'multiple' | 'single' | 'none';
  type ResponsiveType = 'vertical' | 'standard' | 'simple';

  const initialState = {
    tasyCode: 0,
    cnes: '',
    corporateName: '',
    cnpj: '',
    cityState: '',
    webAccess: ''
  }

  const { setMode, idMaterialConfiguration, setIdMaterialConfiguration } = props
  const [open, setOpen] = useState(false);
  const [openLog, setOpenLog] = useState(false);
  const [cbInstitute, setCbInstitute] = useState<CbInstituteProps>(initialState)
  const [data, setData] = useState<any>([]);
  const [totalRows, setTotalRows] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [page, setPage] = useState(0)

  useEffect(() => {
    getMaterialRequest().then((resp: any) => {
      setData(resp.content)
      setTotalRows(resp.totalRecords)
      setRowsPerPage(resp.pageable.pageSize)
    })
  }, [])

  const columns = [
    {
      name: "instituteCnes",
      label: "CNES",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "materialRequestTypeName",
      label: "Tipo Material",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "automaticRequest",
      label: "Solicitação Automática",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (index: any) => {
          return (
            data[index].automaticRequest === true ? 'Sim' : 'Não'
          );
        }
      }
    },
    {
      name: "minInventoryBalance",
      label: "Saldo Minímo",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "automaticRequestAmount",
      label: "Qtd. Solicitar",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "inventoryBalanceAmount",
      label: "Saldo Atual",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "actions",
      label: "Ações",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ align: "center", }),
        setCellHeaderProps: () => ({ align: "center", }),
        customBodyRenderLite: (index: any) => {
          return (
            <Grid container>
              <Grid item xs={12} md={6} >
                <Button color="primary" onClick={() => updateConfiguration(data[index].id)} disabled={!ShowHasPermission('SolicitacaoMaterialConfiguracao_Alteração')}>
                  <Edit titleAccess='Editar' />
                </Button>
              </Grid>

              <Grid item xs={12} md={6} >
                <Button color="primary" onClick={() => openModalLOg(data[index].id)} disabled={!ShowHasPermission('SolicitacaoMaterialConfiguracao_Alteração')}>
                  <HistoryOutlined titleAccess='Histórico' />
                </Button>
              </Grid>
            </Grid>
          );
        }
      }
    },
  ]

  const openModalLOg = (id: number) => {
    setIdMaterialConfiguration(id)
    setOpenLog(true)
  }

  const options = {
    search: false,
    download: false,
    expandableRowsHeader: false,
    filter: false,
    print: false,
    selectableRowsHeader: false,
    viewColumns: false,
    rowsPerPageOptions: [5, 10],
    rowsPerPage: rowsPerPage,
    responsive: 'simple' as ResponsiveType,
    selectableRows: 'none' as SelectableRows,
    page: page,
    textLabels: {
      body: {
        noMatch: "Nenhum registro encontrado.",
      },
      pagination: {
        next: "Próxima Pagina",
        previous: "Pagina Anterior",
        rowsPerPage: "",
        displayRows: "de",
      },
    },
    count: totalRows,
    serverSide: true,
    onTableChange: async (action: any, tableState: any) => {
      switch (action) {
        case 'changePage':
          requestOnChange(tableState.page, tableState.rowsPerPage);
          break;
        case 'changeRowsPerPage':
          requestOnChange(tableState.page, tableState.rowsPerPage);
          break;
        default:
      }
    },
    setTableProps: () => {
      return {
        size: 'small'
      };
    },
  }

  const updateConfiguration = (id: number) => {
    setMode('update')
    setIdMaterialConfiguration(id)
  }

  const requestOnChange = async (page: any, rowsPerPage: any) => {
    let response: any
    cbInstitute.tasyCode === 0 ?
      response = await getMaterialRequest(page + 1, rowsPerPage)
      :
      response = await getMaterialRequest(page + 1, rowsPerPage, cbInstitute.tasyCode)
    setData(response.content)
    setTotalRows(response.totalRecords)
    setRowsPerPage(response.pageable.pageSize)
    setPage(response.pageable.pageNumber - 1)
  }

  const onClose = () => {
    setOpen(false);
  }

  const onCloseLog = () => {
    setOpenLog(false);
    setIdMaterialConfiguration(undefined)
  }

  const handleChangeInstituteIcon = (event: any) => {
    event.stopPropagation();
    setCbInstitute(initialState)
    getMaterialRequest().then((resp: any) => {
      setData(resp.content)
      setTotalRows(resp.totalRecords)
      setRowsPerPage(resp.pageable.pageSize)
    })
  }

  const handleSearchInstitute = async (callback: any) => {
    await changeInstitute(callback)
    await getMaterialRequest(1, 10, callback.tasyCode).then((resp: any) => {
      setData(resp.content)
      setTotalRows(resp.totalRecords)
      setRowsPerPage(resp.pageable.pageSize)
    })
  }

  const changeInstitute = async (callback: any) => {
    setCbInstitute(callback)
  }

  return (
    <>
      <ModalSearchInstitutions open={open} onCallBack={handleSearchInstitute}
        onClose={onClose} />
      <LogMaterialRequestConfig open={openLog} onClose={onCloseLog} materialRequestId={idMaterialConfiguration} />
      <Grid container>
        <Grid item >
          <Typography variant="h5" style={{ fontWeight: 'bolder' }} color="textPrimary">Configuração Solicitação de Materiais</Typography>
        </Grid>
      </Grid>

      <Box pt={'10px'}>
        <Grid container justifyContent="space-between" alignItems="flex-end">
          <Grid item sm={6}>
            <Box mb={2}>
              <Fab title='Adicionar' size="small" color="primary" aria-label="add" onClick={() => setMode('add')} disabled={!ShowHasPermission('SolicitacaoMaterialConfiguracao_Cadastro')}>
                <Add />
              </Fab>
            </Box>
          </Grid>
          <Grid item container sm={6} justifyContent={"flex-end"}>
            <Grid item xs={12} sm={10}>
              <Box mb={2}>
                <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Instituição</Typography>
                <TextField
                  fullWidth
                  inputProps={
                    {
                      className: 'iconCursor',
                    }
                  }
                  value={cbInstitute.corporateName}
                  onClick={() => setOpen(true)}
                  size="small"
                  variant="outlined"
                  margin="none"
                  id="search-user-institute"
                  placeholder="Clique para selecionar a instituição"
                  InputProps={{

                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        {cbInstitute.corporateName !== '' ?
                          <Close className='iconCursor' onClick={handleChangeInstituteIcon} />
                          : <Search className='iconCursor' />}

                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <MUIDataTable
          options={options}
          title={""}
          data={data}
          columns={columns}
        />
      </Box>
    </>
  )
}

export default ViewMaterialRequestConfiguration