import api from "../../utils/api"

export const getMaterialRequest = async (pageNumber?: number, pageSize?: number, tasyCode?: number): Promise<any> => {
  const materialRequest = await api.get('/material-request-configuration', {
    params: {
      institutionTasy: tasyCode,
      pageNumber,
      pageSize
    }
  });

  return materialRequest.data
}

export const getMaterialRequestById = async (idMaterialConfiguration: number): Promise<any> => {
  const materialRequest = await api.get(`/material-request-configuration/${idMaterialConfiguration}`);

  return materialRequest.data
}

export const getMaterialRequestType = async (): Promise<any> => {
  const materialRequestType = await api.get('/material-request-type');

  return materialRequestType.data
}

export const createNewMaterialRequestConfiguration = async (values: any): Promise<void> => {  
  await api.post('/material-request-configuration',
    {
      instituteCnes: values.instituteCnes,
      instituteTasy: values.instituteTasy,
      materialRequestTypeId: values.materialRequestTypeId,
      automaticRequest: values.automaticRequest,
      minInventoryBalance: parseInt(values.minInventoryBalance),
      automaticRequestAmount: parseInt(values.automaticRequestAmount),
      inventoryBalanceAmount: parseInt(values.inventoryBalanceAmount),
    }
  );
}

export const updateMaterialRequestConfiguration = async (values: any): Promise<void> => {
  await api.put(`/material-request-configuration/${values.id}`,
    {
      automaticRequest: values.automaticRequest,
      minInventoryBalance: parseInt(values.minInventoryBalance),
      automaticRequestAmount: parseInt(values.automaticRequestAmount),
      inventoryBalanceAmount: parseInt(values.inventoryBalanceAmount),
    }
  );
}

export const getLogMaterialRequestConfig = async (materialRequestId?: number, pageNumber?: number, pageSize?: number): Promise<any> => {
  const response = await api.get(`/material-request-configuration/${materialRequestId}/log`, {
    params: {
      pageNumber,
      pageSize
    }
  })

  return response.data
}