import {Button, ButtonGroup, Grid, Typography} from "@material-ui/core";
import useStyles from "./style";
import React, {useEffect, useState} from "react";
import {FormResponse} from "../../../../model/form-response";
import * as service from "../../../../service/evolution-service";

export interface ClosedQuestionProps {
    evolutionId: number,
    formResponse: FormResponse,
    disableEdit: boolean,
    onAnswer: () => void,
}

const ClosedQuestion = (props: ClosedQuestionProps) => {
    const style = useStyles();
    const [oldAnswer, setOldAnswer] = useState<number>(0)

    useEffect(() => {
        setOldAnswer(Number(props.formResponse.answerSelected));
    }, [props.formResponse.answerSelected])

    async function updateEvolution(selectedAnswer: number, questionId: number) {
        if (String(selectedAnswer) !== String(props.formResponse.answerSelected)) {
            try {
                props.formResponse.answerSelected = String(selectedAnswer);
                await service.answerQuestion(props?.evolutionId, { answerId: selectedAnswer, questionId });

                props.onAnswer();
            } catch (e) {
                props.formResponse.answerSelected = String(oldAnswer);
            }
        }
    }

    function isSelectedAnswer(index: number) {
        return props?.formResponse?.answerSelected !=null && Number(props.formResponse.answerSelected) === index
    }

    return (
        <Grid className={style.questionContainer}  key={`${props.formResponse.questionId}`}>
            <Typography className={style.questionTitle}>{props.formResponse.question}</Typography>
            <ButtonGroup className={style.formButtonGroup} aria-label="outlined primary button group">
                    {
                        props.formResponse.answers.map((value, index, arr) => {
                            return (
                                <Button disableRipple
                                        disableElevation={true}
                                        key={index}
                                        type='submit'
                                        className={isSelectedAnswer(index) ? style.selectedAnswer : style.nonSelectedAnswer}
                                        onClick={() => updateEvolution(index, props.formResponse.questionId)}
                                        disabled={props.disableEdit}>
                                    {value}
                                </Button>
                            )
                        })
                    }
            </ButtonGroup>
        </Grid>
    )
}

export default ClosedQuestion
