import { Box, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Edit, GetApp, Print, Visibility } from "@material-ui/icons";
import { getReportPDFBase64, getReports } from "../service";
import DataGrid from "../../../components/DataGrid";
import history from "../../../routes/history";
import AlertPermission from "./AlertPermission";
import StatusCustomRenderTypes from "../../../components/StatusCustomRenderTypes";
import openPdfBlob from "../../../utils/openPdfBlob";

const ReportsPF = () => {
  const [data, setData] = useState<any>([]);
  const [notAllowAccessReport, setNotAllowAccessReport] =
    useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [reportIdItemChanged, setReportIdItemChanged] =
    useState<boolean>(false);
  const [reportId, setReportId] = useState<string>("");
  const [searching, setSearching] = useState<boolean>(false);

  useEffect(() => {
    getReports().then((response) => {
      setData(response.reportPageResponse.content);
      setNotAllowAccessReport(response.notAllowAccessReport);
      setTotalPages(response.reportPageResponse.totalPages);
      setSearching(true);
    });
  }, []);

  const changePage = (page: number) => {
    setPage(page);
    setReportIdItemChanged(false);
    setReportId("");

    getReports(page).then((response) => {
      setData(response.reportPageResponse.content);
      setNotAllowAccessReport(response.notAllowAccessReport);
      setTotalPages(response.reportPageResponse.totalPages);
    });
  };

  const columns = [
    {
      name: "dnv",
      label: "DNV",
      width: "10vw",
    },
    {
      name: "namePatient",
      label: "Nome Criança",
      width: "10vw",
    },
    {
      name: "bornDate",
      label: "Data de Nascimento",
    },
    {
      name: "collectionDate",
      label: "Data da Coleta",
      width: "10vw",
    },
    {
      name: "motherName",
      label: "Filiação",
      width: "10vw",
    },
    {
      name: "status",
      label: "",
      width: "10vw",
      customRender: (index: any) => (
        <StatusCustomRenderTypes data={data[index]} />
      ),
    },
    {
      name: "",
      label: "",
      with: "1px",
      alignCenter: true,
      customRender: (index: any) => {
        return (
          <>
            <div className="actions">
              <span className="option">
                <Visibility
                  titleAccess="Visualizar"
                  onClick={() => showPdfNewTab(data[index].reportId, 1)}
                />
              </span>

              <span className="option">
                <GetApp
                  titleAccess="Download"
                  onClick={() => downloadPdf(data[index], 2)}
                />
              </span>

              <span className="option">
                <Print
                  titleAccess="Imprimir"
                  onClick={() => printPDFInApp(data[index].reportId, 3)}
                />
              </span>

              <span className="option">
                <Edit
                  titleAccess="Editar"
                  onClick={() =>
                    history.push(`/laudo/alterar-dados/${data[index].reportId}`)
                  }
                />
              </span>
            </div>
          </>
        );
      },
    },
  ];

  const showPdfNewTab = (reportId: string, visualizationType: number) => {
    setReportIdItemChanged(true);
    setReportId(reportId);

    getReportPDFBase64(reportId, visualizationType).then((pdfBase64) => {
      const contentType = "application/pdf";
      const blob = b64toBlob(pdfBase64, contentType);
      const blobUrl = openPdfBlob(blob);

      window.open(blobUrl, "_blank");
    });
  };

  const downloadPdf = (report: any, visualizationType: number) => {
    setReportIdItemChanged(true);
    setReportId(report.reportId);

    getReportPDFBase64(report.reportId, visualizationType).then((pdfBase64) => {
      const contentType = "application/pdf";
      const blob = b64toBlob(pdfBase64, contentType);
      const blobUrl = openPdfBlob(blob);

      var objDownload = document.createElement("a");
      objDownload.download = `Exame - ${report.namePatient} - ${report.collectionDate}`;
      objDownload.href = blobUrl;
      objDownload.click();
    });
  };

  const printPDFInApp = (reportId: string, visualizationType: number) => {
    setReportIdItemChanged(true);
    setReportId(reportId);

    getReportPDFBase64(reportId, visualizationType).then((pdfBase64) => {
      const contentType = "application/pdf";
      const blob = b64toBlob(pdfBase64, contentType);
      const blobUrl = openPdfBlob(blob);

      var obj = document.createElement("iframe");

      obj.src = "";
      obj.src = blobUrl;
      obj.id = "pdfToShowPrint";

      var div = document.getElementById("hiddenPDFReport") as HTMLDivElement;
      div.innerHTML = "";
      div?.appendChild(obj);

      const iframeEle = document.getElementById(
        "pdfToShowPrint"
      ) as HTMLIFrameElement;

      if (iframeEle) {
        iframeEle.contentWindow!.print();
      }
    });
  };

  const b64toBlob = (b64Data: any, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize),
        byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  return (
    <>
      <div
        title="hiddenPDFReport"
        id="hiddenPDFReport"
        style={{ display: "none" }}
      />

      <Grid container>
        <Grid item xs={12} sm={12}>
          <Box mb={1}>
            <Typography
              variant="h5"
              style={{ fontWeight: "bolder" }}
              color="textPrimary"
            >
              Resultado de Exames
            </Typography>

            {/* <Typography variant="subtitle1" color="textSecondary">
              Você pode alterar abaixo os dados anteriormente inseridos
            </Typography> */}
          </Box>
        </Grid>
      </Grid>

      {!!notAllowAccessReport && (
        <AlertPermission
          message="
            Os laudos de instituições que não permitem a visualização pela plataforma do Instituto Jô Clemente não
            são exibidos na lista a seguir. Para obtê-los, por favor, entre em contato com maternidade."
        />
      )}

      <DataGrid
        data={data}
        columns={columns}
        totalPages={totalPages}
        changePage={changePage}
        reportIdItemChanged={{
          page,
          changed: reportIdItemChanged,
          reportId,
          searching,
        }}
      />
    </>
  );
};

export default ReportsPF;
