import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  dropZoneBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    /* justifyContent: 'space-between', */
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '4px',
    color: '#fff',
    width: '100%',
  },
  dropZoneArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100px',
    cursor: 'default',
  },
  disabledDropZoneBgUpload: {
    backgroundColor: theme.palette.grey[500],
  },
  dropZoneBgUnUploaded: {
    backgroundColor: theme.palette.primary.main,
  },
  dropZoneBgUploaded: {
    backgroundColor: '#047827',
  },
  icon: {
    fontSize: '30px',
    marginBottom: '10px',
  },
}))
