import { useContext, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  CssBaseline,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ApartmentIcon from "@material-ui/icons/Apartment";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import history from "../../routes/history";
import useStyles from "./style";
import LogoMock from "../../assets/images/logoMock.png";
import Logo from "../../assets/images/logo-rectangle.png";
import { Context } from "../../providers/AuthProvider/contexts/AuthContext";
import { PregnantWoman, Visibility, VisibilityOff } from "@material-ui/icons";
import { RECAPTCHA_ACTIONS } from "../../utils/reCaptcha";

import Modal from "./components/Modal";
import PhraseReferenceServiceNeonatal from "../../components/PhraseReferenceServiceNeonatal";

import "./style.css";

export const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.ijc.org.br/">
        Instituto Jô Clemente
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

export const HeaderImage = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  if (isTabletOrMobile) {
    return (
      <>
        <Box display="flex" justifyContent="center">
          <img alt="logo ijc" src={LogoMock} style={{ width: "60%" }} />
        </Box>

        <Box>
          <PhraseReferenceServiceNeonatal />
        </Box>
      </>
    );
  } else {
    return (
      <Box mb={5} style={{ width: "70%" }}>
        <img alt="logo ijc" src={LogoMock} style={{ width: "45%" }} />

        <PhraseReferenceServiceNeonatal />
      </Box>
    );
  }
};

export const ImageLeft = () => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={false} md={5} sm={5} className={classes.image}></Grid>
    </>
  );
};

export const ImageTopMobile = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container>
        <img
          src={Logo}
          alt="Logo mãe com bebê"
          className={classes.imageMobile}
        />
        <Grid
          container
          onClick={() => history.push("/login/clientes")}
          alignItems="center"
          justifyContent="center"
          className={classes.buttonChangeLoginMobile}
        >
          <Grid item>
            <Box textAlign="center">
              <PregnantWoman fontSize="large" />
              <Typography variant="subtitle2" style={{ fontSize: "16px" }}>
                Acesso para pacientes
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export const StyledRadio = (props: any) => {
  return <Radio color="primary" checkedIcon={<CheckCircleIcon />} {...props} />;
};

const Login = () => {
  /*  useEffect(()=>{
         handleRecaptcha(RECAPTCHA_ACTIONS.LOGIN)
     }, []) */

  const { auth } = useContext(Context);
  const classes = useStyles();
  const [visibilityPassword, setVisibilityPassword] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const [open, setOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      cnes: "",
      loginType: "0",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("E-mail no formato inválido.")
        .required("Obrigatório"),
      cnes: Yup.string().when("loginType", {
        is: "0",
        then: Yup.string().required("Obrigatório"),
      }),
      password: Yup.string()
        .min(6, "A senha deve possuir 6 ou mais caracteres")
        .required("Obrigatório"),
      loginType: Yup.string().required("Obrigatório"),
    }),
    onSubmit: (values) => {
      /* handleRecaptcha(RECAPTCHA_ACTIONS.SUBMIT_FORM_LOGIN) */
      auth?.handleLogin(values, RECAPTCHA_ACTIONS.SUBMIT_FORM_LOGIN);
    },
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
  });

  const ButtonChangeTypeUserLogin = () => {
    return (
      <Grid
        container
        onClick={() => history.push("/login/clientes")}
        justifyContent="flex-end"
      >
        <Grid item className={classes.buttonChangeLogin}>
          <Box textAlign="center" m="15px">
            <PregnantWoman fontSize="large" />
            <Typography variant="subtitle2" style={{ fontSize: "16px" }}>
              Acesso para pacientes
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {isTabletOrMobile && <ImageTopMobile />}

      <Grid container className={`${classes.root} login-wrapper-main`}>
        <CssBaseline />

        {!isTabletOrMobile && <ImageLeft />}

        <Grid
          item
          xs={12}
          md={isTabletOrMobile ? 12 : 7}
          sm={isTabletOrMobile ? 12 : 7}
          component={Paper}
          square
        >
          <div className={classes.paper} hidden={true}>
            <form onSubmit={formik.handleSubmit} className={classes.form}>
              {!isTabletOrMobile && <ButtonChangeTypeUserLogin />}

              <HeaderImage />

              <Box mb={2}>
                <Typography
                  variant="h4"
                  style={{ fontWeight: "bolder" }}
                  color="textPrimary"
                >
                  Entrar
                </Typography>

                <Typography
                  variant="inherit"
                  style={{ fontSize: "18px" }}
                  color="textSecondary"
                >
                  Escolha uma forma de acesso {` `}
                  <Typography
                    className={classes.textConnect}
                    color="textSecondary"
                  >
                    Clique {` `}
                    <Link
                      className={classes.textConnectLink}
                      onClick={() => setOpen(true)}
                      color="textSecondary"
                    >
                      aqui
                    </Link>{" "}
                    {` `}
                    no caso de dúvidas.
                  </Typography>
                </Typography>

                <Modal handleClose={() => setOpen(false)} open={open} />
              </Box>

              <Box display="flex" mt={4} mb={4}>
                <RadioGroup
                  row
                  aria-label="position"
                  name="loginType"
                  onChange={formik.handleChange}
                  value={formik.values.loginType}
                >
                  <Paper className={classes.paperRadio}>
                    <Box flexWrap="nowrap" textAlign="center">
                      <ApartmentIcon color="primary" fontSize="large" />

                      <Typography
                        variant="subtitle2"
                        style={{ fontSize: "18px" }}
                        color="textSecondary"
                      >
                        Hospital
                      </Typography>

                      <FormControlLabel
                        value="0"
                        label=""
                        labelPlacement="top"
                        control={<StyledRadio />}
                      />
                    </Box>
                  </Paper>

                  <Paper className={classes.paperRadio}>
                    <Box flexWrap="nowrap" textAlign="center">
                      <AssignmentIndIcon color="primary" fontSize="large" />

                      <Typography
                        variant="subtitle2"
                        style={{ fontSize: "18px" }}
                        color="textSecondary"
                      >
                        IJC
                      </Typography>

                      <FormControlLabel
                        value="1"
                        label=""
                        labelPlacement="top"
                        control={<StyledRadio />}
                      />
                    </Box>
                  </Paper>
                </RadioGroup>
              </Box>

              <Box mb={1} mt={1}>
                <label
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    color: "#5f5f5f",
                  }}
                >
                  E-mail
                </label>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="none"
                  required
                  fullWidth
                  id="email"
                  placeholder="E-mail"
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  {...formik.getFieldProps("email")}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                  }}
                  onKeyUp={(e) => {
                    const target = e.target as HTMLInputElement;
                    formik.setFieldValue('email', target.value.toLowerCase());
                  }}
                  onChange={(e) => {
                    const target = e.target as HTMLInputElement;
                    formik.setFieldValue('email', target.value.toLowerCase());
                  }}
                />

              </Box>
              {formik.values.loginType === "0" && (
                <Box mb={1}>
                  <label
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      color: "#5f5f5f",
                    }}
                  >
                    CNES
                  </label>
                  <TextField
                    size="small"
                    variant="outlined"
                    margin="none"
                    required
                    fullWidth
                    id="cnes"
                    placeholder="CNES"
                    error={formik.touched.cnes && Boolean(formik.errors.cnes)}
                    helperText={formik.touched.cnes && formik.errors.cnes}
                    {...formik.getFieldProps("cnes")}
                  />
                </Box>
              )}
              <Box mb={1}>
                <label
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    color: "#5f5f5f",
                  }}
                >
                  Senha
                </label>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="none"
                  required
                  fullWidth
                  placeholder="Senha"
                  type={visibilityPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setVisibilityPassword(!visibilityPassword)
                          }
                        >
                          {visibilityPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  {...formik.getFieldProps("password")}
                />
              </Box>
              <Box textAlign="end">
                <Link
                  className={classes.linkRequestPass}
                  href="#"
                  variant="body2"
                  onClick={() =>
                    history.push(`/nova-senha/${formik.values.loginType}`)
                  }
                >
                  Esqueceu a senha?
                </Link>
              </Box>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Entrar
              </Button>
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
