import { useState , useContext, useEffect} from 'react'
import { Box, Button, FormControl, Grid, InputAdornment, MenuItem, Select, TextField, Typography } from "@material-ui/core"
import {  Search, Visibility , Close} from "@material-ui/icons"
import MUIDataTable from "mui-datatables";
import history from '../../routes/history';
import ModalSearchInstitutions , {CbInstituteProps} from '../../components/ModalSearchInstitutions'
import './style.css'
import { Context } from '../../providers/AuthProvider/contexts/AuthContext';
import {
  getMaterialRequest,
  getMaterials,
  getMaterialsTypes,
  getMaterialsExportExcel
} from './services';
import {
  IMaterialContent,
  IGetMaterialRequest,
  IMaterialTypes,
  IMaterialsExportExcel
} from './interfaces'
import { dateStringToDateFormat  } from '../../utils/convertDate';
import { format } from 'date-fns'
import ShowHasPermission from '../../utils/showHasPermission';

export const RequestForMaterials = () => {

    const initialState = {
        tasyCode: 0,
        cnes: '',
        corporateName: '',
        cnpj: '',
        cityState: '',
        webAccess: ''
    }

    const initialValuePagination = () => {
        setPage(0)
        setTotalRows(5)
    }

    const { user } = useContext(Context);
    //states
    const [materialData , setMaterialData] = useState<IMaterialContent[]>([])
    const [open, setOpen] = useState(false);
    const [cbInstitute, setCbInstitute] = useState<Partial<CbInstituteProps>>(initialState) //institution value
    const [status , setStatus] = useState(null)
    const [initialDate , setInitialDate] = useState('')
    const [finalDate , setFinalDate] = useState('')

    const [materialTypes, setMaterialTypes] = useState<IMaterialTypes[]>([])
    const [materialTypeById , setMaterialTypeById] = useState<number | null>(null)

    //Pagination states
    const [totalRows, setTotalRows] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [page, setPage] = useState(0)

    type SelectableRows = 'multiple' | 'single' | 'none';
    type ResponsiveType = 'vertical' | 'standard' | 'simple';

    const options = {
        search: false,
        download: false,
        expandableRowsHeader: false,
        filter: false,
        print: false,
        selectableRowsHeader: false,
        viewColumns: false,
        rowsPerPageOptions: [5, 10],
        rowsPerPage: rowsPerPage ,
        responsive: 'simple' as ResponsiveType,
        selectableRows: 'none' as SelectableRows,
        page:  page ,
        textLabels: {
            body: {
                noMatch: "Nenhum registro encontrado.",
            },
            pagination: {
                next: "Próxima Pagina",
                previous: "Pagina Anterior",
                rowsPerPage: "",
                displayRows: "de",
            },
        },
         count: totalRows ,
        serverSide: true,
          onTableChange: async (action: any, tableState: any) => {
            switch (action) {
                case 'changePage':
                    requestMaterials({pageNumber: tableState.page, pageSize:tableState.rowsPerPage});
                    break;
                case 'changeRowsPerPage':
                    requestMaterials({pageNumber: tableState.page, pageSize:tableState.rowsPerPage});

                    break;
                default:
            }
        },
        setTableProps: () => {
            return {
                size: 'small'
            };
        },
    }

    const requestMaterials = async ({ pageNumber,
        pageSize ,
        searchInstituteCnes,
        searchStatus,
        searchTypeMaterial ,
        searchByStartDate,
        searchByEndDate
     }: IGetMaterialRequest) => {
     await getMaterialRequest({
         pageNumber: pageNumber + 1,
         pageSize,
         searchInstituteCnes : searchInstituteCnes ?? cbInstitute.cnes,
         searchStatus : searchStatus  ?? status,
         searchTypeMaterial : searchTypeMaterial ?? materialTypeById ,
         searchByStartDate : searchByStartDate ?? initialDate,
         searchByEndDate : searchByEndDate ?? finalDate,
     }).then(data => {
        setMaterialData(data.content.map(item => ({
            ...item,
            requestDate : dateStringToDateFormat(item.requestDate)
        })))
         setTotalRows(data.totalRecords)
         setPage(data.pageable.pageNumber - 1)
         setRowsPerPage(data.pageable.pageSize)
     }).catch(((err) => {
         initialValuePagination()
         }))
 }

    const columns = [
        {
            name: "id",
            label: "Número Solicitação",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "instituteCnes",
            label: "CNES Instituição",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "requestDate",
            label: "Data Solicitação",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "materialRequestTypeName",
            label: "Tipo Material",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "amount",
            label: "Qtde. Solicitada",
            options: {
                filter: false,
                sort: false,
            }
        },

        {
            name: "statusName",
            label: "Status",
            options: {
                filter: false,
                sort: false,

            },
        },
        {
            name: "Visualizar Solicitação",
            options: {
                filter: false,
                sort: false,
                empty: true,
                setCellProps: () => ({ align: "center", }),
                setCellHeaderProps: () => ({ align: "center", }),
                customBodyRenderLite: (index: number) => {
                    return (
                        <Grid container   justifyContent="center" >

                                <Grid item >
                                     <Button style={{color:'rgb(43,100,150)' , cursor:'pointer'}}  onClick={() =>  history.push(`/solicitacao-materiais/detalhes/${materialData[index].id}`) }>
                                        <Visibility titleAccess='Visualizar Dados'/>
                                    </Button>
                                </Grid>
                        </Grid>
                    );
                }
            }
        }
    ]
    const handlerSubmitForm = () => {
        const formData : IGetMaterialRequest = {
            pageNumber: 0,
            pageSize : rowsPerPage,
            searchInstituteCnes : cbInstitute.cnes ,
            searchStatus : status ,
            searchTypeMaterial :  materialTypeById,
            searchByStartDate : initialDate,
            searchByEndDate :  finalDate
        }
        requestMaterials(formData)
    }

    const handleChangeInstituteIcon = (event: any) => {
        event.stopPropagation();
        setCbInstitute(initialState)
    }

    useEffect(() => {

        async function loadMaterials() {
            var date = new Date(), y = date.getFullYear(), m = date.getMonth()
            var firstDay = format(new Date(y, m-1, 1), 'yyyy-MM-dd')
            var lastDay = format(new Date(y, m, 1), 'yyyy-MM-dd')

            setInitialDate(firstDay)
            setFinalDate(lastDay)

            const materials = await getMaterials(firstDay, lastDay);

            setMaterialData(materials.content.map(material => ({
                ...material,
                requestDate : dateStringToDateFormat(material.requestDate)
            })))
            setTotalRows(materials.totalRecords)
            setRowsPerPage(materials.pageable.pageSize)
        }
        async function loadMaterialTypes() {
            const data = await getMaterialsTypes()
            setMaterialTypes(data)
        }
        loadMaterials()
        loadMaterialTypes()
    },[])


    const onChangeMaterialType = (e : any) => {
        setMaterialTypeById(e.target.value)
    }

    async function handleMaterialsExportExcel() {
      const formData : IMaterialsExportExcel = {
        instituteCnes: cbInstitute.cnes,
        materialRequestTypeId: materialTypeById,
        status: status,
        requestDateBegin: initialDate,
        requestDateEnd: finalDate,
      }

      const { data } = await getMaterialsExportExcel(formData)

      const link = document.createElement('a')

      link.href = window.URL.createObjectURL(new Blob([data]))
      link.download = `Materiais_${new Date().getTime()}.xlsx`
      document.body.appendChild(link)
      link.click()
    }

    return (
        <>
        <ModalSearchInstitutions onCallBack={setCbInstitute} open={open} onClose={() => setOpen(false)} />
            <Grid container className='header-grid'>
                <Grid item >
                    <Typography variant="h5" color="textPrimary">Solicitação de Materiais:</Typography>
                </Grid>
            </Grid>
            <Box mt={5} className='mainBox'>
                <Box className='mainBoxFormFilter' mt={5} mb={2}>
                    <Grid item container sm={12} >
                        <Grid item container xs={12} sm={12} spacing={2}>
                        <Grid item xs={12} sm={3}>
                                <Box mb={2} >
                                    <Typography style={{marginBottom:'1rem'}}  className='typographyStyle'>Período Solicitação:</Typography>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type='date'
                                        value={initialDate}
                                        onChange = {(e:any) => setInitialDate(e.target.value)}
                                        variant="outlined"
                                        margin="none"
                                        label='Data Início'
                                        id="start-date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                         />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Box mb={2} >
                                    <Typography style={{ visibility: 'hidden', marginBottom:'1rem' }} className='typographyStyle'>texto não será exibido </Typography>
                                    <TextField
                                        fullWidth
                                        onChange = {(e:any) => setFinalDate(e.target.value)}
                                        size="small"
                                        variant="outlined"
                                        margin="none"
                                        value={finalDate}
                                        label='Data Fim'
                                        id="end-date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        type='date'
                                         />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box mb={2} >
                                    <Typography style={{marginBottom:'1rem'}} className='typographyStyle'>Status:</Typography>
                                    <FormControl variant="outlined" size="small" margin="none" fullWidth>
                                        <Select
                                            placeholder="Status"
                                            labelId="status-lb"
                                            value={status}
                                            id="slc-status"
                                            onChange={(e : any) => setStatus(e.target.value)}
                                        >
                                            <MenuItem  value={' '}>Todas</MenuItem>
                                            <MenuItem value={0}>Pendente</MenuItem>
                                            <MenuItem value={1}>Confirmada</MenuItem>
                                            <MenuItem value={2}>Entregue</MenuItem>
                                            <MenuItem value={3}>Cancelada</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} sm={12} spacing={2}>
                            {user?.typeUser === 0 && (
                             <Grid item xs={12} sm={6} >
                             <Box mb={2} >
                                 {/* Disponivel apenas para usuário interno */}
                                 <Typography className='typographyStyle'>Instituição:</Typography>
                                 <TextField
                                     fullWidth
                                     onClick={() => setOpen(true)}

                                     size="small"
                                     inputProps={
                                         {
                                             className: 'iconCursor',
                                         }
                                     }
                                     value={cbInstitute.corporateName}
                                     variant="outlined"
                                     margin="none"
                                     id="search-user-institute"
                                     placeholder="Clique para selecionar a instituição"
                                     /* placeholder="Digite o nome da Instituição" */
                                     InputProps={{
                                        readOnly: true,
                                         endAdornment: (
                                            <InputAdornment position="end">
                                            {cbInstitute.corporateName !== '' ?
                                                <Close className='iconCursor' onClick={handleChangeInstituteIcon} />
                                                : <Search className='iconCursor' />}

                                        </InputAdornment>
                                         ),
                                     }} />
                             </Box>
                         </Grid>
                        )}
                          <Grid item xs={12} sm={2}>
                            <Box mb={2} >
                              <Typography className='typographyStyle'>Tipo Material:</Typography>

                              <FormControl variant="outlined" size="small" margin="none" fullWidth>
                                <Select
                                  placeholder="Status"
                                  labelId="status-lb"
                                  name='material-type'
                                  id="material-type"
                                  value={materialTypeById}
                                  onChange={onChangeMaterialType}
                                >
                                  <MenuItem selected value={' '}>Todas</MenuItem>

                                  {materialTypes.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Box mt={3}>
                              <Button  /* className={classes.button}*/ onClick={handlerSubmitForm}  fullWidth variant="contained" color="primary">
                                Pesquisar
                              </Button>
                            </Box>
                          </Grid>

                          {ShowHasPermission('SolicitacaoMaterial_RelatorioSolicitacoes') && (
                            <Grid item xs={12} sm={2}>
                              <Box mt={3}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => handleMaterialsExportExcel()}
                                >Gerar Excel</Button>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                    </Grid>
                </Box>
                {materialData && (
                     <MUIDataTable
                        options={options}
                        title={""}
                        data={materialData}
                        columns={columns}
                    />
                )}
            </Box>
        </>
    )
}

