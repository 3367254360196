import { useContext, useEffect, useState } from 'react'

import { Context } from '../../providers/AuthProvider/contexts/AuthContext'

import useStyles from './style'

import './style.css'

type StatusProps = {
  data: {
    status: string
    reportViewed: string
    reportId: number
  }
}

function StatusCustomRenderTypes({ data }: StatusProps) {
  const [dataCurrent, setDataCurrent] = useState<any>({})

  const classes = useStyles()

  const { reports: { reportIdData } } = useContext(Context)

  useEffect(() => {
    let dataCurrent = data

    if (reportIdData?.length > 0) {
      const responseData = reportIdData?.filter((filter: { reportId: number }) => filter?.reportId === data?.reportId)
        ?.map((item: any) => item)

      dataCurrent = responseData[0]
    }

    setDataCurrent(dataCurrent)
  }, [data, reportIdData])

  return (
    <>
      <span className={
        `tag margin-right-10px ${classes.status} ${dataCurrent?.status === 'Disponível' ?
          classes.availableColor : dataCurrent?.status === 'Alterado' ? classes.changedColor : classes.notAvailableColor}
        `}
      >
        {dataCurrent?.status}
      </span>

      {dataCurrent?.reportViewed === 'S' && (
        <span className={`tag report-viewed-visualized ${classes.status} ${classes.availableColor}`}>Visualizado</span>
      )}
    </>
  )
}

export default StatusCustomRenderTypes
