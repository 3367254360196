import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { useState, useEffect, useCallback } from 'react';
import { Grid, IconButton, Typography, TextField, Button, Box, Checkbox } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MUIDataTable from 'mui-datatables';
import { getInstitute } from './services/';
import { debounce } from 'lodash';
import { useMediaQuery } from 'react-responsive';

export type CbInstituteProps = {
    tasyCode: number,
    cnes: string,
    corporateName: string,
    cnpj: string,
    cityState: string,
    webAccess: string
}

type Pageable = {
    pageNumber: number,
    pageSize: number,
}

type Response = {
    data: {
        content: CbInstituteProps[],
        pageable: Pageable,
        totalPages: number,
        totalRecords: number,
    }

}

type InstituteProps = {
    PageNumber?: number,
    PageSize?: number,
    TasyCode?: number,
    Cnes?: string,
    CorporateName?: string
}

interface ModalSearchInstitutionsProps {
    open: boolean;
    onClose: () => void | undefined;
    onCallBack: (cbInstitute: CbInstituteProps[]) => void;
    selectedInstitutes: CbInstituteProps[];
    resetSelection: boolean;
}

const ModalSearchInstitutions = ({ open, onClose, onCallBack, selectedInstitutes = [], resetSelection = false }: ModalSearchInstitutionsProps) => {
    const [data, setData] = useState<CbInstituteProps[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [tasyValue, setTasyValue] = useState<number>();
    const [cnesValue, setCnesValue] = useState('');
    const [socialValue, setSocialValue] = useState('');
    const [selectedItems, setSelectedItems] = useState<CbInstituteProps[]>(selectedInstitutes.length > 0 ? selectedInstitutes : []);
    const [selectedRows, setSelectedRows] = useState<number[]>(selectedInstitutes.length > 0 ? selectedInstitutes.map(item => item.tasyCode) : []);
    const isTablet = useMediaQuery({ minWidth: 600, maxWidth: 1100 });

    useEffect(() => {

        if (resetSelection) {
            setSelectedItems([]);
            setSelectedRows([]);
            //initialValue();
        }
    }, [resetSelection]);
    
    const initialValue = () => {
        setData([]);
        setPage(0);
        setTotalRows(0);
    }

    const requestInstitute = async ({ CorporateName, Cnes, TasyCode, PageNumber, PageSize }: InstituteProps) => {
        await getInstitute({
            Cnes: Cnes ?? cnesValue,
            CorporateName: CorporateName ?? socialValue,
            TasyCode: TasyCode ?? tasyValue,
            PageNumber: PageNumber + 1,
            PageSize: PageSize
        }).then((response: Response) => {
            setData(response.data.content);
            setTotalRows(response.data.totalRecords);
            setPage(response.data.pageable.pageNumber - 1);
            setRowsPerPage(response.data.pageable.pageSize);
            // Força a atualização dos itens selecionados baseado no conteúdo atualizado
            //const newSelectedRows = selectedInstitutes.filter(item => response.data.content.some(dataItem => //dataItem.tasyCode === item.tasyCode)).map(item => item.tasyCode);
            //setSelectedRows(newSelectedRows);
        })
        .catch(((err) => {
        }))
    }

    const debouncedRequestInstitute = useCallback(
        debounce((searchParams) => {

            requestInstitute(searchParams);
        }, 2000), // Aumentado para 2000 milissegundos
        [] // Removendo as dependências para evitar reinicializações desnecessárias
    );

    const onSearchTasy = (e: any) => {
        setTasyValue(e.target.value);
        debouncedRequestInstitute({ TasyCode: e.target.value, PageNumber: 0, PageSize: rowsPerPage });
    }

    const onSearchCnes = (e: any) => {

        const selectedItemsAsNumbers: number[] = selectedInstitutes.map(item => item.tasyCode);
        const arrayAtualizado: number[] = Array.from(new Set(selectedRows.concat(selectedItemsAsNumbers)));
        setSelectedRows(arrayAtualizado);

        const selectedItemsAsNumbers2: CbInstituteProps[] = selectedInstitutes;
        const arrayAtualizado2: CbInstituteProps[] = Array.from(new Set(selectedItems.concat(selectedItemsAsNumbers2)));
        setSelectedItems(arrayAtualizado2);

        setCnesValue(e.target.value);
        debouncedRequestInstitute({ Cnes: e.target.value, PageNumber: 0, PageSize: rowsPerPage });
    }

    const onSearchSocial = (e: any) => {

        const selectedItemsAsNumbers: number[] = selectedInstitutes.map(item => item.tasyCode);
        const arrayAtualizado: number[] = Array.from(new Set(selectedRows.concat(selectedItemsAsNumbers)));
        setSelectedRows(arrayAtualizado);

        const selectedItemsAsNumbers2: CbInstituteProps[] = selectedInstitutes;
        const arrayAtualizado2: CbInstituteProps[] = Array.from(new Set(selectedItems.concat(selectedItemsAsNumbers2)));
        setSelectedItems(arrayAtualizado2);

        setSocialValue(e.target.value);
        debouncedRequestInstitute({ CorporateName: e.target.value, PageNumber: 0, PageSize: rowsPerPage });
    }

    const onCloseHandle = () => {
        onClose();
    }

    type SelectableRows = 'multiple' | 'single' | 'none';
    type ResponsiveType = 'vertical' | 'standard' | 'simple';

    const handleCheckboxChange = (tasyCode: number) => {
        const currentIndex = selectedRows.indexOf(tasyCode);
        const newSelectedRows = [...selectedRows];
        const newSelectedItems = [...selectedItems];
    
        if (currentIndex === -1) {
            newSelectedRows.push(tasyCode);
            // Adiciona o item aos itens selecionados se não estiver presente
            const newItem = data.find(item => item.tasyCode === tasyCode);
            if (newItem) {
                newSelectedItems.push(newItem);
            }
        } else {
            newSelectedRows.splice(currentIndex, 1);
            // Remove o item dos itens selecionados
            const itemIndex = newSelectedItems.findIndex(item => item.tasyCode === tasyCode);
            if (itemIndex !== -1) {
                newSelectedItems.splice(itemIndex, 1);
            }
        }

        setSelectedRows(newSelectedRows);
        setSelectedItems(newSelectedItems);
    };
    
    const handleConfirmSelection = () => {
        // Esta função irá agora filtrar quaisquer duplicatas que poderiam ser adicionadas
        const uniqueSelectedItems = selectedItems.reduce((acc, currentItem) => {
            if (!acc.some(item => item.tasyCode === currentItem.tasyCode)) {
                acc.push(currentItem);
            }
            return acc;
        }, []);
    
        onCallBack([...uniqueSelectedItems]);
        onCloseHandle();
    };

    const options = { 
        search: false,
        download: false,
        expandableRowsHeader: false,
        filter: false,
        print: false,
        selectableRowsHeader: false,
        viewColumns: false,
        rowsPerPageOptions: [5, 10],
        rowsPerPage: rowsPerPage,
        responsive: isTablet ? 'vertical' as ResponsiveType : 'simple' as ResponsiveType,
        selectableRows: 'none' as SelectableRows,
        page: page,
        textLabels: {
            body: {
                noMatch: "Nenhum registro encontrado.",
            },
            pagination: {
                next: "Próxima Pagina",
                previous: "Pagina Anterior",
                rowsPerPage: "",
                displayRows: "de",
            },
            selectedRows: {
                text: "linha(s) selecionada(s)",
                delete: "Excluir",
                deleteAria: "Excluir linhas selecionadas",
            }
        },
        count: totalRows,
        serverSide: true,
        onTableChange: async (action: any, tableState: any) => {
            switch (action) {
                case 'changePage':
                    requestInstitute({ PageNumber: tableState.page, PageSize: tableState.rowsPerPage });
                    break;
                case 'changeRowsPerPage':                   
                    requestInstitute({ PageNumber: tableState.page, PageSize: tableState.rowsPerPage });
                    break;
                default:
            }
        },
        setTableProps: () => {
            return {
                size: 'small'
            };
        }
    }

    const columns = [
        {
            name: "tasyCode",
            label: "Selecionar",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value: number) => {
                    return (
                        <Checkbox
                            checked={selectedRows.includes(value)}
                            onChange={() => handleCheckboxChange(value)}
                        />
                    );
                }
            }
        },
        {
            name: "tasyCode",
            label: "Código TASY",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "cnes",
            label: "Código CNES",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "corporateName",
            label: "Razão Social",
            options: {
                filter: false,
                sort: false,

            },
        },
        {
            name: "cnpj",
            label: "CNPJ",
            options: {
                filter: false,
                sort: false,

            },
        },
        {
            name: "cityState",
            label: "Cidade / Estado",
            options: {
                filter: false,
                sort: false,

            },
        },
        {
            name: "webAccess",
            label: "Acesso Web",
            options: {
                filter: false,
                sort: false,
                customBodyRender : (index : string) => {
                    return (
                        index ==='S' ? 'SIM' : 'NÃO'
                    )
                }
            },
        },
    ];

    return (
        <Dialog open={open} onClose={onClose} maxWidth='xl' fullWidth scroll='body'>
            <MuiDialogTitle disableTypography>
                <Typography variant="h6">Pesquisar Instituições</Typography>
                <IconButton aria-label="close" style={{ position: 'absolute', right: '10px', top: '10px' }}
                    onClick={onCloseHandle}>
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <DialogContent>
                <Grid container justifyContent='center' alignItems='center' spacing={2}>
                    <Grid item >
                        <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Código TASY</Typography>
                        <TextField
                            fullWidth
                            onChange={onSearchTasy}
                            size="small"
                            variant="outlined"
                            margin="none"
                            id="search-tasy"
                            placeholder="Digite o código TASY"
                        />
                    </Grid>
                    <Grid item >
                        <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Código CNES</Typography>
                        <TextField
                            fullWidth
                            onChange={onSearchCnes}
                            size="small"
                            variant="outlined"
                            margin="none"
                            id="search-cnes"
                            placeholder="Digite o código CNES"
                        />
                    </Grid>
                    <Grid item >
                        <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Razão Social</Typography>
                        <TextField
                            fullWidth
                            onChange={onSearchSocial}
                            size="small"
                            variant="outlined"
                            margin="none"
                            id="search-social-reason"
                            placeholder="Digite a Razão Social"
                        />
                    </Grid>
                </Grid>
                <Grid>
                    <Box mt={2} mb={2}>
                        <MUIDataTable 
                            options={options}
                            title={""}
                            data={data}
                            columns={columns}
                        />
                    </Box>
                    <Box mt={2} mb={2}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleConfirmSelection}
                        >
                            Confirmar Seleção
                        </Button>
                    </Box>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default ModalSearchInstitutions;
