import ChangePassword from "../modules/ChangePassword"
import Dashboard from "../modules/Dashboard"
import Login from "../modules/Login"
import LoginPF from "../modules/LoginPF"
import ResetPassword from "../modules/ResetPassword"
import ResetPasswordPF from '../modules/LoginPF/ResetPasswordPF'
import UserGroup from "../modules/UserGroup"
import Users from "../modules/Users"
import history from "./history"
import SuggestionsCompaints from "../modules/suggestionsComplaints"
import NewbornBloodspotTraining from "../modules/NewbornBloodspotTraining"
import {RequestForMaterials} from '../modules/RequestForMaterials'
import {MaterialRquestDetails} from '../modules/RequestForMaterials/MaterialRequestDetails'
import MaterialRequestConfiguration from "../modules/MaterialRequestConfiguration"
import WithoutPermission from "../modules/WithoutPermission"
import {ChangeChildRegistrationData} from '../modules/ChangeChildRegistrationData'
import Reports from "../modules/Reports"
import { ReactNode } from "react"
import EvolutionList from "../modules/Evolution";
import EvolutionForm from "../modules/Evolution/components/form";
import EvolutionSign from "../modules/Evolution/components/sign";

type RouteTypes = {
  id: string,
  component : ReactNode,
  publicPath : boolean,
  path : string[],
  permission ?: string,
  exact ?: boolean
  title ?: string,

}

/* type RouteType = {
  id : string;
  title: string,
  component: ReactNode,
  publicPath: boolean,
  path: [string] string
} */

export const PATH_ROUTE = {
  LOGIN: '/login/hospitais',
  LOGIN_PF: '/login/clientes',
  DASHBOARD: '/dashboard',
  CADASTRO: '/cadastro', 
  REDEFINIR_SENHA: '/nova-senha/:typeUser',
  REDEFINIR_SENHA_PARAM: '/nova-senha/redefinir/:resetPasswordCode',
  REDEFINIR_SENHA_PF : '/nova-senha-cliente',
  REDEFINIR_SENHA_PARAM_PF: '/nova-senha-cliente/redefinir/:resetPasswordCode',
  GRUPO_USUARIO: '/grupo-usuario',
  USUARIO: '/usuario',
  ALTERAR_SENHA: '/alterar-senha',
  ALTERAR_DADOS_DO_LAUDO : '/laudo/alterar-dados/:laudoId', 
  SUGESTOES_RECLAMACOES: '/sugestoes-reclamacoes',
  LAUDO : '/laudo',
  CAPACITACAO_TESTE_DO_PEZINHO: '/capacitacao-teste-pezinho',
  DETALHES_SOLIC_DE_MATERIAL : '/solicitacao-materiais/detalhes/:requestNumber',
  SOLIC_DE_MATERIAIS : '/solicitacao-materiais/',
  CONFIGURACAO_SOLIC_MATERIAIS : '/configuracao-solicitacao-material',
  SEM_AUTORIZACAO: '/sem-autorizacao',
  EVOLUTION: '/evolucao',
  FORM_EVOLUTION: '/formulario-evolucao/:evolutionId/:stage',
  SIGNATURE_EVOLUTION: '/signature-evolucao/:evolutionId/:type'
}

export const routes : RouteTypes[] = [  
  {
    id: 'Login',
    component: Login,
    publicPath: true,
    path: [PATH_ROUTE.LOGIN]
  },
  {
    id: 'LoginCliente',
    component: LoginPF,
    publicPath: true,
    path: [PATH_ROUTE.LOGIN_PF]
  }, 
  {
    id: 'ResetPassword',
    component: ResetPassword,
    publicPath: true,
    path: [PATH_ROUTE.REDEFINIR_SENHA_PARAM, PATH_ROUTE.REDEFINIR_SENHA]
  },
  {
    id: 'ResetPasswordPF',
    component: ResetPasswordPF,
    publicPath: true,
    path: [PATH_ROUTE.REDEFINIR_SENHA_PARAM_PF, PATH_ROUTE.REDEFINIR_SENHA_PF]
  },
  {
    id: 'Dashboard',
    component: Dashboard,
    publicPath: false,
    path: [PATH_ROUTE.DASHBOARD],
    permission: ''
  },
  {
    id: 'Cadastro',
    component: () => { return <><h1>TELA DE CADASTRO</h1>                                
                              <button onClick={()=> history.push('/dashboard')}>Dashboard</button></> },
    publicPath: false,
    path: [PATH_ROUTE.CADASTRO],
    permission: ''
  },
  {
    id: 'GrupoUsuario',
    component: UserGroup,
    publicPath: false,
    path: [PATH_ROUTE.GRUPO_USUARIO],
    permission: 'GrupoUsuario_ListagemVisualizacao'
  },
  {
    id: 'Usuario',
    component: Users,
    publicPath: false,
    path: [PATH_ROUTE.USUARIO],
    permission: 'Usuário_ListagemVisualizacao'
  },
  {
    id: 'AlterarSenha',
    component: ChangePassword,
    publicPath: false,
    path: [PATH_ROUTE.ALTERAR_SENHA]
  },
  {
    id: 'AlterarDadosLaudo',
    component: ChangeChildRegistrationData,
    publicPath: false,
    path: [PATH_ROUTE.ALTERAR_DADOS_DO_LAUDO],
    permission: ''
  },
  {
    id: 'SugestoesReclamacoes',
    component: SuggestionsCompaints,
    publicPath: false,
    path: [PATH_ROUTE.SUGESTOES_RECLAMACOES],
    permission: ''
  }, 
  {
    id: 'Laudo',
    component: Reports,
    publicPath: false,
    path: [PATH_ROUTE.LAUDO],
    permission: ''
  },
  {
    id: 'CapacitacaoTestePezinhn',
    component: NewbornBloodspotTraining,
    publicPath: false,
    path: [PATH_ROUTE.CAPACITACAO_TESTE_DO_PEZINHO],
    permission: 'SolicitacaoCapacitacaoTesteDoPezinho_Cadastro'
  },
  {
    id: 'ConfiguracaoMateriais',
    component: MaterialRequestConfiguration,
    publicPath: false,
    path: [PATH_ROUTE.CONFIGURACAO_SOLIC_MATERIAIS],
    permission: 'SolicitacaoMaterialConfiguracao_ListagemVisualizacao'
  },
  {
    id: 'SolicitacaoMateriais',
    exact: true,
    component: RequestForMaterials,
    publicPath: false,
    path: [PATH_ROUTE.SOLIC_DE_MATERIAIS],
    permission: ''
  },
  {
    id: 'DetalhesSolicitacaoMaterial',
    component: MaterialRquestDetails,
    publicPath: false,
    path: [PATH_ROUTE.DETALHES_SOLIC_DE_MATERIAL],
    permission: 'SolicitacaoMaterial_ListagemVisualizacao'
  },
  {
    id: 'PaginaNaoEncontrada',
    title: 'Página Não Encontrada',
    component: WithoutPermission,
    publicPath: false,
    path: [PATH_ROUTE.SEM_AUTORIZACAO]
  },
  {
    id: 'Evolucao',
    title: 'Formulário de Evolução',
    component: EvolutionList,
    publicPath: false,
    path: [PATH_ROUTE.EVOLUTION]
  },
  {
    id: 'FormularioEvolucao',
    title: 'Evolução Paciente',
    component: EvolutionForm,
    publicPath: false,
    path: [PATH_ROUTE.FORM_EVOLUTION]
  },
  {
    id: 'SignatureEvolucao',
    title: 'Assinatura Evolução',
    component: EvolutionSign,
    publicPath: false,
    path: [PATH_ROUTE.SIGNATURE_EVOLUTION]
  },
]
