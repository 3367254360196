import axios from "axios";
import Snackbar from "../components/Snackbar";
import history from '../routes/history';
const baseUrl = process.env.REACT_APP_BASE_API_URL
const versionAPi = process.env.REACT_APP_VERSION_API

const api = axios.create({
  baseURL: `${baseUrl}/${versionAPi}`,
});

/**
 * Configura o token nas requisições da api.
 */
export const confiHeaderTokenApi = (token?: string) => {
  if (token) {
    api.defaults.headers.Pragma = 'no-cache'

    api.defaults.headers.Authorization = `Bearer ${token}`;
  } else {
    api.defaults.headers.Authorization = undefined;
  }
}

/**
 * TO-DO - Se houver a lógica de refresh token, implementar nessa função que intercepta as requisições e respostas da api.
  Por hora se ocorrer a invalidação do token o usuário é redirecionado para a tela de login.
*/
export const configInterceptApi = (setIsLoading: any) => {
  //Intercepata as requisições da api.
  api.interceptors.request.use((request) => {
    setIsLoading(true);

    return request
  }, err => {
    setIsLoading(false);

    return err
  })

  //Intercepata as respostas das requisições.
  api.interceptors.response.use((response) => {
    setIsLoading(false);
   
    return response
  }, async function (error) {
    setIsLoading(false);

    if (error.response) {      
      if (error.response.status === 400 && !error.response.data.content && !error.response.data.errors) {
        Snackbar({ message: error.response.data.detail, type: 'error' });
        // console.log(error.response.data)
      }

      if (error.response.status === 400 && error.response.data.errors) {
        Snackbar({ message: 'Erro ao cadastrar, verifique os campos obrigatórios', type: 'error' });
      }

      if (error.response.status === 401 && !error.response.data.content) {
        if (error.response.data === "") {
          Snackbar({ message: 'Usuário não autorizado', type: 'error' });
        } else {
          Snackbar({ message: error.response.data.detail, type: 'error' });
        }

        //TO-DO - Se houver a lógica de refresh token, implementar aqui.
        history.push('/login/hospitais')

      }

      if (error.response.status === 403)
        Snackbar({ message: 'O usuário não tem permissão de realizar essa ação', type: 'error' });

      if (error.response.status === 500) {
        Snackbar({ message: 'Ocorreu um erro interno!', type: 'error' });
      }
    } else {
      Snackbar({ message: 'Ocorreu um erro interno!', type: 'error' });
    }

    return Promise.reject(error);
  });
}

export default api;
