import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({

    patientInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px 15px'
    },
    dateInfo: {
        paddingRight: '15px'
    },
    editLink: {
        paddingRight: '15px'
    },
    evolutionCardContent: {
        padding: '5px 15px'
    },
    finishedEvolutionLabel: {
        paddingTop: '10px',
        color: '#1B943D'
    },
    unfinishedEvolutionLabel: {
        paddingTop: '10px',
        color: '#E64683'
    },
    patientInfoContainer: {
        border: '1px solid',
        backgroundColor: '#efefef'
    },
    fullProfileButton: {
        margin: 'auto',
        padding: '15px',
        justifyContent: 'center'
    },
    evolutionProgressLabel: {
        paddingLeft: '15px',
        marginTop: '5px'
    },
    evolutionProgressDoneLabel: {
        paddingLeft: '15px',
        marginTop: '5px',
        color: 'green'
    },
    evolutionProgressStep: {
        fontSize: '0.6rem'
    },
    formGroup: {
        border: '1px solid',
        padding: '10px'
    },
    formTitle: {
        justifyContent: 'center',
        display: 'flex',
    },
    questionTitle: {
        justifyContent: 'start',
        display: 'flex',
        marginLeft: '10px'
    },
    questionContainer: {
      margin: '15px 0px'
    },
    formPaper: {
        flexGrow: 1,
        margin: '10px 0px'
    },
    formRadioGroup: {
        flexDirection: 'unset',
        justifyContent: 'center'
    },
    selectLabel: {
        padding: '5px 10px'
    },
    closeButton: {
    },
    title: {
        padding: '0px 0px !important',
        "& .MuiDialogTitle-root": {
            padding: '0px 0px !important',
            color: 'red'
        }
    },
    signTitle: {
        display: 'flex',
        justifyContent: 'center'
    },
    signChip: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    }

}))
