import { useEffect, useState } from "react";
import { useContext } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, CssBaseline, Grid, Link, Paper ,TextField, Typography, IconButton , InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import history from '../../../routes/history';
import useStyles from './style';
import { Context } from "../../../providers/AuthProvider/contexts/AuthContext";
import { useParams } from "react-router-dom";
import { HeaderImage, ImageLeft, ImageTopMobile } from "../../Login";
import { useMediaQuery } from "react-responsive";
import { handleRecaptcha, RECAPTCHA_ACTIONS } from "../../../utils/reCaptcha";


type IRouteParams = {
    resetPasswordCode:string;
}

const Copyright = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.ijc.org.br/">
                Instituto Jô Clemente
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const ResetPassword = () => {
    const { auth } = useContext(Context);
    const classes = useStyles();
    const [isSendResetPass, setIsSendResetPass] = useState(false);
    const [isSuccessResetPass, setIsSuccessResetPass] = useState(false);
     const [visibilityPassword, setVisibilityPassword] = useState(false)
     const [visibilityNewPassword, setVisibilityNewPassword] = useState(false)
    const [renderResetPass, setRenderResetPass] = useState(false);
    const { resetPasswordCode } = useParams<IRouteParams>();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 598px)' })


    useEffect(() => {
        handleRecaptcha(RECAPTCHA_ACTIONS.LOGIN)

        if (resetPasswordCode) {
            setRenderResetPass(true)
        }

    }, [resetPasswordCode])


    const formik = useFormik({
        initialValues: {
            email: '',
            loginType: '2'
        },
        validationSchema: Yup.object({
            email: Yup.string().email('E-mail no formato inválido.').required('Obrigatório'),

            /* loginType: Yup.string().required('Obrigatório') */
        }),
        onSubmit: values => {

            auth?.handleResetPassword(values,RECAPTCHA_ACTIONS.SUBMIT_FORM_RESET).then((ret: boolean) => {
                setIsSendResetPass(true)
                setIsSuccessResetPass(ret)
            })
        }
    })

    const InformationSendResetPass = () => {
        return (
            <Box m={5} >
                {isSuccessResetPass
                    ? <Typography variant='h6' >Enviamos um e-mail para você com o link para redefinição da senha.</Typography>

                    : <><Typography variant='h6' >O e-mail não foi identificado.</Typography>
                        <Button fullWidth
                            onClick={() => setIsSendResetPass(false)}
                            variant="text" color="secondary"
                            className={classes.submit}> Tentar novamente </Button></>}
            </Box>
        )
    }

    const InformationFormResetPass = () => {
        return (
            <Box m={5} >
                {isSuccessResetForm
                    ? <Typography variant='h6' >Senha alterada com sucesso.</Typography>

                    : <><Typography variant='h6' >Não foi possível criar a nova senha.</Typography>
                        <Button fullWidth
                            onClick={() => { setRenderResetPass(false); history.push('/nova-senha-cliente') }} //replace('/nova-senha')}
                            variant="text" color="secondary"
                            className={classes.submit}> Tentar novamente </Button></>}
            </Box>
        )
    }

    const [isSendResetForm, setIsSendResetForm] = useState(false);
    const [isSuccessResetForm, setIsSuccessResetForm] = useState(false);


    const FormResetPassWord = () => {
        /* Confirm Password should be put here */
        const formikRestPass = useFormik({
            initialValues: {
                password :'',
                confirmPassword: '',
                resetPasswordCode: resetPasswordCode
            },
            validationSchema: Yup.object({
                password: Yup.string().min(6, 'A senha deve possuir 6 ou mais caracteres'),
                confirmPassword: Yup.string()
                    .oneOf([Yup.ref('password')], 'Confirmação incorreta')

            }),
            onSubmit: values => {
                auth?.handleNewPassword({
                    resetPasswordCode: values.resetPasswordCode,
                    newPassword: values.password
                },RECAPTCHA_ACTIONS.RESET_PASSWORD_CONFIRMATION).then((ret: boolean) => {
                    setIsSendResetForm(true)
                    setIsSuccessResetForm(ret)
                })
            }
        })

        return (
            <>{isSendResetForm ?
                <InformationFormResetPass /> :
                <form onSubmit={formikRestPass.handleSubmit} className={classes.form}>
                    <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Nova Senha</Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                margin="none"
                                type={visibilityPassword ? 'text' : 'password'}
                                required
                                fullWidth
                                id="password"

                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                onClick={() => {  setVisibilityPassword(!visibilityPassword)}}
                                            >
                                                {visibilityPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                error={formikRestPass.touched.password && Boolean(formikRestPass.errors.password)}
                                helperText={formikRestPass.touched.password && formikRestPass.errors.password}
                                {...formikRestPass.getFieldProps('password')}
                            />
                    <Box mt={1} mb={2}>
                        <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Confirmar Senha</Typography>
                        <TextField
                            size="small"
                            variant="outlined"
                            margin="none"
                            type={visibilityNewPassword ? 'text' : 'password'}
                            required
                            fullWidth
                            id="confirmPassword"

                             InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton
                                            aria-label='toggle password visibility'
                                            onClick={() => setVisibilityNewPassword(!visibilityNewPassword)}
                                        >
                                            {visibilityNewPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            error={formikRestPass.touched.confirmPassword && Boolean(formikRestPass.errors.confirmPassword)}
                            helperText={formikRestPass.touched.confirmPassword && formikRestPass.errors.confirmPassword}
                            {...formikRestPass.getFieldProps('confirmPassword')}
                        />
                    </Box>
                    <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                        Enviar
                    </Button>
                </form>
            }</>
        )
    }

    return (

        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            {isTabletOrMobile && <ImageTopMobile />}
            {!isTabletOrMobile && <ImageLeft />}

            <Grid item xs={12} md={7} component={Paper} square >
                <div className={classes.paper}>
                    <Link
                      style={{ width: '100%', padding: '10px', fontWeight:'bold' }}
                      href="#"
                      variant="body2"
                      onClick={() => history.push('/login/clientes')}
                    >
                      Voltar
                    </Link>

                    <div className={classes.form}>
                        <HeaderImage />
                    </div>

                    {isSendResetPass
                        ?
                        <InformationSendResetPass />
                        :
                        renderResetPass
                            ?
                            <FormResetPassWord />
                            :
                            <form onSubmit={formik.handleSubmit} className={classes.form}>

                                <Box mb={2}>
                                    <Typography variant="h4" style={{ fontWeight: 'bolder' }} color="textPrimary">
                                        Recuperação de senha
                                    </Typography>
                                    <Typography variant="inherit" style={{ fontSize: '18px' }} color="textSecondary">
                                        Enviaremos um e-mail com o link para a redefinição da senha.
                                    </Typography>
                                </Box>

                                <TextField
                                    size="small"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                    {...formik.getFieldProps('email')}
                                />

                                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                                    Enviar
                                </Button>
                            </form>}
                        <Box mt={5}>
                        <Copyright />
                    </Box>
                </div>
            </Grid>
        </Grid>
    );
}

export default ResetPassword
