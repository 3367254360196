import { useState } from "react";
import { Box, Button, Grid, IconButton, InputAdornment, TextField, Typography } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useFormik } from "formik";
import * as Yup from 'yup';

import useStyles from './style';
import { changePassword } from "../../providers/AuthProvider/services";
import Snackbar from "../../components/Snackbar";


const ChangePassword = () => {
    const [visibilityPassword, setVisibilityPassword] = useState(false)
    const [visibilityNewPassword, setVisibilityNewPassword] = useState(false)
    const classes = useStyles();

    const formik = useFormik({
        initialValues: {
            password: '',
            newPassword: '',
            confirmNewPassword : ''
        },
        validationSchema: Yup.object({
            password: Yup.string().required('Obrigatório'),
            newPassword: Yup.string().when('password', {
                is: (val:string) => !!val  ,   //when old password has a value, then required field
                then : Yup.string().min(6, 'A senha deve possuir 6 ou mais caracteres')
                .required('Obrigatório'),
                otherwise : Yup.string(),
            }),
            confirmNewPassword : Yup.string().when('password', {
                is: (val:string) => !!val ,
                then: Yup.string().required('Obrigatório'),
                otherwise: Yup.string(),
            }).oneOf([Yup.ref('newPassword')], 'Confirmação incorreta')
                
        }),
        onSubmit: values => {           
            changePassword(values).then(()=>{
                Snackbar({ message: 'Senha alterada com sucesso.', type: 'success' })
                formik.resetForm();
            });
            
        }
    })


    return (
        <>
            <Grid container>
                <Grid item >
                    <Typography variant="h5" style={{ fontWeight: 'bolder' }} color="textPrimary">Alterar Senha</Typography>
                </Grid>
            </Grid>
            <form onSubmit={formik.handleSubmit} >
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item sm={3} >
                        <Box mt={1}>
                            <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Senha Atual</Typography>
                            <TextField
                                size="small"
                                variant="outlined"

                                margin="none"
                                type={visibilityPassword ? 'text' : 'password'}
                                required
                                fullWidth
                                id="password"
                                placeholder="Senha Atual"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                onClick={() => setVisibilityPassword(!visibilityPassword)}
                                            >
                                                {visibilityPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                                {...formik.getFieldProps('password')}
                            />
                        </Box>
                        <Box mt={1} mb={2}>
                            <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Nova Senha</Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                margin="none"
                                disabled={!formik.values.password}
                                inputProps={
                                    formik.values.password==='' ? {style: {cursor: 'not-allowed'}} : {}  
                                   }
                                type={visibilityNewPassword ? 'text' : 'password'}
                                required
                                fullWidth
                                id="newPassword"
                                placeholder="Nova Senha"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                onClick={() => setVisibilityNewPassword(!visibilityNewPassword)}
                                            >
                                                {visibilityNewPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                                helperText={formik.touched.newPassword && formik.errors.newPassword}
                                {...formik.getFieldProps('newPassword')}
                            />
                        </Box>
                        <Box mt={1} mb={2}>
                            <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Confirmar Senha</Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                margin="none"
                                disabled={!formik.values.password}
                                inputProps={
                                 formik.values.password==='' ? {style: {cursor: 'not-allowed'}} : {}  
                                }
                                type={visibilityNewPassword ? 'text' : 'password'}
                                required
                                fullWidth
                                id="confirmNewPassword"
                                placeholder="Confirmar Senha"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                onClick={() => setVisibilityNewPassword(!visibilityNewPassword)}
                                            >
                                                {visibilityNewPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                error={formik.touched.confirmNewPassword && Boolean(formik.errors.confirmNewPassword)}
                                helperText={formik.touched.confirmNewPassword && formik.errors.confirmNewPassword}
                                {...formik.getFieldProps('confirmNewPassword')}
                            />
                        </Box>
                        <Button className={classes.button} type="submit" fullWidth variant="contained" color="primary">Alterar Senha</Button>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export default ChangePassword