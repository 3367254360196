import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { useState } from 'react'
import { Grid, IconButton, Typography, TextField, Button, Box } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import MUIDataTable from 'mui-datatables'
import { getInstitute } from './services/'
import { debounce } from 'lodash'
import { useMediaQuery } from 'react-responsive'

export type CbInstituteProps = {
    tasyCode: number,
    cnes: string,
    corporateName: string,
    cnpj: string,
    cityState: string,
    webAccess: string
}

type Pageable = {
    pageNumber: number,
    pageSize: number,
}

type Response = {
    data: {
        content: CbInstituteProps[],
        pageable: Pageable,
        totalPages: number,
        totalRecords: number,
    }

}

type InstituteProps = {
    PageNumber?: any,
    PageSize?: any,
    TasyCode?: number,
    Cnes?: string,
    CorporateName?: string
}


interface ModalSearchInstitutionsProps {
    open: boolean;
    onClose: () => void | undefined;
    onCallBack: (cbInstitute: CbInstituteProps) => void;
}


const ModalSearchInstitution = ({ open, onClose, onCallBack }: ModalSearchInstitutionsProps) => {
    const [data, setData] = useState<CbInstituteProps[]>([])
    const [totalRows, setTotalRows] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [page, setPage] = useState(0)
    const [tasyValue, setTasyValue] = useState<number>()
    const [cnesValue, setCnesValue] = useState('')
    const [socialValue, setSocialValue] = useState('')
    const isTablet = useMediaQuery({ minWidth: 600, maxWidth: 1100 })
    
    const initialValue = () => {
        setData([])
        setPage(0)
        setTotalRows(5)
        setTotalRows(0)
    }

    const requestInstitute = async ({ CorporateName, Cnes, TasyCode, PageNumber, PageSize }: InstituteProps) => {
        await getInstitute({
            Cnes: Cnes ?? cnesValue,
            CorporateName: CorporateName ?? socialValue,
            TasyCode: TasyCode ?? tasyValue,
            PageNumber: PageNumber + 1,
            PageSize: PageSize
        }).then((response: Response) => {
            setData(response.data.content)/*.map (item => ({
                ...item,
                webAccess: item.webAccess === 'S' ? 'SIM' : 'NÃO'
            })) */
            setTotalRows(response.data.totalRecords)
            setPage(response.data.pageable.pageNumber - 1)
            setRowsPerPage(response.data.pageable.pageSize)
        }).catch(((err) => {
                initialValue()
            }))
    }

    const onSearchTasy = (e: any) => {
        setTasyValue(e.target.value)
        requestInstitute({ TasyCode: e.target.value })
    }

    const onSearchCnes = (e: any) => {
        setCnesValue(e.target.value)
        requestInstitute({ Cnes: e.target.value })
    }

    const onSearchSocial = (e: any) => {
        setSocialValue(e.target.value)
        requestInstitute({ CorporateName: e.target.value })
    }

    const onCloseHandle = () => {
        initialValue()
        onClose()
    }

    type SelectableRows = 'multiple' | 'single' | 'none';
    type ResponsiveType = 'vertical' | 'standard' | 'simple';

    const selectItem = (index: any) => {
        const item = data[index]
        
        onCallBack(item)        
        onCloseHandle()
    }

    const options = { 
        search: false,
        download: false,
        expandableRowsHeader: false,
        filter: false,
        print: false,
        selectableRowsHeader: false,
        viewColumns: false,
        rowsPerPageOptions: [5, 10],
        rowsPerPage: rowsPerPage,
        responsive: isTablet ? 'vertical' as ResponsiveType : 'simple' as ResponsiveType,
        selectableRows: 'none' as SelectableRows,
        page: page,
        textLabels: {
            body: {
                noMatch: "Nenhum registro encontrado.",
            },
            pagination: {
                next: "Próxima Pagina",
                previous: "Pagina Anterior",
                rowsPerPage: "",
                displayRows: "de",
            },
        },
        count: totalRows,
        serverSide: true,
        tableState:{
            announceText: null
        },
        onTableChange: async (action: any, tableState: any) => {
            switch (action) {
                case 'changePage':
                    requestInstitute({ PageNumber: tableState.page, PageSize: tableState.rowsPerPage });
                    break;
                case 'changeRowsPerPage':                   
                    requestInstitute({ PageNumber: tableState.page, PageSize: tableState.rowsPerPage });
                    break;
                default:
            }
        },
        setTableProps: () => {
            return {
                size: 'small'
            };
        },
    }    

    const columns = [
        {
            name: "tasyCode",
            label: "Código TASY",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "cnes",
            label: "Código CNES",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "corporateName",
            label: "Razão Social",
            options: {
                filter: false,
                sort: false,

            },
        },
        {
            name: "cnpj",
            label: "CNPJ",
            options: {
                filter: false,
                sort: false,

            },
        },
        {
            name: "cityState",
            label: "Cidade / Estado",
            options: {
                filter: false,
                sort: false,

            },
        },
        {
            name: "webAccess",
            label: "Acesso Web",
            options: {
                filter: false,
                sort: false,
                customBodyRender : (index : string) => {
                    return (
                        index ==='S' ? 'SIM' : 'NÃO'
                    )
                }
            },
        },
        {
            name: "Ações",
            options: {
                filter: false,
                sort: false,
                empty: true,
                setCellProps: () => ({ align: "center", }),
                setCellHeaderProps: () => ({ align: "center", }),
                customBodyRenderLite: (index: any) => {
                    return (
                        <Button color="primary" onClick={() => selectItem(index)} /* onClick={() => { onEditCallback(data[index]) */ >
                            Selecionar
                        </Button>

                    );
                }
            }
        }
    ];

    return (
        <Dialog open={open} onClose={onClose} maxWidth='xl' fullWidth scroll='body'>
            <MuiDialogTitle disableTypography>
                <Typography variant="h6">Pesquisar Instituições</Typography>
                <IconButton aria-label="close" style={{ position: 'absolute', right: '10px', top: '10px' }}
                    onClick={onCloseHandle}>
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <DialogContent>
                <Grid container justifyContent='center' alignItems='center' spacing={2}>
                    <Grid item >
                        <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Código TASY</Typography>
                        <TextField
                            fullWidth
                            onChange={debounce((e) => onSearchTasy(e), 1000)}
                            size="small"
                            variant="outlined"
                            margin="none"
                            id="search-tasy"
                            placeholder="Digite o código TASY"
                        />
                    </Grid>
                    <Grid item >
                        <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Código CNES</Typography>
                        <TextField
                            fullWidth
                            onChange={debounce((e) => onSearchCnes(e), 1000)}
                            size="small"
                            variant="outlined"
                            margin="none"
                            id="search-cnes"
                            placeholder="Digite o código CNES"
                        />
                    </Grid>
                    <Grid item >
                        <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#5f5f5f' }}>Razão Social</Typography>
                        <TextField
                            fullWidth
                            onChange={debounce((e) => onSearchSocial(e), 1000)}
                            size="small"
                            variant="outlined"
                            margin="none"
                            id="search-social-reason"
                            placeholder="Digite a Razão Social"
                        />
                    </Grid>
                </Grid>
                <Grid>
                    <Box mt={2} mb={2}>
                        <MUIDataTable 
                            options={options}
                            title={""}
                            data={data}
                            columns={columns}
                        />
                    </Box>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default ModalSearchInstitution