import api from "../../utils/api"
import { formatQuestion, mountQuestions } from "./formatQuestions";

type tipoSugestoes = {
  id: number;
  description: string;
}

export const getInstituteName = async (): Promise<string> => {
  const instituteName = await api.get('/institute/user');

  return instituteName.data.corporateName
}

export const getSuggestionsType = async (): Promise<Array<tipoSugestoes>> => {
  const response = await api.get('/type-message')

  return response.data
}

export const sendRequest = async (values: any): Promise<void> => {

  const questionUnityType = await formatQuestion(values.questionUnityType)
  const questionServiceForm = values.questionServiceForm
  const questionTrainingCategory = await formatQuestion(values.questionTrainingCategory)
  const questionTrainingPeriod = await formatQuestion(values.questionTrainingPeriod)
  const questionProfessionalToBeTraineds = await formatQuestion(values.questionProfessionalToBeTraineds)

  const questions = await mountQuestions(questionUnityType, questionServiceForm, questionTrainingCategory, questionTrainingPeriod, questionProfessionalToBeTraineds)

  await api.post('/ns-training-request', {
    corporateName: values.corporateName,
    expectedNumberParticipants: values.expectedNumberParticipants,
    observation: values.observation,
    questions: questions
  })
}

type InstituteProps = {
  PageNumber?: any,
  PageSize?: any,
  TasyCode?: number,
  Cnes?: number,
  CorporateName?: string
}

export const getInstitute = async ({ Cnes, PageNumber, PageSize }: InstituteProps) => {


  const INSTITUTE = '/institute'

  let queryParam = ``

  if (Cnes) {
      queryParam = queryParam + `&Cnes=${Cnes}`
  }

  if (PageNumber) {
      queryParam = queryParam + `&PageNumber=${PageNumber}`
  }

  if (PageSize) {
      queryParam = queryParam + `&PageSize=${PageSize}`
  }

  return await api.get(`${INSTITUTE}?${queryParam}`)

}