import { Dialog, Typography, IconButton, DialogContent, Grid, Box, TextField, Button } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import { useMediaQuery } from 'react-responsive'
import useStyles from '../style'
import { sendReportEmail } from '../service'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import Snackbar from '../../../components/Snackbar'

export interface IEmailReport {
    reportIds: number[]
    emails: string[]
}

const DialogSendMailReport = ({ handleClose, open, reportId }: { handleClose: any, open: boolean, reportId: string }) => {
    const classes = useStyles()
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 598px)' })

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().required('Informe ao menos um e-mail válido para envio do laudo.')
        }),
        onSubmit: values => {
            let emailsObj = values.email.split(' ').join('').split(',')

            const data: IEmailReport = {
                reportIds: [Number(reportId)],
                emails: emailsObj
            }

            sendReportEmail(data).then((e) => {
                Snackbar({ message: "Laudo enviado com sucesso.", type: 'success' })
                close(true)
            })
        }
    })

    const close = (closeEmailSend?: boolean) => {
        formik.resetForm()
        handleClose(closeEmailSend)
    }

    return (
        <Dialog scroll='paper'
            onClose={() => close(false)}
            aria-labelledby="customized-dialog-title"
            fullWidth
            open={open}
            maxWidth={'sm'}
            fullScreen={isTabletOrMobile ?? false}
            disableBackdropClick
        >
            <MuiDialogTitle disableTypography>
                <Typography variant="h6">Enviar laudo por e-mail</Typography>

                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={() => close(false)}
                >
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <DialogContent dividers >
            <Typography variant="body2">Em caso de envio do laudo para mais de um e-mail, informar os e-mails separados por vírgula.</Typography>
                <Grid>
                    <form onSubmit={formik.handleSubmit}>
                        <Box mt={2} mb={2}>
                            <TextField
                                multiline
                                maxRows={4}
                                fullWidth
                                size="small"
                                variant="outlined"
                                margin="none"
                                label='E-mail'
                                id="emailSendReport"
                                InputLabelProps={{ shrink: true, }}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                {...formik.getFieldProps('email')}
                            />
                        </Box>
                        <Box>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Enviar
                            </Button>
                        </Box>
                    </form>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default DialogSendMailReport

