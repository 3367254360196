import useStyles from "./style";
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {Box, Button, Dialog, Grid, IconButton, TextField, Typography} from "@material-ui/core";
import ReactInputMask from "react-input-mask";
import {EvolutionFilterModalProps} from "../../../model/filters/evolution-filter-modal-props";
import {useMediaQuery} from "react-responsive";
import CloseIcon from "@material-ui/icons/Close";
import {TypeEvaluation} from "../../../model/type-evaluation";
import {Program} from "../../../model/program";
import * as service from '../../../service/evolution-service'
import {Autocomplete} from "@material-ui/lab";
import { EvolutionStatus } from "../../../model/evolution-status";

const EvolutionFilterModal = (props: EvolutionFilterModalProps) => {
    const styles = useStyles();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 599px)' })

    const [typeEvaluationList, setTypeEvaluationList] = useState<TypeEvaluation[]>([]);
    const [programList, setProgramList] = useState<Program[]>([]);
    const [program, setProgram] = useState<Program | null>();
    const [typeEvaluation, setTypeEvaluation] = useState<TypeEvaluation | null>(null);
    const [evolutionStatus, setEvolutionStatus] = useState<EvolutionStatusProp | null>(null);
    const [docNumber, setDocNumber] = useState<string>('');
    const [patientName, setPatientName] = useState<string >('');
    const [startPeriod, setStartPeriod] = useState<string>('');
    const [endPeriod, setEndPeriod] = useState<string>('');

    const clearFilter = () => {
        form.resetForm();
    };

    type EvolutionStatusProp = {status: EvolutionStatus, label: string};
    const statusList: EvolutionStatusProp[] = [
        {status: EvolutionStatus.InProgress, label: 'Em andamento'},
        {status: EvolutionStatus.Finalized, label: 'Finalizado'}
    ]

    const form = useFormik({
        initialValues: props.filter,
        validateOnChange: true,
        validateOnBlur: true,
        validate: (values => {
            values.PageNumber = 1;
            props.onFormChange(values);
        }),
        onSubmit: (values) => {
            props.onClose(values);
            clearFilter();
        },
    });


    function closeModal(): void {
        clearFilter();
        props.onClose({}, 'close');
    }


    function onTypeEvaluatioChange(event: React.ChangeEvent<{}>, value: any) {
        form.setFieldValue('TypeEvaluationId', value?.id);
    }

    function onProgramChange(event: React.ChangeEvent<{}>, value: any) {
        form.setFieldValue('ProgramId', value?.id);
    }

    function onStatusChange(event: React.ChangeEvent<{}>, value: any) {
        form.setFieldValue('EvolutionStatus', value);
    }

    function onCpfChange(value: React.ChangeEvent<HTMLInputElement>) {
        form.setFieldValue("DocNumber", value.target.value)
        setDocNumber(value.target.value);
    }

    function onPatientNameChange(value: React.ChangeEvent<HTMLInputElement>) {
        form.setFieldValue("PatientName", value.target.value)
        setPatientName(value.target.value);
    }

    function onStartPeriodChange(value: React.ChangeEvent<HTMLInputElement>) {
        form.setFieldValue("StartPeriod", value.target.value)
        setStartPeriod(value.target.value);
    }

    function onEndPeriodChange(value: React.ChangeEvent<HTMLInputElement>) {
        form.setFieldValue("EndPeriod", value.target.value)
        setEndPeriod(value.target.value);
    }

    useEffect(() => {
        setProgram(programList.find(x => x.id == props.filter.ProgramId) ?? null);
        setTypeEvaluation(typeEvaluationList.find(x => x.id == props.filter.TypeEvaluationId) ?? null);
    }, [programList, typeEvaluationList]);

    useEffect(() => {
        if (props.isOpen) {
            setDocNumber(props.filter.DocNumber ?? '');
            setPatientName(props.filter.PatientName ?? '');
            setStartPeriod(props.filter.StartPeriod?.toString() ?? '');
            setEndPeriod(props.filter.EndPeriod?.toString() ?? '');

            setEvolutionStatus(statusList.find(x => x.status === props.filter.EvolutionStatus) ?? null);
            service.getProgramsAndEvolutionTypes().then(res => {
                setProgramList(res.programs);
                setTypeEvaluationList(res.typeEvaluations);
            })
        }
    }, [])

    function clear() {
        setProgram(null);
        setTypeEvaluation(null);
        setEvolutionStatus(null);
        setDocNumber('');
        setPatientName('');
        setStartPeriod('');
        setEndPeriod('');

        form.setFieldValue("EndPeriod", '')
        form.setFieldValue("StartPeriod", '')
        form.setFieldValue("PatientName", '')
        form.setFieldValue("DocNumber", '')
        form.setFieldValue('EvolutionStatus', '');
        form.setFieldValue('ProgramId', '');
        form.setFieldValue('TypeEvaluationId', '');
    }

    return(
        <>
            <Dialog  onClose={props.onClose} aria-labelledby="customized-dialog-title" open={props.isOpen} maxWidth="xs"
                     fullScreen={isTabletOrMobile ?? false} >
                <Grid>
                    <IconButton className={styles.closeButton} edge="end" color="inherit" onClick={() => closeModal()} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <form onSubmit={form.handleSubmit} className={styles.formSection}>
                    <Grid container alignItems="flex-end">
                        <Grid item xs={12} md={4}>
                            <Box mb={1} mr={1}>
                                <Typography className={styles.typographyStyle}>
                                    CPF
                                </Typography>
                                <ReactInputMask
                                    mask="999.999.999-99"
                                    value={docNumber}
                                    onChange={onCpfChange}>
                                    {() => (
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="none"
                                            fullWidth
                                            id="DocNumber"
                                            placeholder="000.000.000-00"
                                        />
                                    )}
                                </ReactInputMask>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box mb={1} mr={1}>
                                <Typography className={styles.typographyStyle}>
                                    Nome Paciente
                                </Typography>
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    margin="none"
                                    value={patientName}
                                    onChange={onPatientNameChange}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Box mb={1} mr={1}>
                                <Typography className={styles.typographyStyle}>
                                    Data ínicio
                                </Typography>
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    margin="none"
                                    label="Data Início"
                                    id="start-date"
                                    InputLabelProps={{ shrink: true }}
                                    type="date"
                                    value={startPeriod}
                                    onChange={onStartPeriodChange}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Box mb={1} mr={1}>
                                <Typography className={styles.typographyStyle}>
                                    Data fim
                                </Typography>
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    margin="none"
                                    label="Data fim"
                                    id="start-date"
                                    InputLabelProps={{ shrink: true }}
                                    type="date"
                                    value={endPeriod}
                                    onChange={onEndPeriodChange}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={6} md={4}>
                            <Box mb={1} mr={1}>
                                <Typography className={styles.typographyStyle}>
                                    Status
                                </Typography>
                                <Autocomplete
                                    getOptionLabel={(option: EvolutionStatusProp | undefined) => option?.label ?? ''}
                                    disablePortal
                                    onChange={(event, result) => onStatusChange(event, result?.status)}
                                    id="EvolutionStatus"
                                    options={statusList}
                                    value={evolutionStatus}
                                    getOptionSelected={((option, value: EvolutionStatusProp | undefined) => option.status === value?.status)}
                                    renderInput={(params) =>
                                        <TextField {...params} label="Status" />}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Box mb={1} mr={1}>
                                <Typography className={styles.typographyStyle}>
                                    Programa
                                </Typography>
                                <Autocomplete
                                    getOptionLabel={(option) => option.description}
                                    disablePortal
                                    onChange={(event, value) => onProgramChange(event, value)}
                                    id="ProgramId"
                                    options={programList}
                                    value={program}
                                    getOptionSelected={((option, value: Program | undefined) => option.id === value?.id)}
                                    renderInput={(params) =>
                                        <TextField {...form.getFieldProps("ProgramId")} {...params} label="Programa" />}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Box mb={1} mr={1}>
                                <Typography className={styles.typographyStyle}>
                                    Tipo de Avaliação
                                </Typography>
                                <Autocomplete
                                    getOptionLabel={(option) => option.description}
                                    disablePortal
                                    onChange={(event, value) => onTypeEvaluatioChange(event, value)}
                                    id="TypeEvaluationId"
                                    options={typeEvaluationList}
                                    value={typeEvaluation}
                                    getOptionSelected={((option, value: TypeEvaluation | undefined) => option.id === value?.id)}
                                    renderInput={(params) =>
                                        <TextField {...form.getFieldProps("TypeEvaluationId")} {...params} label="Tipo de Avaliação" />}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container alignItems="flex-end" className={styles.btnApplyFilter}>
                        <Grid item xs={6} md={6}>
                            <Box mb={1} mr={1}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    onClick={clear}>
                                    Limpar
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <Box mb={1} mr={1}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary">
                                    Pesquisar
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </Dialog>
        </>
    )
}


export default EvolutionFilterModal;
