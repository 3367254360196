import 'whatwg-fetch'
import 'core-js/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './modules/App'

ReactDOM.render(
  <React.Fragment>
     <App />
  </React.Fragment>,
  document.getElementById('root')
)
