import { useParams } from "react-router-dom";
import * as service from "../../service/evolution-service";
import {useEffect, useState} from "react";
import {
    Accordion, AccordionDetails,
    AccordionSummary,
    Box,
    Button, CircularProgress,
    Grid,
    IconButton,
    Link, Paper, TextField, Typography
} from "@material-ui/core";
import {Evolution} from "../../model/evolution";
import useStyles from "./style";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClosedQuestion from "./components/closed-question/closed-question";
import OpenQuestion from "./components/open-question";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import history from "../../../../routes/history";
import {Form} from "../../model/form";

import Snackbar from "../../../../components/Snackbar";
import CheckIcon from '@material-ui/icons/Check';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import CreateEditEvolutionModal from "../modal/create-edit-evolution/create-edit-evolution-modal";
import {Patient} from "../../model/patient";
import { Autocomplete } from "@material-ui/lab";
import { TypeEvaluation } from "../../model/type-evaluation";
import { Program } from "../../model/program";
import { EvolutionStatus } from "../../model/evolution-status";
import { FormResponse } from "../../model/form-response";

interface IRouteParams {
    evolutionId: string;
    stage: string;
}

const EvolutionForm = () => {

    const style = useStyles();

    const { evolutionId, stage } = useParams<IRouteParams>();

    const [evolution, setEvolution]= useState<Evolution>();
    const [patient, setPatient]= useState<Patient>();
    const [formEvolution, setFormEvolution]= useState<Form[]>();

    const [maxStage, setMaxStage]= useState<number>(1);

    const [firstStageDone, setFirstStageDone]= useState<boolean>(false);
    const [secondStageDone, setSecondStageDone]= useState<boolean>(false);
    const [thirdStageDone, setThirdStageDone]= useState<boolean>(false);
    const [showProfile, setShowProfile] = useState<boolean>(false);

    const [typeEvaluations, setTypeEvaluations] = useState<TypeEvaluation[]>([]);
    const [selectedTypeEvaluation, setSelectedTypeEvaluation] = useState<TypeEvaluation | null>(null);

    const [programs, setPrograms] = useState<Program[]>([]);
    const [selectedProgram, setSelectedProgram] = useState<Program | null>(null);
    const [isOnEdit, setIsOnEdit] = useState<boolean>(false);

    function isReleased(): boolean {
        if (!evolution) return false;

        return evolution.status == EvolutionStatus.Finalized;
    }

    function getStatusLabel(status: number | undefined): string {
        switch (status) {
            case 0 :
                return 'Status - Em Andamento';
            case 1 :
                return 'Status - Finalizado';
            default:
                return '';
        }
    }


    async function fetchAndUpdateEvolution(stageArg = Number(stage)): Promise<void> {
        const evolution = await service.getEvolutionQuestions(Number(evolutionId));

        refreshStagesDone(isReleased());
        setMaxStage(Math.max(...evolution.forms.map(o => o.stage)));

        evolution.forms.forEach((f) => {
            f.formResponses = f.formResponses.slice().sort((a, b) => Number(a.isOpen) - Number(b.isOpen));
        });

        const form = evolution.forms.slice().filter(a => a.stage === stageArg);

        setPatient(evolution.patient);
        setEvolution(evolution);
        setSelectedTypeEvaluation(evolution?.typeEvaluation);
        setSelectedProgram(evolution?.program);
        setFormEvolution(form);
    }

    function updateCurrentForm(stage: number) {
        const form = evolution!.forms.slice().filter(a => a.stage === stage);

        setFormEvolution(form);
    }

    function checkIfIsAnswered(forms: Form[] | undefined): boolean {
        if (!forms)
            return false;

        return forms.every(x => x.formResponses.every(x => x.isOpen && x.openAnswer !== "" || !x.isOpen && x.answerSelected !== null));
    }

    function refreshStagesDone(released: boolean): void {
        const forms = evolution?.forms.slice();
        if (!forms)
            return;

        const firstStage = checkIfIsAnswered(forms.filter(x => x.stage == 1))
            && selectedProgram !== null
            && selectedTypeEvaluation !== null;

        const secondStage = checkIfIsAnswered(forms.filter(x => x.stage == 2));
        const thirdStage = checkIfIsAnswered(forms.filter(x => x.stage == 3));

        setFirstStageDone(firstStage || released);
        setSecondStageDone(secondStage || released);
        setThirdStageDone(thirdStage || released);
    }

    async function getSettings(): Promise<void> {
        const settings = await service.getProgramsAndEvolutionTypes();

        setTypeEvaluations(settings.typeEvaluations);
        setPrograms(settings.programs);
    }

    async function updateEvolutionProgram(event: any, newValue: Program | null): Promise<void> {
        if (!newValue)
            return;

        await service.updateEvolutionProgram({id: Number(evolutionId), programId: newValue.id});
        setSelectedProgram(newValue);
    }

    async function updateTypeEvaluation(event: any, newValue: TypeEvaluation | null): Promise<void> {
        if (!newValue)
            return;

        await service.updateTypeEvaluation({id: Number(evolutionId), typeEvaluationId: newValue.id});
        setSelectedTypeEvaluation(newValue);
    }

    async function moveToNextStage(id: number, stage: number, released: boolean): Promise<void> {
        if (formEvolution?.some(d => d.formResponses.some(f => !f.isOpen && f.answerSelected == null))) {
            Snackbar({ message: 'Você deve responder todas as perguntas para continuar', type: 'error' });
            return;
        }

        if (!evolution?.lastSavedStage || stage >= evolution?.lastSavedStage) {
            await service.updateEvaluationStage(id, stage);
        }

        refreshStagesDone(released);

        const nextStage = stage + 1;
        if (nextStage <= maxStage) {
            updateCurrentForm(nextStage);
            history.push(`/formulario-evolucao/${id}/${nextStage}`);
        }
    }

    async function releaseEvolution(): Promise<void> {
        await service.releaseEvolution(Number(evolutionId));
        navigate(`1`);
    }


    function navigate(stage: string): void {
        history.push(`/formulario-evolucao/${evolutionId}/${stage}`);

        updateCurrentForm(Number(stage));
    }

    function back(): void {
        history.push(`/evolucao`);
    }

    function openSingDialog(type: number) {
        history.push(`/signature-evolucao/${evolutionId}/${type}`)
    }

    function getAccordionLabel(): string {
        switch (stage) {
            case String(1) :
                return `Fatores Pessoais e Sociais`;
            case String(2) :
                return `Fatores Ambientais Relevantes`
            case String(3) :
                return `Observações complementares`
            default:
                return ``
        }
    }

    function getEvolutionCreatDate(): string {
        const date = evolution?.createdAt ?? new Date()
        return new Intl.DateTimeFormat('pt-BR').format(new Date(date))
    }

    async function updatePatientPicture(): Promise<void> {
        if (!patient)
            return;

        try {
            const picture = await service.getPatientPhoto(patient.tasyCode);
            patient.picture = `data:image/png;base64,${picture?.patientPhoto}`;
        } catch (e) {
            console.log(e)
        }

        setPatient(patient);
        setShowProfile(true);
    }

    async function onEditClick(): Promise<void> {
        await updatePatientPicture();
        setIsOnEdit(true);
    }

    function handleCreateEvolutionModalClose(): void {
        setShowProfile(false);
        setIsOnEdit(false);
    }

    async function getInitialData(): Promise<void> {
        await fetchAndUpdateEvolution();
        await getSettings();
    }

    function onAnswer() {
        refreshStagesDone(isReleased());
    }

    useEffect(() => {
        refreshStagesDone(isReleased());
    }, [evolution, selectedTypeEvaluation, selectedProgram]);

    useEffect(() => {
        getInitialData();
    }, [evolutionId])

    return(
        <>
            <MuiDialogTitle disableTypography className={style.title}>

            <Typography >
                <IconButton aria-label="close" className={style.closeButton} onClick={() => back()}>
                    <KeyboardBackspaceIcon />
                </IconButton>
                Protocolo Integrado de Evolução
            </Typography>


            </MuiDialogTitle>

            <Grid className={style.patientInfoContainer}>
                <Grid className={style.patientInfo}>
                    <Typography color="textPrimary">Nome: {evolution?.patient?.name}</Typography>
                    <Typography className={style.dateInfo} color="textPrimary">
                        {getEvolutionCreatDate()}
                    </Typography>
                </Grid>

                <Grid className={style.evolutionCardContent}>
                    <Typography color="textPrimary">Empresa: {evolution?.patient?.company}</Typography>
                </Grid>

                <Grid className={style.evolutionCardContent}>
                    <Typography color="textPrimary">Atividade: {evolution?.patient?.activity}</Typography>
                </Grid>

                <Grid className={style.patientInfo}>
                    <Typography className={evolution?.status === 1 ? style.finishedEvolutionLabel: style.unfinishedEvolutionLabel } color="textPrimary">
                        {getStatusLabel(evolution?.status)}
                    </Typography>
                    <Link onClick={() => onEditClick()} className={style.editLink} color={'primary'}>Editar</Link>
                </Grid>


                <Grid container alignItems="flex-end" className={style.fullProfileButton}>
                    <Grid item xs={12} md={6}>
                        <Box mb={1} mr={1}>
                            <Button
                                onClick={() => updatePatientPicture()}
                                fullWidth
                                variant="contained"
                                color="primary">
                                Perfil Completo
                            </Button>
                        </Box>
                    </Grid>
                </Grid>

            </Grid>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}>

                    <Box position="relative" display="inline-flex">
                        <CircularProgress variant="determinate" value={100 / maxStage * Number(stage)} />
                        <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
                            <Typography className={style.evolutionProgressStep} variant="caption" color="textSecondary"> {stage} de {maxStage}</Typography>
                        </Box>
                    </Box>

                    <Typography className={style.evolutionProgressLabel}>{getAccordionLabel()}</Typography>
                </AccordionSummary>

                <AccordionDetails onClick={() => navigate('1')}>
                    <Typography className={firstStageDone ? style.evolutionProgressDoneLabel : style.evolutionProgressLabel}>Fatores Pessoais e Sociais {
                        firstStageDone ? <CheckIcon/> : null
                    }</Typography>
                </AccordionDetails>

                <AccordionDetails onClick={() => navigate('2')}>
                    <Typography className={secondStageDone ? style.evolutionProgressDoneLabel : style.evolutionProgressLabel}>Fatores Ambientais Relevantes {
                        secondStageDone ? <CheckIcon/> : null
                    }</Typography>
                </AccordionDetails>

                <AccordionDetails onClick={() => navigate('3')}>
                    <Typography className={thirdStageDone ? style.evolutionProgressDoneLabel : style.evolutionProgressLabel}>Observações complementares {
                        thirdStageDone ? <CheckIcon/> : null
                    }</Typography>
                </AccordionDetails>
            </Accordion>
            {
                Number(stage) === 1 ?
                    <Paper>
                        <Grid item xs={12} md={12}>
                            <Box mb={1} mr={1} ml={1}>
                                <Autocomplete
                                        disabled={evolution?.status === 1}
                                        options={typeEvaluations}
                                        getOptionLabel={(option) => option.description}
                                        value={selectedTypeEvaluation}
                                        onChange={updateTypeEvaluation}
                                        getOptionSelected={((option, value) => option.description == value.description)}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Tipo de avaliação" variant="outlined" />
                                        )}
                                    />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Box mb={1} mr={1} ml={1}>
                                <Autocomplete
                                    disabled={evolution?.status === 1}
                                    options={programs}
                                    getOptionLabel={(option) => option.description}
                                    value={selectedProgram}
                                    onChange={updateEvolutionProgram}
                                    getOptionSelected={((option, value) => option.description == value.description)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Programa" variant="outlined" />
                                    )}
                                />
                            </Box>
                        </Grid>
                    </Paper>
                : <></>
            }

            {
                Number(stage) == 3 ?
                    <>

                        <Grid>
                            <Typography className={style.signTitle} variant="h5" style={{ fontWeight: 'bolder' }} color="textPrimary">Assinatura dos Reponsáveis</Typography>
                            <Grid item xs={12} md={6}>
                                <Box mb={1} mr={3} ml={3} mt={3}>
                                    <Button
                                        onClick={() => openSingDialog(0)}
                                        fullWidth
                                        variant="outlined"
                                        color="default">
                                        <Grid className={style.signChip}>
                                            <div>
                                                <BorderColorIcon />
                                            </div>
                                            <div>
                                                Responsável pelo atendimento
                                            </div>
                                            <div>
                                                <CheckIcon />
                                            </div>
                                        </Grid>
                                    </Button>
                                </Box>
                                <Box mb={1} mr={3} ml={3} mt={3}>
                                    <Button
                                        onClick={() => openSingDialog(1)}
                                        fullWidth
                                        variant="outlined"
                                        color="default">
                                        <Grid className={style.signChip}>
                                            <div>
                                                <BorderColorIcon />
                                            </div>
                                            <div>
                                                Atendido
                                            </div>
                                            <div>
                                                <CheckIcon />
                                            </div>
                                        </Grid>
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>

                    </> : null
            }



            {
                formEvolution?.map((f) => {
                        return (
                            <Grid key={f.formId}>
                                <Typography key={f.formId} className={style.formTitle} color='primary'>{f.description || ''}</Typography>
                                {
                                    f?.formResponses?.map((response) => {
                                        return (
                                        response.isOpen ?
                                            <OpenQuestion key={response.questionId}
                                                          evolutionId={Number(evolutionId)}
                                                          disableEdit={evolution?.status === 1}
                                                          onAnswer={onAnswer}
                                                          formResponse={response}
                                                          withAttachment={f.stage == 3}
                                            />
                                            :
                                            <ClosedQuestion key={response.questionId}
                                                            disableEdit={evolution?.status === 1}
                                                            evolutionId={Number(evolutionId)}
                                                            formResponse={response}
                                                            onAnswer={onAnswer}/>
                                        )
                                    })
                                }
                            </Grid>
                        )
                    }
                )
            }

            <Grid container alignItems="flex-end" className={style.fullProfileButton}>

                {
                stage != `3` ?
                <Grid item xs={12} md={12}>
                    <Box mb={1} mr={1} ml={1}>
                        <Button
                            onClick={() => moveToNextStage(Number(evolutionId), Number(stage), isReleased())}
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary">
                            Próxima etapa
                        </Button>
                    </Box>
                </Grid>
                :
                <Grid item xs={12} md={12}>
                    <Box mb={1} mr={1} ml={1}>
                        <Button
                            onClick={() => releaseEvolution()}
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary">
                            Liberar
                        </Button>
                    </Box>
                </Grid>

                }
            </Grid>

            {
                showProfile ?
                    <CreateEditEvolutionModal
                        disableForm={!isOnEdit}
                        isEditing={isOnEdit}
                        patient={patient}
                        photoTasyCode={patient?.tasyCode!}
                        evolutionId={Number(evolutionId)}
                        handleClose={() => handleCreateEvolutionModalClose()}
                        open={showProfile}/>
                    : null
            }
        </>
    )

}

export default EvolutionForm;
