import { AxiosResponse } from "axios";

export interface iAuth {
    isLoading: boolean,
    authenticated: boolean,
    setIsLoading?: any,
    handleLogin?: any,
    handleLogoff?: any,
    handleResetPassword?: any
    handleNewPassword?: any
    checkIsAuthStore?: any
}

export interface iUser {
    token?: string,
    refresh?: string,
    created?: string,
    expires?: string,
    menus?:any,
    name?: string,
    permissions?: string[],
    typeUser: number,
    instituteCnes: string,
    instituteTasy?: number,
    institutionCorporateName: string
}

export interface iReports {
  reportIdData: any
  setReportIdData: (value: any) => void
}

export interface iUserAuthContext {
    auth: iAuth,
    user?: iUser,
    authenticatedUser ?: (resp : AxiosResponse) => void,
    reports: iReports
}

enum loginType{
    pj  = '0',
    ijc = '1',
    pf = '2'
}

export enum typeUser{
    'ijc' = 0, //interno
    'pf' = 1, //cliente
    'pj' = 2, //instituição
}

export interface iLoginRequest {
    email: string,
    password: string,
    cnes:string
    loginType: loginType,
    typeUser: number,
    typeLoginPf?: number,
    dnv: string,
    cellPhone : string,
    medicalRecordNumber: string,
}

export interface iUserIJCRequestAuth {
    email: string,
    password: string,
    typeUser: number
}

export interface iUserPJRequestAuth {
    cnes: string,
    email: string,
    password: string,
    typeUser: number
}

export interface iUserPFRequestAuth {
    typeUser: number,
    email:string,
    password: string,
    dnv: string,
    cellPhone:string,
    medicalRecordNumber: string,
    typeLoginPf : number,

}

export interface iUserResetNewPass {
    cnes: string,
    email: string,
    typeUser: number
    loginType: loginType
}

export interface iUserNewPass
{
    resetPasswordCode: string,
    newPassword: string
}

export interface iChangePassword {
    password: string,
    newPassword: string
}

export interface IUserPFContent {
    firstAccess : boolean,
    typeLoginPf : number,
    dnv : string,
    dddCellPhone : string,
    numberCellPhone : string,
    medicalRecordNumber : string,
}
