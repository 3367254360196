import { useState } from "react";
import ViewDataGridUser from "./components/ViewDataGridUsers";
import ContextUsers from './context'
import CreateUpdateUsers from './components/CreateUpdateUsers'
// import ViewDataByGuest from './components/ViewDataByGuest'


const Users = () => {
    const [mode, setMode] = useState('')
    const [isReadOnly, setIsReadOnly] = useState(false)
    const [userId, setUserId] = useState<number>();

    const [typeUser, setTypeUser] = useState<number>()

    return (
        <ContextUsers.Provider value={{mode, setMode, isReadOnly, setIsReadOnly, userId, setUserId, typeUser , setTypeUser}}>
           {
                 mode ==='add' || mode==='edit' ?
                 <CreateUpdateUsers />
                 : 
                 <ViewDataGridUser /> 
            }     
        </ContextUsers.Provider>
    )
}

export default Users