import api from '../../../utils/api'
import { IReportProps } from '../interfaces'



export const getReportById = async(id : number) : Promise<IReportProps> =>  {
    return await api.get(`report/${id}`).then(resp => resp.data)
}

export const postReportEditionRequest = async(report:any) => {
    return await api.post('report/create-edit-request', report)
}