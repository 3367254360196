import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    typographyStyle: {
        fontSize: `0.9rem`,
        fontWeight: `bold`,
        marginBottom: `10px`
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    contentCenter : {
        justifyContent: 'center',
        display: 'flex'
    },
    avatar: {
        color: '#e64683',
        backgroundColor: '#f9f9f9',
        borderColor: '#e64683',
        border: '1px solid',
        width: '100px',
        height: '100px'
    },
    avatarContainer: {
        cursor: 'pointer'
    }
}))
