import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
     createNewButton: {
        width: '100%'
    },
    createNewContainer: {
         backgroundColor: 'white',
         justifyContent: 'center',
         marginTop: '5px',
         position: 'sticky',
         left: 0,
         top: 0,
         bottom: 0,
         right: 0,
         zIndex: 200,
        padding: '10px 0px'
    },
    createNewContainerBox: {
        margin: "auto"
    },
    status: {
        padding: '10px',
        borderRadius: '5px',
        textAlign: 'center'
    },
    availableColor: {
        backgroundColor: 'rgba(0, 218, 48, 0.2)',
        color: '#006F18'
    },
    notAvailableColor: {
        backgroundColor: 'rgba(178,100,14,0.2)',
        color: '#ab5f0a'
    },
    typographyStyle: {
        fontSize: `0.9rem`,
        fontWeight: `bold`,
        marginBottom: `10px`
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    btnFilter : {
         justifyContent: 'center'
    },
    evolutionCardContent: {
        padding: '5px 15px'
    },
    evolutionCardContentPatientInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px 15px'
    },
    evolutionList: {
        border: '0.5px solid #efefef',
        borderRadius: '6px',
        cursor: 'pointer',
        marginTop: '5px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    evolutionPatientInfo: {
        backgroundColor: '#efefef',
    },
    evolutionPatientIconSection: {
        backgroundColor: '#1b943d',
        position: 'relative',
        borderLeft: 'unset',
        borderRadius: '0px 5px 5px 0px'
    },
    evolutionPdfIconButton: {
        left: '15%',
    },
    evolutionPdfIcon: {
        color: 'white'
    },
    section: {
        display: 'flex',
        flexDirection: 'column'
    },
    filters: {
        position: 'sticky',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: 200
    },
    dateInfo: {
         paddingRight: '15px'
    },
    finishedEvolutionLabel: {
         paddingTop: '10px',
         color: '#1B943D'
    },
    unfinishedEvolutionLabel: {
        paddingTop: '10px',
         color: '#E64683'
    }
}))
