/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import UploadFile from "../../components/UploadFile";
import useStyles from "./style";
import { dateTimeStringToDateTimeFormat } from "../../utils/convertDate";
import history from "../../routes/history";
import { getReportById, postReportEditionRequest } from "./services";
import Snackbar from "../../components/Snackbar";
import { useParams } from "react-router-dom";

interface IRouteParams {
  laudoId: string;
}

export function ChangeChildRegistrationData() {
  const classes = useStyles();

  const [isUploadFieldDataUserDisable, setIsUploadFieldDataUserDisable] =
    useState(true);
  const [
    isUpdateFieldCollectProtocolDisable,
    setIsUpdateFieldCollectProtocolDisable,
  ] = useState(true);
  const [birthCertificateFile, setBirthCertificateFile] = useState<
    File
  >();
  const [collectionProtocolFile, setCollectionProtocolFile] = useState<
    File
  >();
  const [birthCertificateFileBase64, setBirthCertificateFileBase64] = useState<
    string | ArrayBuffer | null
  >();
  const [collectionProtocolFileBase64, setCollectionProtocolFileBase64] =
    useState<string | ArrayBuffer | null>();

  const { laudoId } = useParams<IRouteParams>();
  const formik = useFormik({
    initialValues: {
      reportId: 0,
      corporateName: "",
      namePatient: "",
      bornDate: "",
      motherName: "",
      weight: 0,
      gestationalAge: 0,
      transfusion: false,
      collectionDate: "",
      transfusionDate: "",
      observation: "",
    },

    validationSchema: Yup.object({
      namePatient: Yup.string().required("Campo obrigatório"),
      bornDate: Yup.string().required("Campo obrigatório"),
      motherName: Yup.string().required("Campo obrigatório"),
      weight: Yup.number()
        .typeError("Apenas números são permitidos")
        .required("Campo obrigatório")
        .lessThan(2147483648, "Valor Inválido."),
      gestationalAge: Yup.number().required("Campo obrigatório"),
      collectionDate: Yup.string().required("Campo obrigatório"),
      transfusion: Yup.boolean().nullable(),
      transfusionDate: Yup.string()
        .nullable()
        .when("transfusion", {
          is: true,
          then: Yup.string().nullable().required("Campo obrigatório"),
        }),
    }),
    onSubmit: (values) => {
      if (tempData === values) {
        return Snackbar({
          message: "Formulário sem alteração",
          type: "success",
        });
      }

      const sendValues = {
        ...values,
        weight: Number(formik.values.weight),
        gestationalAge: Number(formik.values.gestationalAge),
        collectionProtocolFile: collectionProtocolFile
          ? collectionProtocolFileBase64
          : "",
        collectionProtocolExtension: collectionProtocolFile
          ? getExtensionFile(collectionProtocolFile?.name)
          : "",
        collectionProtocolType: collectionProtocolFile
          ? collectionProtocolFile?.type
          : "",
        birthCertificateFile: birthCertificateFile
          ? birthCertificateFileBase64
          : "",
        birthCertificateExtension: birthCertificateFile
          ? getExtensionFile(birthCertificateFile?.name)
          : "",
        birthCertificateType: birthCertificateFile
          ? birthCertificateFile?.type
          : "",
      };

      postReportEditionRequest(sendValues).then((resp) => {
        Snackbar({
          message: "Solicitação de alteração dos dados enviado com sucesso",
          type: "success",
        });
        history.push("/laudo");
      });
    },
  });

  const getExtensionFile = (name: string) => {
    let regexGetExtension = /(?:\.([^.]+))?$/;
    let extension = regexGetExtension.exec(name) ?? "";
    return extension[1];
  };

  useEffect(() => {
    const reader = new FileReader();
    reader.readAsDataURL((birthCertificateFile as Blob) ?? new Blob());
    reader.onload = function () {
      setBirthCertificateFileBase64(reader.result);
    };
  }, [birthCertificateFile]);

  useEffect(() => {
    const reader = new FileReader();
    reader.readAsDataURL((collectionProtocolFile as Blob) ?? new Blob());
    reader.onload = function () {
      setCollectionProtocolFileBase64(reader.result);
    };
  }, [collectionProtocolFile]);

  const [tempData, setTempData] = useState<any>();

  useEffect(() => {
    async function loadReport() {
      const laudoFormatted = Number(laudoId);

      const data = await getReportById(laudoFormatted).then(
        (dataformatted) => ({
          ...dataformatted,
          reportId: laudoFormatted,
          corporateName: dataformatted.corporateName ?? "Não informada",
          bornDate: dateTimeStringToDateTimeFormat(dataformatted.bornDate),
          collectionDate: dateTimeStringToDateTimeFormat(
            dataformatted.collectionDate
          ),
          transfusionDate:
            dataformatted.transfusionDate &&
            dateTimeStringToDateTimeFormat(dataformatted.transfusionDate),
        })
      );

      setTempData(data);
      formik.setValues(data);
    }
    loadReport();
  }, [laudoId]);

  const handleSubmitForm = () => {
    Yup.object().validate(formik.values, { abortEarly: false });
    formik.submitForm();
  };

  const handleChangeInput = (e: React.ChangeEvent<any>) => {
    formik.setFieldValue(e.currentTarget.id, e.currentTarget.value);
    setIsUploadFieldDataUserDisable(false);
  };

  const handleChangeCollectDataInput = (e: React.ChangeEvent<any>) => {
    formik.setFieldValue("collectionDate", e.currentTarget.value);
    setIsUpdateFieldCollectProtocolDisable(false);
  };

  const handleChangeGestionalAge = (e: React.ChangeEvent<any>) => {
    formik.setFieldValue("gestationalAge", e.currentTarget.value);
    setIsUpdateFieldCollectProtocolDisable(false);
  };

  const handleChangeTransfusion = (e: React.ChangeEvent<any>) => {
    if (e.currentTarget.value === "true") {
      formik.setFieldValue("transfusion", true);
      setIsUpdateFieldCollectProtocolDisable(false);
    } else {
      formik.setFieldValue("transfusion", false);
      setIsUpdateFieldCollectProtocolDisable(true);
    }
  };

  return (
    <Box flexDirection="column">
      <Box mb={5}>
        <Grid container direction="column" justifyContent="center">
          <Grid item>
            <Typography
              variant="h4"
              style={{ fontWeight: "bolder" }}
              color="textPrimary"
            >
              Alterar dados cadastrais criança
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" color="textSecondary">
              Você pode solicitar a alteração dos dados anteriormente inseridos.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={4}>
          <Typography className={classes.inputLabel}>Instituição:</Typography>
          <TextField
            size="small"
            variant="outlined"
            margin="none"
            disabled
            fullWidth
            id="institutionName"
            placeholder="Instituição"
            error={
              formik.touched.corporateName &&
              Boolean(formik.errors.corporateName)
            }
            helperText={
              formik.touched.corporateName && formik.errors.corporateName
            }
            {...formik.getFieldProps("corporateName")}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography className={classes.inputLabel}>
            Data/Hora Coleta:
          </Typography>
          <TextField
            size="small"
            variant="outlined"
            margin="none"
            required
            fullWidth
            onChange={handleChangeCollectDataInput}
            value={formik.values.collectionDate}
            id="collectionDate"
            type="datetime-local"
            error={
              formik.touched.collectionDate &&
              Boolean(formik.errors.collectionDate)
            }
            helperText={
              formik.touched.collectionDate && formik.errors.collectionDate
            }
          />
        </Grid>
      </Grid>
      <Box>
        <Grid
          container
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} sm={4}>
            <Typography className={classes.inputLabel}>
              Nome Criança:{" "}
            </Typography>
            <TextField
              size="small"
              variant="outlined"
              margin="none"
              required
              fullWidth
              value={formik.values.namePatient}
              onChange={handleChangeInput}
              id="namePatient"
              placeholder="Nome Completo"
              error={
                formik.touched.namePatient && Boolean(formik.errors.namePatient)
              }
              helperText={
                formik.touched.namePatient && formik.errors.namePatient
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.inputLabel}>
              {" "}
              Nome da mãe:
            </Typography>
            <TextField
              size="small"
              variant="outlined"
              margin="none"
              required
              fullWidth
              id="motherName"
              type="text"
              placeholder="Digite o nome do pai ou da mãe"
              value={formik.values.motherName}
              onChange={handleChangeInput}
              error={
                formik.touched.motherName && Boolean(formik.errors.motherName)
              }
              helperText={formik.touched.motherName && formik.errors.motherName}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid
          container
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} sm={4}>
            <Typography className={classes.inputLabel}>
              Data/Hora Nascimento:
            </Typography>
            <TextField
              size="small"
              variant="outlined"
              type="datetime-local"
              margin="none"
              required
              fullWidth
              id="bornDate"
              placeholder="Data e Hora de Nascimento"
              value={formik.values.bornDate}
              onChange={handleChangeInput}
              error={formik.touched.bornDate && Boolean(formik.errors.bornDate)}
              helperText={formik.touched.bornDate && formik.errors.bornDate}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.inputLabel}>
              Idade Gestacional:
            </Typography>
            <TextField
              size="small"
              variant="outlined"
              margin="none"
              fullWidth
              id="gestationalAge"
              type="number"
              onChange={handleChangeGestionalAge}
              value={formik.values.gestationalAge}
              error={
                formik.touched.gestationalAge &&
                Boolean(formik.errors.gestationalAge)
              }
              helperText={
                formik.touched.gestationalAge && formik.errors.gestationalAge
              }
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.inputLabel}>
              Peso no momento da coleta (gramas):
            </Typography>
            <TextField
              type="text"
              size="small"
              variant="outlined"
              margin="none"
              required
              value={formik.values.weight}
              onChange={handleChangeInput}
              fullWidth
              id="weight"
              placeholder="05 kg"
              error={formik.touched.weight && Boolean(formik.errors.weight)}
              helperText={formik.touched.weight && formik.errors.weight}
              /* {...formik.getFieldProps('weight')} */
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography className={classes.inputLabel}>
              Criança recebeu transfusão?
            </Typography>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              {...formik.getFieldProps("transfusion")}
              onChange={handleChangeTransfusion}
            >
              <FormControlLabel value={true} control={<Radio />} label="Sim" />
              <FormControlLabel value={false} control={<Radio />} label="Não" />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography className={classes.inputLabel}>
              Data Transfusão:
            </Typography>
            <TextField
              size="small"
              variant="outlined"
              margin="none"
              disabled={!formik.values.transfusion}
              fullWidth
              id="transfusionDate"
              placeholder="ex : 10/09/2021"
              type="date"
              error={
                formik.touched.transfusionDate &&
                Boolean(formik.errors.transfusionDate)
              }
              helperText={
                formik.touched.transfusionDate && formik.errors.transfusionDate
              }
              {...formik.getFieldProps("transfusionDate")}
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={4}>
          <Typography className={classes.inputLabel}>Observações:</Typography>
          <TextField
            multiline
            minRows={6}
            size="small"
            variant="outlined"
            margin="none"
            fullWidth
            id="observation"
            placeholder="Digite aqui suas observações..."
            error={
              formik.touched.observation && Boolean(formik.errors.observation)
            }
            helperText={formik.touched.observation && formik.errors.observation}
            {...formik.getFieldProps("observation")}
          />
        </Grid>
        <Grid item xs={6} sm={2}>
          <Box mt={3}>
            <UploadFile
              file={birthCertificateFile}
              //@ts-ignore
              setFile={setBirthCertificateFile}
              optionalClass={classes}
              labelFileInput="Enviar Certidão de Nascimento"
              isDisabled={isUploadFieldDataUserDisable}
            />
          </Box>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Box mt={3}>
            <UploadFile
              file={collectionProtocolFile}
              isDisabled={isUpdateFieldCollectProtocolDisable}
                //@ts-ignore
              setFile={setCollectionProtocolFile}
              optionalClass={classes}
              labelFileInput="Enviar Resumo de Alta"
            />
          </Box>
        </Grid>
      </Grid>
      <Box mt={4}>
        <Grid
          container
          item
          xs={12}
          sm={12}
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={6} sm={3}>
            <Button
              disabled={tempData === formik.values}
              className={classes.button}
              onClick={handleSubmitForm}
              fullWidth
              variant="contained"
              color="primary"
            >
              Enviar
            </Button>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Button
              className={classes.button}
              onClick={() => history.push("/laudo")}
              fullWidth
              variant="contained"
              color="secondary"
            >
              Voltar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
