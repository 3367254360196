import { Dialog, DialogContent, Typography, IconButton  } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import { useMediaQuery } from 'react-responsive'

import useStyles from './styles'

const Modal = ({ handleClose, open }: { handleClose: any, open: boolean }) => {
  const classes = useStyles()
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 598px)' })

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="lg"
      fullScreen={isTabletOrMobile ?? false}
    >
      <MuiDialogTitle disableTypography>
        <Typography variant="h6">Dicas no Acesso à Plataforma</Typography>

        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>

      <DialogContent dividers>
        <Typography variant="body2">
          Esta área é dedicada aos usuários do Instituto Jô Clemente e para as instituições de saúde.<br /><br />

          Para fazer login e entrar na plataforma os hospitais, unidades básicas de saúde e demais instituições
          devem selecionar a opção "Hospital" e informar o CNES, E-mail e Senha recebidos do Instituto Jô Clemente.<br /><br />

          Já os usuários internos do Instituto Jô Clemente devem selecionar a opção "IJC" e informar o E-mail e Senha
          recebidos do Departamento de TI.<br /><br />

          Nota: Caso tenha esquecido a senha basta clicar no link "Esqueceu a senha?". Neste caso, será enviado para
          o e-mail cadastrado um link para cadastro de nova senha.
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

export default Modal
