import api from "../../../utils/api"
import { API_ROUTE } from "../constants"
import { iUserIJCRequestAuth, iUser, iUserPFRequestAuth, iUserPJRequestAuth, iUserResetNewPass, iUserNewPass, iChangePassword } from "../interfaces"


export const loginIjc = async (userIJCRequestAuth: iUserIJCRequestAuth , tokenReCaptcha : string) => {
    return await api.post<iUser>
        (`${API_ROUTE.LOGIN}?tokenReCaptcha=${tokenReCaptcha}`, userIJCRequestAuth)
}

export const loginPJ = async (userPJRequestAuth: iUserPJRequestAuth,  tokenReCaptcha : string) => {
    return await api.post<iUser>
        (`${API_ROUTE.LOGIN}?tokenReCaptcha=${tokenReCaptcha}`, userPJRequestAuth)
}

export const loginPF = async (userPFRequestAuth: Partial<iUserPFRequestAuth>, tokenReCaptcha : string) => {
    return await api.post<iUser>
        (`${API_ROUTE.LOGIN}?tokenReCaptcha=${tokenReCaptcha}`, userPFRequestAuth)
}

export const resetPassIjc = async (userResetNewPass: iUserResetNewPass , tokenReCaptcha : string) => {
    return await api.post
        (`${API_ROUTE.RESET_PASS}?tokenReCaptcha=${tokenReCaptcha}`, userResetNewPass)
}

export const resetPassPj = async (userResetNewPass: iUserResetNewPass , tokenReCaptcha : string) => {
    return await api.post
        (`${API_ROUTE.RESET_PASS}?tokenReCaptcha=${tokenReCaptcha}`, userResetNewPass)
}

export const newPassResetCode = async (userNewPass: iUserNewPass, tokenReCaptcha : string) => {
    return await api.post
    (`${API_ROUTE.NEW_PASS_RESET_CODE}?tokenReCaptcha=${tokenReCaptcha}`, userNewPass)
        /* (API_ROUTE.NEW_PASS_RESET_CODE, userNewPass) */
}

export const changePassword = async (changePassword: iChangePassword) => {
    return await api.post
        (API_ROUTE.CHANGE_PASSWORD, changePassword)
}