import React, {useState, useEffect} from "react";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import {
    Accordion,
    AccordionSummary,
    Box, Button,
    Grid,
    IconButton,
    List,
    ListItemSecondaryAction,
    ListItemText,
    Typography
} from "@material-ui/core";
import useStyles from "./style";
import {hideRecaptcha} from "../../utils/reCaptcha";
import CreateEditEvolutionModal from "./components/modal/create-edit-evolution/create-edit-evolution-modal";
import * as service from "./service/evolution-service";
import {EvolutionResponse} from "./model/evolution-response";
import EvolutionFilterModal from "./components/modal/filter/evolution-filter-modal";
import {EvolutionFilters} from "./model/filters/evolution-filters";

import history from '../../routes/history';

const EvolutionList = (props : any) => {
    hideRecaptcha();
    const titlePage = `Formulário de Evolução`;
    const [creatingEvolution, setCreatingEvolution] = useState(false);
    const [filteringEvolution, setFilteringEvolution] = useState(false);
    const classes = useStyles();
    let [evolutions, setEvolutions]= useState<EvolutionResponse[]>([]);
    const [filter, setFilter] = useState<EvolutionFilters>({PageNumber: 1, PageSize: 10});

    const [page, setPage] = useState<number>(1);
    const [maxPage, setMaxPage] = useState<number>(1);

    const loadMoreItems = async () => {
        if (page < maxPage) {
            let currentPage = page + 1
            setPage(currentPage);
            await list(currentPage)
        }
    };


    async function list(pageParam = 1): Promise<void> {
        const response = await service.listEvolutions(filter, pageParam);
        setMaxPage(response.totalPages);
        const merge = [...evolutions, ...response.content];
        setEvolutions(merge as EvolutionResponse[]);
    }

    function getStatusLabel(status: number): string {
        return status === 0 ? 'Status - Em Andamento' : 'Status - Finalizado';
    }

    async function onCreateEvolutionCloseModal(): Promise<void> {
        await list();
        setCreatingEvolution(false);
    }

    async function onFilterEvolutionCloseModal(action?: string) {
        if (!action) {
            evolutions = [];
            await list();
        }
        setFilteringEvolution(false);
    }

    function openFilter(event: React.MouseEvent): void {
        event.preventDefault();
        setFilteringEvolution(true);
    }

    async function openEvolution(evolutionId: number, stage: number): Promise<void> {
        history.push(`/formulario-evolucao/${evolutionId}/${stage || 1}`)
    }

    async function openPdf(evolutionId: number) {
        const pdf = await service.getPdf(evolutionId);

        const pdfData = base64ToBlob(pdf);
        const pdfUrl = URL.createObjectURL(pdfData);

        window.open(pdfUrl, '_blank');

    }

    function base64ToBlob( base64: string, type = "application/pdf" ) {
        const binStr = atob( base64 );
        const len = binStr.length;
        const arr = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            arr[ i ] = binStr.charCodeAt( i );
        }
        return new Blob( [ arr ], { type: type } );
    }

    useEffect(() => {
        list()
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {passive: true});
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [evolutions])

    const handleScroll = async () => {
        if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
            await loadMoreItems();
        }
    };

    function updateFilterValue(params: EvolutionFilters) {
        setFilter(params);
    }

    return (
        <>
            <Grid className={classes.section}>
                <Grid container>
                    <Grid item >
                        <Typography variant="h5" style={{ fontWeight: 'bolder' }} color="textPrimary">{`${titlePage}`}</Typography>
                    </Grid>
                </Grid>


                <Accordion className={classes.filters}>
                    <AccordionSummary aria-controls="filter" id="filter" onClick={(event) => openFilter(event)}>
                        <Typography>Filtros</Typography>
                    </AccordionSummary>
                </Accordion>

                {
                    !evolutions || evolutions?.length == 0 ?

                        <Grid container justifyContent="center">
                            <Box mt={2}>
                                Nenhum registro encontrado
                            </Box>
                        </Grid>
                        : null
                }



                <List dense={true} >

                    {
                        evolutions?.map(ev => {
                            return (
                                <Grid className={classes.evolutionList}>
                                    <Grid xs={ev.status === 0 ? 12 : 10} md={ev.status === 0 ? 12 : 10} className={classes.evolutionPatientInfo}>
                                        <ListItemText
                                            onClick={() => openEvolution(ev.evolutionId, ev.lastSavedStage || 1)}
                                            primary={
                                                <Grid className={classes.evolutionCardContentPatientInfo}>
                                                    <Typography variant="h5" color="textPrimary">{ev.patientName}</Typography>
                                                    <Typography className={classes.dateInfo} color="textPrimary">{new Intl.DateTimeFormat('pt-BR').format(new Date(ev.createdAt))}</Typography>
                                                </Grid>
                                            }
                                            secondary={
                                                <Grid className={classes.evolutionCardContent}>
                                                    <Typography color="textPrimary">{ev.activity} - {ev.company}</Typography>
                                                    <Typography className={ev.status === 1 ? classes.finishedEvolutionLabel: classes.unfinishedEvolutionLabel } color="textPrimary">{getStatusLabel(ev.status)}</Typography>
                                                </Grid>
                                            }
                                        />
                                    </Grid>

                                    {
                                        ev.status !== 0 ?
                                            <Grid xs={2} md={2} className={classes.evolutionPatientIconSection} onClick={() => openPdf(ev.evolutionId)}>
                                                <ListItemSecondaryAction>
                                                    <IconButton className={classes.evolutionPdfIconButton}>
                                                        <PictureAsPdfIcon className={classes.evolutionPdfIcon} />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </Grid>
                                            : null
                                    }
                                </Grid>
                            )
                        })
                    }
                </List>

                {
                    creatingEvolution || filteringEvolution ? null :
                        <Grid className={classes.createNewContainer}>
                            <Grid item xs={12} md={6} className={classes.createNewContainerBox}>
                                <Box mb={1} mr={1}>
                                    <Button
                                        onClick={() => setCreatingEvolution(true)}
                                        fullWidth
                                        variant="contained"
                                        color="primary">
                                        Criar novo
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                }


                {
                    filteringEvolution &&
                        <EvolutionFilterModal
                            filter={filter}
                            onFormChange={(filter: EvolutionFilters) => updateFilterValue(filter)}
                            onClose={(filterArgs: EvolutionFilters, action?: string) => onFilterEvolutionCloseModal(action)}
                            isOpen={filteringEvolution}/>
                }

                {
                    creatingEvolution &&
                    <CreateEditEvolutionModal
                        disableForm={false}
                        isEditing={false}
                        handleClose={onCreateEvolutionCloseModal}
                        open={creatingEvolution}/>
                }

            </Grid>
        </>
    )
}


export default EvolutionList
