import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  title: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    paddingBottom: 0
  },
  button: {
    textTransform: 'none',
    fontSize: '15px',
    width: 100
  }
}))